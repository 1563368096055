import ThemeIcons from 'resources/images/menu'
import { ROLE } from 'enum/roles'
import { ThemeIconKeysType } from 'types/ThemeIconKeysType'
import { DropdownItems } from 'types/DropdownItems'
import { MenuElement } from 'types/MenuElement'
import { IconType } from 'types/IconType'

export const getIconMenu = (icon: ThemeIconKeysType): IconType => {
    return ThemeIcons[icon]
}

const setMenuItemHref = (menuItem: string, param?: string | number): string => {
    switch (menuItem) {
        case 'dashboards':
            return `/dashboard/${param}`
        default:
            return ''
    }
}

const setAllOptionItemHref = (menuItem: string): string => {
    switch (menuItem) {
        case 'dashboards':
            return `/`
        default:
            return ''
    }
}

export const setMenuElementSubItems = (
    parentItem: string,
    roles: Array<ROLE> | undefined,
    items: Array<DropdownItems>,
    addAllOption = false
): Array<MenuElement> => {
    const subItems: Array<MenuElement> = []
    if (addAllOption === true) {
        const allOptionItem: MenuElement = {
            code: parentItem,
            label: 'All',
            href: setAllOptionItemHref(parentItem),
            hasIcon: false,
            roles,
        }
        subItems.push(allOptionItem)
    }
    items.map((item: DropdownItems) => {
        const subItem: MenuElement = {
            code: `${item.value}`,
            label: item.label,
            href: setMenuItemHref(parentItem, item.value),
            hasIcon: false,
            roles,
        }
        subItems.push(subItem)
    })
    return subItems
}
