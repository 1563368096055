import { PersonalIconsObjectType } from 'types/PersonalIconsObjectType'
import { ReactComponent as CrownIcon } from './crown.svg'
import { ReactComponent as CupIcon } from './cup.svg'
import { ReactComponent as GiftIcon } from './gift.svg'
import { ReactComponent as HomeIcon } from './home.svg'
import { ReactComponent as ThoughtIcon } from './thought.svg'
import { ReactComponent as TshirtIcon } from './tshirt.svg'

const PersonalIcons: PersonalIconsObjectType = {
    crown: CrownIcon,
    cup: CupIcon,
    gift: GiftIcon,
    home: HomeIcon,
    thought: ThoughtIcon,
    tshirt: TshirtIcon,
}

export default PersonalIcons
