import { BusinessIconsObjectType } from 'types/BusinessIconsObjectType'
import { ReactComponent as AgendaIcon } from './agenda.svg'
import { ReactComponent as ApartmentIcon } from './apartment.svg'
import { ReactComponent as BriefcaseIcon } from './briefcase.svg'
import { ReactComponent as CartFullIcon } from './cart-full.svg'
import { ReactComponent as CartIcon } from './cart.svg'
import { ReactComponent as ConsultingIcon } from './consulting.svg'
import { ReactComponent as CustomerIcon } from './customer.svg'
import { ReactComponent as DeliveryIcon } from './delivery.svg'
import { ReactComponent as GrowIcon } from './grow.svg'
import { ReactComponent as HandshakeIcon } from './handshake.svg'
import { ReactComponent as InvestMonitorIcon } from './invest-monitor.svg'
import { ReactComponent as InvestmentIcon } from './investment.svg'
import { ReactComponent as LicenseIcon } from './license.svg'
import { ReactComponent as NotepadIcon } from './notepad.svg'
import { ReactComponent as OfferIcon } from './offer.svg'
import { ReactComponent as PaperclipIcon } from './paperclip.svg'
import { ReactComponent as PieChartIcon } from './pie-chart.svg'
import { ReactComponent as PinIcon } from './pin.svg'
import { ReactComponent as QuotationIcon } from './quotation.svg'
import { ReactComponent as RevenueIcon } from './revenue.svg'
import { ReactComponent as StampIcon } from './stamp.svg'
import { ReactComponent as StatsDownIcon } from './stats-down.svg'
import { ReactComponent as StatsUpIcon } from './stats-up.svg'
import { ReactComponent as TagIcon } from './tag.svg'
import { ReactComponent as TargetRevenueIcon } from './target-revenue.svg'
import { ReactComponent as TargetIcon } from './target.svg'

const BusinessIcons: BusinessIconsObjectType = {
    agenda: AgendaIcon,
    apartment: ApartmentIcon,
    briefcase: BriefcaseIcon,
    'cart-full': CartFullIcon,
    cart: CartIcon,
    consulting: ConsultingIcon,
    customer: CustomerIcon,
    delivery: DeliveryIcon,
    grow: GrowIcon,
    handshake: HandshakeIcon,
    'invest-monitor': InvestMonitorIcon,
    investment: InvestmentIcon,
    license: LicenseIcon,
    notepad: NotepadIcon,
    offer: OfferIcon,
    paperclip: PaperclipIcon,
    'pie-chart': PieChartIcon,
    pin: PinIcon,
    quotation: QuotationIcon,
    revenue: RevenueIcon,
    stamp: StampIcon,
    'stats-down': StatsDownIcon,
    'stats-up': StatsUpIcon,
    tag: TagIcon,
    'target-revenue': TargetRevenueIcon,
    target: TargetIcon,
}

export default BusinessIcons
