import { MenuElement } from 'types/MenuElement'
import { ROLE } from 'enum/roles'
import { getUploadFileLimit } from './imageUtils'
import { UploadFileLimit } from 'interfaces/UploadFileLimit.interfaces'

export const DEVELOPMENT = process.env.REACT_APP_DEVELOPMENT_MODE === 'true' ?? false
export const AUTH_2FA = process.env.REACT_APP_AUTH_2FA_MODE === 'true' ?? false
export const AUTH_REGISTRATION = process.env.REACT_APP_AUTH_REGISTRATION === 'true' ?? false
export const COMING_SOON_ENABLED = process.env.REACT_APP_COMING_SOON_ENABLED === 'true' ?? false
export const menuItems: Array<MenuElement> = [
    {
        code: 'dashboard',
        label: 'Dashboard',
        href: '/dashboard',
        subItems: [],
        hasIcon: true,
        icon: { folder: 'heroicons', name: 'chart-pie' },
        roles: [ROLE.USER],
    },
    {
        code: 'admin',
        label: 'Dashboard',
        href: '/admin',
        subItems: [],
        hasIcon: true,
        icon: { folder: 'heroicons', name: 'chart-pie' },
        roles: [ROLE.ADMINISTRATOR],
    },
]
export const secondaryMenuItems: Array<MenuElement> = [
    {
        code: 'my-account',
        label: 'My Account',
        href: '/my-account',
        subItems: [],
        hasIcon: true,
        icon: { folder: 'heroicons', name: 'user' },
        roles: [ROLE.USER],
    },
]
export const DEBOUNCE_INTERVAL = 600
interface DefaultPaginationProps {
    sizes: Array<number>
    initialSize: number
}
export const defaultPagination: DefaultPaginationProps = {
    sizes: [5, 10, 20],
    initialSize: 10,
}

export const maxFileSize: UploadFileLimit = getUploadFileLimit()
