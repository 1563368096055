import { ConstructionIconsObjectType } from 'types/ConstructionIconsObjectType'
import { ReactComponent as BricksIcon } from './bricks.svg'
import { ReactComponent as ConstructionHammerIcon } from './construction-hammer.svg'
import { ReactComponent as ConstructionIcon } from './construction.svg'
import { ReactComponent as HammerIcon } from './hammer.svg'
import { ReactComponent as HelmetIcon } from './helmet.svg'
import { ReactComponent as PaintBucketIcon } from './paint-bucket.svg'
import { ReactComponent as PaintRollerIcon } from './paint-roller.svg'
import { ReactComponent as RulerAltIcon } from './ruler-alt.svg'
import { ReactComponent as RulerPencilIcon } from './ruler-pencil.svg'
import { ReactComponent as RulerIcon } from './ruler.svg'
import { ReactComponent as ShovelIcon } from './shovel.svg'
import { ReactComponent as TrowelIcon } from './trowel.svg'
import { ReactComponent as WheelbarrowIcon } from './wheelbarrow.svg'

const ConstructionIcons: ConstructionIconsObjectType = {
    bricks: BricksIcon,
    'construction-hammer': ConstructionHammerIcon,
    construction: ConstructionIcon,
    hammer: HammerIcon,
    helmet: HelmetIcon,
    'paint-bucket': PaintBucketIcon,
    'paint-roller': PaintRollerIcon,
    'ruler-alt': RulerAltIcon,
    'ruler-pencil': RulerPencilIcon,
    ruler: RulerIcon,
    shovel: ShovelIcon,
    trowel: TrowelIcon,
    wheelbarrow: WheelbarrowIcon,
}

export default ConstructionIcons
