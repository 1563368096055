import { useHistory } from 'react-router-dom'
import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'
import AuthPage from 'components/AuthPage'
import SignupForm from 'components/SignupForm'

const Signup = ({ translation }: BaseAppRoute) => {
    const history = useHistory()
    return (
        <AuthPage translation={translation} showLoginBtn={true}>
            <p className={'text-center text-3xl mb-2'}>{translation('titleSignUp')}</p>
            <SignupForm
                translation={translation}
                showOtherBtn={true}
                labelOtherBtn={translation('backHome')}
                onClickOtherBtnHandler={() => history.push('/')}
            />
        </AuthPage>
    )
}

export default Signup
