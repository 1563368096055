import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROLE } from 'enum/roles'
import { UserReducerType } from 'types/UserReducerType'
import { StateReducerType } from 'types/StateReducerType'
import { UserHasRequiredRole } from 'utils/authUtils'

interface MyRouteProps extends RouteProps {
    component: any
    roles?: Array<ROLE>
    user: UserReducerType
    rest?: any
}

// The PrivateRoute is only for authenticated user,if user is authenticated then only it can access to respective page otherwise it will redirect to login page
const PrivateRoute: React.FC<MyRouteProps> = ({ component: Component, roles = [], user, ...rest }) => {
    const renderPrivateRouteHandler = (props: any) => {
        return UserHasRequiredRole(true, typeof user.user.role !== 'undefined' ? user.user.role : ROLE.USER, roles) ===
            true ? (
            <Component {...props} />
        ) : (
            <Redirect to="/access-denied" />
        )
    }

    return user.authenticated === true ? (
        <Route
            {...rest}
            render={(props) =>
                Object.keys(user.user).length > 0 ? renderPrivateRouteHandler(props) : <Component {...props} />
            }
        />
    ) : user.authenticated === false ? (
        <Route {...rest} render={() => <Redirect to="/sign-in" />} />
    ) : (
        <></>
    )
}

const mapStateToProps = (state: StateReducerType) => ({
    user: state.user,
})

export default connect(mapStateToProps)(PrivateRoute)
