import React from 'react'
import { useHistory } from 'react-router-dom'
import { BaseComponent } from 'interfaces/BaseComponent.interfaces'
import PrimaryButton from 'components/PrimaryButton'
import SvgIcon from 'components/SvgIcon'

const LandingFeatures = ({ translation }: BaseComponent) => {
    const history = useHistory()

    return (
        <React.Fragment>
            <section id={'characteristics'} className={'relative'}>
                {/* Section background (needs .relative class on parent and next sibling elements) */}
                <div className={'absolute inset-0 bg-gray-50 pointer-events-none mb-16'} aria-hidden={'true'}></div>
                <div
                    className={'absolute left-0 right-0 m-auto w-px p-px h-20 bg-primary transform -translate-y-1/2'}
                ></div>

                <div className={'relative max-w-7xl mx-auto px-4 sm:px-6'}>
                    <div className={'pt-12 md:pt-20'}>
                        <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                            <h2 className={'text-4xl md:text-5xl font-black tracking-tight mb-6 md:mb-4'}>
                                {translation('theCharacteristicsOf')}{' '}
                                <span className={'bg-clip-text text-transparent text-primary'}>EthicMind</span>
                            </h2>
                        </div>

                        <div className="md:grid md:grid-cols-12 md:gap-6">
                            <div
                                // className={'max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 md:mt-6'}
                                className={'max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-12'}
                                data-aos={'fade-right'}
                            >
                                <div className={'mb-8 md:mb-0'}>
                                    <span
                                        className={
                                            'flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg'
                                        }
                                    >
                                        <div className={'w-full'}>
                                            <div className={'font-bold leading-snug tracking-tight mb-1'}>
                                                {translation('1stCharacteristicTitle')}
                                            </div>
                                            <div className={'text-gray-600 text-justify'}>
                                                {translation('1stCharacteristicText')}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'flex justify-center items-center w-12 h-12 bg-primary rounded-full shadow flex-shrink-0 ml-3'
                                            }
                                        >
                                            <SvgIcon
                                                className={'w-8 h-8 text-white'}
                                                folder={'heroicons'}
                                                name={'currency-dollar'}
                                            />
                                        </div>
                                    </span>
                                    <span
                                        className={
                                            'flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg'
                                        }
                                    >
                                        <div className={'w-full'}>
                                            <div className={'font-bold leading-snug tracking-tight mb-1'}>
                                                {translation('2ndCharacteristicTitle')}
                                            </div>
                                            <div className={'text-gray-600 text-justify'}>
                                                {translation('2ndCharacteristicText')}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'flex justify-center items-center w-12 h-12 bg-primary rounded-full shadow flex-shrink-0 ml-3'
                                            }
                                        >
                                            <SvgIcon
                                                className={'w-8 h-8 text-white'}
                                                folder={'heroicons'}
                                                name={'chart-square-bar'}
                                            />
                                        </div>
                                    </span>
                                    <span
                                        className={
                                            'flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg'
                                        }
                                    >
                                        <div className={'w-full'}>
                                            <div className={'font-bold leading-snug tracking-tight mb-1'}>
                                                {translation('3rdCharacteristicTitle')}
                                            </div>
                                            <div className={'text-gray-600 text-justify'}>
                                                {translation('3rdCharacteristicText')}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'flex justify-center items-center w-12 h-12 bg-primary rounded-full shadow flex-shrink-0 ml-3'
                                            }
                                        >
                                            <SvgIcon
                                                className={'w-8 h-8 text-white'}
                                                folder={'heroicons'}
                                                name={'star'}
                                            />
                                        </div>
                                    </span>
                                    <span
                                        className={
                                            'flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg'
                                        }
                                    >
                                        <div className={'w-full'}>
                                            <div className={'font-bold leading-snug tracking-tight mb-1'}>
                                                {translation('4thCharacteristicTitle')}
                                            </div>
                                            <div className={'text-gray-600 text-justify'}>
                                                {translation('4thCharacteristicText1stPart')}{' '}
                                                <span className={'italic'}>
                                                    {translation('4thCharacteristicText2ndPart')}
                                                </span>{' '}
                                                {translation('4thCharacteristicText3rdPart')}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'flex justify-center items-center w-12 h-12 bg-primary rounded-full shadow flex-shrink-0 ml-3'
                                            }
                                        >
                                            <SvgIcon
                                                className={'w-8 h-8 text-white'}
                                                folder={'heroicons'}
                                                name={'shield-check'}
                                            />
                                        </div>
                                    </span>
                                    <span
                                        className={
                                            'flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg'
                                        }
                                    >
                                        <div className={'w-full'}>
                                            <div className={'font-bold leading-snug tracking-tight mb-1'}>
                                                {translation('5thCharacteristicTitle')}
                                            </div>
                                            <div className={'text-gray-600 text-justify'}>
                                                {translation('5thCharacteristicText')}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'flex justify-center items-center w-12 h-12 bg-primary rounded-full shadow flex-shrink-0 ml-3'
                                            }
                                        >
                                            <SvgIcon
                                                className={'w-8 h-8 text-white'}
                                                folder={'heroicons'}
                                                name={'thumb-up'}
                                            />
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={'relative'}>
                {/* Illustration behind content */}
                <div
                    className={'absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -mb-32'}
                    aria-hidden={'true'}
                >
                    <svg width={'1760'} height={'518'} viewBox={'0 0 1760 518'} xmlns={'http://www.w3.org/2000/svg'}>
                        <defs>
                            <linearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'illustration-02'}>
                                <stop stopColor={'#FFF'} offset={'0%'} />
                                <stop stopColor={'#EAEAEA'} offset={'77.402%'} />
                                <stop stopColor={'#DFDFDF'} offset={'100%'} />
                            </linearGradient>
                        </defs>
                        <g transform={'translate(0 -3)'} fill={'url(#illustration-02)'} fillRule={'evenodd'}>
                            <circle cx={'1630'} cy={'128'} r={'128'} />
                            <circle cx={'178'} cy={'481'} r={'40'} />
                        </g>
                    </svg>
                </div>
                <div className={'max-w-7xl mx-auto px-4 sm:px-6'}>
                    <div className={'py-12 md:py-16'}>
                        {/* Section header */}
                        <div className={'max-w-3xl mx-auto text-center pb-12 md:pb-0'}>
                            <h2 className={'text-3xl md:text-4xl font-extrabold tracking-tight mb-4'}>
                                {translation('titleCharacteristicFooter1stPart')}{' '}
                                <span className={'bg-clip-text text-transparent text-primary'}>EthicMind</span>{' '}
                                {translation('titleCharacteristicFooter2Part')}
                            </h2>
                            <p className={'text-xl text-gray-600 mb-8'} data-aos={'zoom-y-out'}>
                                {translation('subTitleCharacteristicFooter')}
                            </p>
                            <div
                                className={'max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center'}
                                data-aos={'zoom-y-out'}
                                data-aos-delay={'300'}
                            >
                                <div>
                                    <PrimaryButton
                                        text={translation('startNow')}
                                        className={
                                            'inline-flex! items-center! justify-center! text-xl! font-medium! leading-snug! transition-all! py-2! px-4! w-full! mb-4! sm:w-auto! sm:mb-0!'
                                        }
                                        onClickHandler={() => history.push('/sign-up')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default LandingFeatures
