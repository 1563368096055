import { useHistory } from 'react-router-dom'
import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'
import SvgIcon from 'components/SvgIcon'
import ImageSrc from 'resources/images/misc/404.jpg'

const NotFound = ({ translation }: BaseAppRoute) => {
    const history = useHistory()
    return (
        <div className={'min-w-screen min-h-screen bg-primary flex items-center p-5 lg:p-20 overflow-hidden relative'}>
            <div
                className={
                    'flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left'
                }
            >
                <div className={'w-full md:w-1/2'}>
                    <div className={'mb-10 lg:mb-20'}></div>
                    <div className={'mb-10 md:mb-20 text-gray-600 font-light'}>
                        <h1 className={'font-black uppercase text-3xl lg:text-5xl text-primary mb-10'}>
                            {translation('notFoundPageTitle')}
                        </h1>
                        <p>{translation('notFoundPage1stParagraph')}</p>
                        <p>{translation('notFoundPage2ndParagraph')}</p>
                    </div>
                    <div className={'mb-20 md:mb-0'}>
                        <button
                            className={
                                'flex justify-center capitalize text-lg font-light outline-none focus:outline-none transform transition-all hover:scale-110  text-gray-700 hover:text-primary'
                            }
                            onClick={() => history.push('/')}
                        >
                            <SvgIcon className={'w-8 h-8 mr-2'} folder={'heroicons'} name={'arrow-narrow-left'} />
                            {translation('goHome')}
                        </button>
                    </div>
                </div>
                <div className={'w-full md:w-1/2 text-center'}>
                    <img src={ImageSrc} className={'w-full max-w-lg lg:max-w-5xl mx-auto'} />
                </div>
            </div>
            <div
                className={
                    'w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform'
                }
            ></div>
            <div
                className={
                    'w-96 h-full bg-yellow-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform'
                }
            ></div>
        </div>
    )
}

export default NotFound
