import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useIsTablet } from 'hooks/useDeviceDetect'
import { BaseComponentChangeableLanguage } from 'interfaces/BaseComponentChangeableLanguage.interfaces'
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'

const PageMainContainer = styled.div`
    min-height: calc(100vh - 4.45rem);
`

interface PageProps extends BaseComponentChangeableLanguage {
    children: JSX.Element | React.ReactChild | ReactNode | React.FC
    className?: string
    fullWidth?: boolean
    searchIsEnabled?: boolean
    action?: JSX.Element | React.ReactChild | ReactNode | React.FC
}

const Page = ({
    children,
    className,
    fullWidth = false,
    searchIsEnabled = true,
    action,
    translation,
    i18n,
    languages,
}: PageProps) => {
    const isNotLgScreen: boolean = useIsTablet()
    const [sidebarActive, setSidebarActive] = useState<boolean>(false)

    useEffect(() => {
        if (isNotLgScreen === false) {
            setSidebarActive(false)
        }
    }, [isNotLgScreen])

    return (
        <div>
            <Navbar
                sidebarActive={sidebarActive}
                setSidebarActive={setSidebarActive}
                searchIsEnabled={searchIsEnabled}
                action={action}
            />
            <div className={'flex overflow-hidden bg-white pt-16'}>
                <Sidebar
                    sidebarActive={sidebarActive}
                    searchIsEnabled={searchIsEnabled}
                    translation={translation}
                    i18n={i18n}
                    languages={languages}
                />
                <PageMainContainer
                    className={`w-full h-full ${
                        fullWidth === false ? 'container' : ''
                    } bg-gray-50 relative overflow-y-auto lg:ml-64`}
                >
                    <main className={`${className ?? ''}`}>{children}</main>
                </PageMainContainer>
            </div>
        </div>
    )
}

export default Page
