import React from 'react'

interface AlertErrorMessageProps {
    message: string
    className?: string
}

const AlertErrorMessage = ({ message, className }: AlertErrorMessageProps) => {
    return (
        <div className={`px-4 py-3 leading-normal text-red-700 bg-red-100 rounded-lg ${className ?? ''}`}>
            <p>{message}</p>
        </div>
    )
}

export default AlertErrorMessage
