import { ReactNode } from 'react'
import styled from 'styled-components'
import LogoImage from 'resources/images/logo/logo.png'
import SvgIcon from 'components/SvgIcon'

const NavbarComponent = styled.nav`
    height: 4.45rem;
`

interface NavbarProps {
    sidebarActive: boolean
    setSidebarActive: React.Dispatch<React.SetStateAction<boolean>>
    searchIsEnabled: boolean
    action?: JSX.Element | React.ReactChild | ReactNode | React.FC
}

const Navbar = ({ sidebarActive, setSidebarActive, searchIsEnabled, action }: NavbarProps) => {
    return (
        <NavbarComponent className={'bg-white border-b border-gray-200 fixed z-40 w-full'}>
            <div className={'px-3 py-3 lg:px-5 lg:pl-3'}>
                <div className={'flex items-center justify-between'}>
                    <div className={'flex items-center justify-start'}>
                        <button
                            className={
                                'lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded'
                            }
                            onClick={() => setSidebarActive(!sidebarActive)}
                        >
                            {sidebarActive === true ? (
                                <SvgIcon className={'w-6 h-6'} folder={'heroicons'} name={'x'} />
                            ) : (
                                <SvgIcon className={'w-6 h-6'} folder={'heroicons'} name={'menu-alt-1'} />
                            )}
                        </button>
                        <div className={'flex justify-start items-center h-full'}>
                            <img src={LogoImage} className={'w-auto h-10 sm:h-12 mr-4'} alt={'EthicMind'} />
                            <span className={'text-xl text-primary font-black'}>EthicMind</span>
                        </div>
                        {searchIsEnabled === true && (
                            <div className={'hidden lg:block lg:pl-32'}>
                                <label className={'sr-only'} htmlFor={'topbar-search'}>
                                    Search
                                </label>
                                <div className={'mt-1 relative lg:w-64'}>
                                    <div
                                        className={
                                            'absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'
                                        }
                                    >
                                        <SvgIcon
                                            className={'w-5 h-5 text-gray-500'}
                                            folder={'heroicons'}
                                            name={'search'}
                                        />
                                    </div>
                                    <input
                                        type={'text'}
                                        id={'topbar-search'}
                                        className={
                                            'bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 p-2.5'
                                        }
                                        placeholder={'Search'}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={'flex items-center'}>
                        {searchIsEnabled === true && (
                            <button
                                className={
                                    'lg:hidden text-gray-500 hover:text-gray-900 hover:bg-gray-100 p-2 rounded-lg'
                                }
                                onClick={() => setSidebarActive(!sidebarActive)}
                            >
                                <span className={'sr-only'}>Search</span>
                                <SvgIcon className={'w-6 h-6'} folder={'heroicons'} name={'search'} />
                            </button>
                        )}
                        {action}
                    </div>
                </div>
            </div>
        </NavbarComponent>
    )
}

export default Navbar
