import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikHelpers, useFormik } from 'formik'
import toast, { Toaster } from 'react-hot-toast'
import { AxiosError, AxiosResponse } from 'axios'
import ResetPasswordServices from 'services/ResetPasswordServices'
import { ConfirmResetPswFormInputNameType } from 'types/ConfirmResetPswFormInputNameType'
import { ConfirmResetPwsFormProps } from 'interfaces/ConfirmResetPwsFormProps.interfaces'
import { DataConfirmResetPsw } from 'interfaces/DataConfirmResetPsw.interfaces'
import { ApiResponse } from 'interfaces/ApiResponse.interfaces'
import { ConfirmResetPswResponse } from 'interfaces/ConfirmResetPswResponse.interfaces'
import InputText from 'components/InputText'
import InputTextError from 'components/InputTextError'
import ToastNotification from 'components/ToastNotification'
import SvgIcon from 'components/SvgIcon'
import PrimaryButton from 'components/PrimaryButton'
import SecondaryButton from 'components/SecondaryButton'
import LoadingSpinner from 'components/LoadingSpinner'

const ConfirmResetPwsForm = ({
    guid,
    translation,
    invalid = false,
    showOtherBtn = false,
    labelOtherBtn,
    onClickOtherBtnHandler,
}: ConfirmResetPwsFormProps) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [executeRedirectToLogin, setExecuteRedirectToLogin] = useState(false)

    const confirmResetPwsForm = useFormik({
        initialValues: {
            resetPwdGuid: guid,
            username: '',
            newPassword: '',
            confirmPassword: '',
        },
        validate: (data: DataConfirmResetPsw) => {
            const errors: Partial<DataConfirmResetPsw> = {}

            if (!data.username || data.username.trim() === '') {
                errors.username = translation('errorEmailRequired')
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.username)) {
                errors.username = translation('errorEmailInvalid')
            }

            if (!data.newPassword || data.newPassword.trim().length === 0) {
                errors.newPassword = translation('errorPasswordRequired')
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}$/.test(data.newPassword)) {
                errors.newPassword = translation('errorPasswordInvalid')
            }

            if (!data.confirmPassword || data.confirmPassword.trim().length === 0) {
                errors.confirmPassword = translation('errorConfirmPasswordRequired')
            } else if (data.confirmPassword !== data.newPassword) {
                errors.confirmPassword = translation('errorConfirmPasswordSamePassword')
            }

            return errors
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit: (formValue: DataConfirmResetPsw, helpers: FormikHelpers<any>) => {
            setLoading(true)
            ResetPasswordServices.confirmResetPassword(formValue)
                .then((response: AxiosResponse<ConfirmResetPswResponse>) => {
                    const hasBeenSuccessful =
                        !!response.data.data.id &&
                        response.data.data.id > 0 &&
                        !!response.data.data.guid &&
                        response.data.data.guid !== ''
                            ? true
                            : false
                    setLoading(false)
                    setDisabled(hasBeenSuccessful)
                    toast.custom(
                        (t) => (
                            <ToastNotification
                                t={t}
                                severity={hasBeenSuccessful === true ? 'success' : 'error'}
                                message={translation(
                                    hasBeenSuccessful === true ? 'messageConfirmResetPswSuccess' : 'messageGenericError'
                                )}
                            />
                        ),
                        {
                            duration: 5000,
                        }
                    )
                    setRedirectToLogin(hasBeenSuccessful)
                })
                .catch((err: AxiosError<ApiResponse>) => {
                    setLoading(false)
                    setDisabled(false)
                    toast.custom((t) => (
                        <ToastNotification
                            t={t}
                            severity={'error'}
                            message={translation(getErrorLabelHandler(err?.response?.data.message ?? ''))}
                        />
                    ))
                })
        },
    })

    const getErrorLabelHandler = (error: string): string => {
        switch (error) {
            case 'Username or link not valid':
                return 'messageErrorUsernameLink'
            case 'Password not valid':
                return 'messageErrorPasswordNotValid'
            case 'New password must be different old password':
                return 'messageErrorNewPasswordDifferent'
            default:
                return 'messageGenericError'
        }
    }

    const isFormFieldValid = (name: ConfirmResetPswFormInputNameType) =>
        !!(confirmResetPwsForm.touched[name] && confirmResetPwsForm.errors[name])

    const checkFormInputError = (name: ConfirmResetPswFormInputNameType): boolean => {
        return isFormFieldValid(name)
    }

    const getFormErrorMessage = (name: ConfirmResetPswFormInputNameType) => {
        return (
            isFormFieldValid(name) && (
                <InputTextError className={'mt-0.5! leading-4'} message={confirmResetPwsForm.errors[name]} />
            )
        )
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined

        if (redirectToLogin === true) {
            intervalId = setInterval(() => {
                setExecuteRedirectToLogin(true)
            }, 2100)
        }
        return () => {
            if (typeof intervalId !== 'undefined') {
                clearInterval(intervalId)
            }
        }
    }, [redirectToLogin])

    useEffect(() => {
        if (executeRedirectToLogin === true) {
            history.push('/sign-in')
        }
    }, [executeRedirectToLogin, history])

    return (
        <React.Fragment>
            <Toaster position={'top-right'} reverseOrder={false} />
            {invalid ? (
                <div>
                    <p className={'text-center text-lg mb-6'}>{translation('subtitleLinkInvalid')}</p>
                    <div className={'flex items-center justify-center mt-2 sm:mt-4'}>
                        <PrimaryButton
                            type={'button'}
                            className={'w-32 mr-3 py-2!'}
                            text={translation('resetPasswordBtn')}
                            onClickHandler={() => history.push('/reset-password')}
                            disabled={false}
                        />
                        {!!showOtherBtn === true && (
                            <SecondaryButton
                                type={'button'}
                                className={'py-2!'}
                                text={labelOtherBtn}
                                onClickHandler={onClickOtherBtnHandler}
                                disabled={false}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <form className={'flex flex-col'} onSubmit={confirmResetPwsForm.handleSubmit}>
                    <InputText
                        type={'hidden'}
                        className={'w-full h-10 -ml-10 pl-10 pr-3'}
                        name={'resetPwdGuid'}
                        onChange={confirmResetPwsForm.handleChange}
                        error={checkFormInputError('resetPwdGuid')}
                        value={guid}
                    />
                    <div className={'flex flex-col mb-4'}>
                        <label
                            htmlFor={'username'}
                            className={`text-xs px-1 ${
                                checkFormInputError('username') === true ? 'text-red-700' : 'text-neutral-700'
                            }`}
                        >
                            {translation('emailUsername')}
                        </label>
                        <div className="flex">
                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                <SvgIcon folder={'heroicons'} name={'mail'} className={'w-5 text-gray-400'} />
                            </div>
                            <InputText
                                type={'text'}
                                className={'w-full h-10 -ml-10 pl-10 pr-3'}
                                name={'username'}
                                placeholder={'your@email.com'}
                                onChange={confirmResetPwsForm.handleChange}
                                error={checkFormInputError('username')}
                            />
                        </div>
                        {getFormErrorMessage('username')}
                    </div>
                    <div className={'flex flex-col mb-4'}>
                        <label
                            htmlFor={'newPassword'}
                            className={`text-xs px-1 ${
                                checkFormInputError('newPassword') === true ? 'text-red-700' : 'text-neutral-700'
                            }`}
                        >
                            {translation('password')}
                        </label>
                        <div className="flex">
                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                <SvgIcon folder={'heroicons'} name={'key'} className={'w-5 text-gray-400'} />
                            </div>
                            <InputText
                                type={'password'}
                                className={'w-full h-10 -ml-10 pl-10 pr-3'}
                                name={'newPassword'}
                                placeholder={'************'}
                                onChange={confirmResetPwsForm.handleChange}
                                error={checkFormInputError('newPassword')}
                            />
                        </div>
                        {getFormErrorMessage('newPassword')}
                    </div>
                    <div className={'flex flex-col mb-4'}>
                        <label
                            htmlFor={'confirmPassword'}
                            className={`text-xs px-1 ${
                                checkFormInputError('confirmPassword') === true ? 'text-red-700' : 'text-neutral-700'
                            }`}
                        >
                            {translation('confirmPassword')}
                        </label>
                        <div className="flex">
                            <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                <SvgIcon folder={'heroicons'} name={'key'} className={'w-5 text-gray-400'} />
                            </div>
                            <InputText
                                type={'password'}
                                className={'w-full h-10 -ml-10 pl-10 pr-3'}
                                name={'confirmPassword'}
                                placeholder={'************'}
                                onChange={confirmResetPwsForm.handleChange}
                                error={checkFormInputError('confirmPassword')}
                            />
                        </div>
                        {getFormErrorMessage('confirmPassword')}
                    </div>
                    {loading ? (
                        <div className={'flex items-center justify-center'}>
                            <LoadingSpinner width={'42px'} height={'42px'} />
                        </div>
                    ) : (
                        <div className={'flex items-center justify-center mt-2 sm:mt-4'}>
                            <PrimaryButton
                                type={'submit'}
                                className={'w-32 mr-3 py-2!'}
                                text={translation('confirm')}
                                disabled={disabled}
                            />
                            {!!showOtherBtn === true && (
                                <SecondaryButton
                                    type={'button'}
                                    className={'py-2!'}
                                    text={labelOtherBtn}
                                    onClickHandler={onClickOtherBtnHandler}
                                    disabled={disabled}
                                />
                            )}
                        </div>
                    )}
                </form>
            )}
        </React.Fragment>
    )
}

export default ConfirmResetPwsForm
