import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import useElementSize from 'hooks/useElementSize'
import { MenuElement } from 'types/MenuElement'
import { IconType } from 'types/IconType'
import { BaseComponent } from 'interfaces/BaseComponent.interfaces'
import SvgIcon from 'components/SvgIcon'
import { getIconMenu } from 'utils/themeUtils'

interface SubItemsContainerProps {
    collapse: boolean
    heightItem: string
    className?: string
}

const SubItemsContainer = styled.div<SubItemsContainerProps>`
    height: ${(props) => (props.collapse === false && !!props.heightItem ? props.heightItem : '0px')};
    margin: 0 !important;
    overflow: hidden;
`

interface MenuItemProps extends BaseComponent, MenuElement {
    activeItem?: string
}

const MenuItem = ({
    code,
    href,
    external = false,
    subItems = [],
    hasIcon = false,
    icon,
    activeItem,
    onClickHandler,
    translation,
}: MenuItemProps) => {
    const initSubItemsHeight = '0px'
    const ItemCloseIcon: IconType | undefined = getIconMenu('itemClose')
    const ItemOpenIcon: IconType | undefined = getIconMenu('itemOpen')
    const history = useHistory()
    const [collapseSubItems, setCollapseSubItems] = useState(false)
    const [subItemsHeight, setSubItemsHeight] = useState(initSubItemsHeight)
    const [subItemsStyle, setSubItemsStyle] = useState<React.CSSProperties>({})
    const [subItemsRef, { height }] = useElementSize()

    useEffect(() => {
        setSubItemsHeight(`${height}px`)
    }, [height])

    const goToHandler = () => {
        goToLink(href, external)
    }

    const goToLink = (link: string, isExternalLink = false) => {
        if (isExternalLink === true) {
            window.open(link, '_blank')
        } else {
            history.push(link)
        }
    }

    const collapseSubItemsHandler = () => {
        setSubItemsStyle({
            MozTransition: 'height 0.35s ease',
            msTransition: 'height 0.35s ease',
            WebkitTransition: 'height 0.35s ease',
            transition: 'height 0.35s ease',
        })
        const prevState = collapseSubItems
        setCollapseSubItems(!collapseSubItems)
        setSubItemsHeight(!prevState ? initSubItemsHeight : `${height}px`)
    }

    return (
        <React.Fragment>
            <li key={code}>
                <span
                    className={`text-base font-normal rounded-lg flex items-center p-2 ${
                        activeItem === code
                            ? 'text-white bg-primary hover:text-gray-900'
                            : 'text-gray-900 hover:bg-gray-100'
                    } group cursor-pointer`}
                    onClick={() => {
                        subItems.length > 0
                            ? collapseSubItemsHandler()
                            : !!onClickHandler
                            ? onClickHandler()
                            : goToHandler()
                    }}
                >
                    {subItems.length > 0 ? (
                        <span
                            className={
                                'flex space-x-4 items-center transition-transform transform rounded-md focus:outline-none'
                            }
                        >
                            {hasIcon === true && !!icon && !!icon.folder && !!icon.name && (
                                <SvgIcon
                                    className={'w-6 h-6 text-gray-500 group-hover:text-gray-900 transition duration-75'}
                                    folder={icon.folder}
                                    name={icon.name}
                                />
                            )}
                            <span className={'ml-3'}>{translation(`menuItems${'.'}${code}`)}</span>
                            {collapseSubItems === true ? (
                                <ItemOpenIcon className={'w-4 h-4'} />
                            ) : (
                                <ItemCloseIcon className={'w-4 h-4 transition-transform'} />
                            )}
                        </span>
                    ) : (
                        <>
                            {hasIcon === true && !!icon && !!icon.folder && !!icon.name && (
                                <SvgIcon
                                    className={`w-6 h-6 ${
                                        activeItem === code ? 'text-white bg-primary' : 'text-gray-500'
                                    } group-hover:text-gray-900 transition duration-75`}
                                    folder={icon.folder}
                                    name={icon.name}
                                />
                            )}
                            <span className={'ml-3'}>{translation(`menuItems${'.'}${code}`)}</span>
                        </>
                    )}
                </span>
            </li>
            {subItems.length > 0 && (
                <SubItemsContainer collapse={collapseSubItems} heightItem={subItemsHeight} style={subItemsStyle}>
                    <div ref={subItemsRef}>
                        {subItems.map((subItem: MenuElement, index: number) => (
                            <span
                                className={`flex py-2 pl-12 items-center text-sm cursor-pointer ${
                                    activeItem === subItem.code
                                        ? 'text-white bg-primary hover:text-gray-900'
                                        : 'hover:text-primary'
                                }`}
                                onClick={() => {
                                    !!subItem.onClickHandler
                                        ? subItem.onClickHandler()
                                        : goToLink(subItem.href, subItem.external)
                                }}
                                key={index}
                            >
                                {translation(`menuItems${'.'}${subItem.code}`)}
                            </span>
                        ))}
                    </div>
                </SubItemsContainer>
            )}
        </React.Fragment>
    )
}

export default MenuItem
