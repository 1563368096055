import { FlagsIconsObjectType } from 'types/FlagsIconsObjectType'
import { ReactComponent as FlagEnIcon } from './en.svg'
import { ReactComponent as FlagItIcon } from './it.svg'

const FlagsIcons: FlagsIconsObjectType = {
    EN: FlagEnIcon,
    IT: FlagItIcon,
}

export default FlagsIcons
