import { SpinnerIconsObjectType } from 'types/SpinnerIconsObjectType'
import { ReactComponent as HourglassIcon } from './hourglass.svg'
import { ReactComponent as SpinnerArrowIcon } from './spinner-arrow.svg'
import { ReactComponent as SpinnerSolidIcon } from './spinner-solid.svg'
import { ReactComponent as SpinnerIcon } from './spinner.svg'

const SpinnerIcons: SpinnerIconsObjectType = {
    hourglass: HourglassIcon,
    'spinner-arrow': SpinnerArrowIcon,
    'spinner-solid': SpinnerSolidIcon,
    spinner: SpinnerIcon,
}

export default SpinnerIcons
