import { CommunicationIconsObjectType } from 'types/CommunicationIconsObjectType'
import { ReactComponent as AnchorIcon } from './anchor.svg'
import { ReactComponent as BiCycleIcon } from './bi-cycle.svg'
import { ReactComponent as BridgeIcon } from './bridge.svg'
import { ReactComponent as BubbleIcon } from './bubble.svg'
import { ReactComponent as BullhornIcon } from './bullhorn.svg'
import { ReactComponent as BusIcon } from './bus.svg'
import { ReactComponent as CarAltIcon } from './car-alt.svg'
import { ReactComponent as CarIcon } from './car.svg'
import { ReactComponent as CaravanIcon } from './caravan.svg'
import { ReactComponent as CommentsAltIcon } from './comments-alt.svg'
import { ReactComponent as CommentsReplyIcon } from './comments-reply.svg'
import { ReactComponent as CommentsIcon } from './comments.svg'
import { ReactComponent as EnvelopeIcon } from './envelope.svg'
import { ReactComponent as HelicopterIcon } from './helicopter.svg'
import { ReactComponent as InboxIcon } from './inbox.svg'
import { ReactComponent as PhoneSetIcon } from './phone-set.svg'
import { ReactComponent as PhoneIcon } from './phone.svg'
import { ReactComponent as PlaneIcon } from './plane.svg'
import { ReactComponent as PopupIcon } from './popup.svg'
import { ReactComponent as PostcardIcon } from './postcard.svg'
import { ReactComponent as ReplyIcon } from './reply.svg'
import { ReactComponent as RoadIcon } from './road.svg'
import { ReactComponent as ScooterIcon } from './scooter.svg'
import { ReactComponent as ShipIcon } from './ship.svg'
import { ReactComponent as SupportIcon } from './support.svg'
import { ReactComponent as TaxiIcon } from './taxi.svg'
import { ReactComponent as TrainAltIcon } from './train-alt.svg'
import { ReactComponent as TrainIcon } from './train.svg'

const CommunicationIcons: CommunicationIconsObjectType = {
    anchor: AnchorIcon,
    'bi-cycle': BiCycleIcon,
    bridge: BridgeIcon,
    bubble: BubbleIcon,
    bullhorn: BullhornIcon,
    bus: BusIcon,
    'car-alt': CarAltIcon,
    car: CarIcon,
    caravan: CaravanIcon,
    'comments-alt': CommentsAltIcon,
    'comments-reply': CommentsReplyIcon,
    comments: CommentsIcon,
    envelope: EnvelopeIcon,
    helicopter: HelicopterIcon,
    inbox: InboxIcon,
    'phone-set': PhoneSetIcon,
    phone: PhoneIcon,
    plane: PlaneIcon,
    popup: PopupIcon,
    postcard: PostcardIcon,
    reply: ReplyIcon,
    road: RoadIcon,
    scooter: ScooterIcon,
    ship: ShipIcon,
    support: SupportIcon,
    taxi: TaxiIcon,
    'train-alt': TrainAltIcon,
    train: TrainIcon,
}

export default CommunicationIcons
