import React, { useEffect } from 'react'
import { HashRouter } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import store from 'redux/store'
import { useTranslation } from 'react-i18next'
import { availableLanguages } from 'i18n'
import AOS from 'aos'
import 'aos/dist/aos.css'
import AppRouter from 'components/AppRouter'
import { CheckAuthentication } from 'utils/authUtils'
import { getQueryClient } from 'utils/internetUtils'

const App = () => {
    const { t, i18n } = useTranslation()

    useEffect(() => {
        CheckAuthentication()
        AOS.init({
            once: true,
            disable: 'phone',
            duration: 700,
            easing: 'ease-out-cubic',
        })
    }, [])

    return (
        <div className="app">
            <Provider store={store}>
                <QueryClientProvider client={getQueryClient()}>
                    <HashRouter>
                        <AppRouter t={t} i18n={i18n} languages={availableLanguages} />
                    </HashRouter>
                </QueryClientProvider>
            </Provider>
        </div>
    )
}

export default App
