import {
    SET_USER,
    SET_TMP_LOGIN_DATA,
    CLEAR_TMP_LOGIN_DATA,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    SET_UNSIGNED_ALL_DOCUMENTS,
    SET_TMP_USER,
    LOADING_USER,
} from '../types'

const initialState = {
    authenticated: null,
    credentials: {},
    loading: false,
    tmpUser: {},
    user: {},
}

export default function (state = initialState, action: any) {
    const { type, payload } = action
    switch (type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
                tmpUser: {},
            }
        case SET_UNAUTHENTICATED:
            return {
                ...state,
                authenticated: false,
                user: {},
            }
        case SET_UNSIGNED_ALL_DOCUMENTS:
            return {
                ...state,
                hasSignedDocuments: false,
                user: {},
            }
        case SET_TMP_LOGIN_DATA:
            return {
                authenticated: false,
                loading: false,
                credentials: payload,
            }
        case CLEAR_TMP_LOGIN_DATA:
            return {
                ...state,
                credentials: {},
            }
        case SET_USER:
            return {
                ...state,
                loading: false,
                credentials: {},
                tmpUser: {},
                user: payload,
            }
        case SET_TMP_USER:
            return {
                ...state,
                loading: false,
                credentials: {},
                tmpUser: payload,
                user: {},
            }
        case LOADING_USER:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
