import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { loginOtpVerify, cancelLoginOtpVerify } from 'redux/actions/userActions'
import { Login2FAFormProps } from 'interfaces/Login2FAFormProps.interfaces'
import { StateReducerType } from 'types/StateReducerType'
import { Login2FAFormValues } from 'types/Login2FAFormValues'
import { FormikHelpers, useFormik } from 'formik'
import InputText from 'components/InputText'
import InputTextError from 'components/InputTextError'
import PrimaryButton from 'components/PrimaryButton'
import SecondaryButton from 'components/SecondaryButton'
import AlertErrorMessage from 'components/AlertErrorMessage'
import LoadingSpinner from 'components/LoadingSpinner'
import { ROLE } from 'enum/roles'

const Login2FAForm = ({ UI, user, loginOtpVerify, cancelLoginOtpVerify, translation }: Login2FAFormProps) => {
    const otpLength = 6
    const history = useHistory()
    const [login2FAError, setLogin2FAError] = useState(null)
    const [loading, setLoading] = useState(false)
    const login2FAForm = useFormik({
        initialValues: {
            username: user.credentials?.username,
            password: user.credentials?.password,
            code: '',
        },
        validate: (data: Login2FAFormValues) => {
            const errors: Partial<Login2FAFormValues> = {}

            if (!data.code || data.code.trim() === '') {
                errors.code = translation('errorOTPCodeRequired')
            }

            return errors
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit: (formValue: Login2FAFormValues, helpers: FormikHelpers<Login2FAFormValues>) => {
            setLoading(true)
            loginOtpVerify(formValue.username, formValue.password, formValue.code)
        },
    })

    useEffect(() => {
        if (
            typeof user.credentials?.username === 'undefined' ||
            user.credentials?.username === null ||
            typeof user.credentials?.password === 'undefined' ||
            user.credentials?.password === null
        ) {
            history.push('/sign-in')
        }

        if (user.authenticated === true) {
            history.push(
                user.user.role === ROLE.ADMINISTRATOR && user.user.isAdministrator === true ? '/admin' : '/dashboard'
            )
        }
    }, [user, history])

    useEffect(() => {
        if (UI.error) {
            setLogin2FAError(UI.error)
        }
        setLoading(UI.loading)
    }, [UI])

    const isFormFieldValid = (name: 'username' | 'password' | 'code') =>
        !!(login2FAForm.touched[name] && login2FAForm.errors[name])

    const checkFormInputError = (name: 'username' | 'password' | 'code'): boolean => {
        return isFormFieldValid(name)
    }

    const getFormErrorMessage = (name: 'username' | 'password' | 'code') => {
        return isFormFieldValid(name) && <InputTextError message={login2FAForm.errors[name]} />
    }

    return (
        <form className={'flex flex-col pb-4'} onSubmit={login2FAForm.handleSubmit}>
            {!!login2FAError && login2FAError !== '' && <AlertErrorMessage message={login2FAError} />}
            <div className={'flex flex-col py-4'}>
                <label
                    htmlFor="code"
                    className={`text-sm  ${checkFormInputError('code') === true ? 'text-red-700' : 'text-neutral-700'}`}
                >
                    {translation('OTPCode')}
                </label>
                <InputText
                    type={'text'}
                    className={'pl-1.5 mt-2'}
                    name={'code'}
                    placeholder={translation('placeholderOTPCode')}
                    maxLength={otpLength}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                        }
                    }}
                    onChange={login2FAForm.handleChange}
                    error={checkFormInputError('code')}
                />
                {getFormErrorMessage('code')}
            </div>
            {loading ? (
                <div className={'flex items-center justify-center'}>
                    <LoadingSpinner width={'42px'} height={'42px'} />
                </div>
            ) : (
                <div className={'flex items-center justify-center mt-4'}>
                    <PrimaryButton type={'submit'} className={'mr-3 py-2!'} text={translation('verifyOTP')} />
                    <SecondaryButton
                        type={'button'}
                        className={'py-2!'}
                        text={translation('cancel')}
                        onClickHandler={() => cancelLoginOtpVerify()}
                    />
                </div>
            )}
        </form>
    )
}

// this map the states to our props in this funtional component
const mapStateToProps = (state: StateReducerType) => ({
    user: state.user,
    UI: state.UI,
})

// this map actions to our props in this functional component
const mapActionsToProps = {
    loginOtpVerify,
    cancelLoginOtpVerify,
}

export default connect(mapStateToProps, mapActionsToProps)(Login2FAForm)
