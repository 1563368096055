import { BaseComponent } from 'interfaces/BaseComponent.interfaces'

const LandingIntro = ({ translation }: BaseComponent) => {
    return (
        <section id={'intro'} className={'relative'}>
            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div className={'absolute inset-0 bg-gray-50 pointer-events-none'} aria-hidden={'true'}></div>
            <div
                className={'absolute left-0 right-0 m-auto w-px p-px h-20 bg-primary transform -translate-y-1/2'}
            ></div>
            <div className={'relative max-w-7xl mx-auto px-4 sm:px-6'}>
                <div className={'pt-12 md:pt-20'}>
                    <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                        <h2 className={'text-4xl md:text-5xl font-black tracking-tight mb-6 md:mb-4'}>
                            {translation('lowCostHighReward')}
                        </h2>
                        <p className={'text-justify text-xl text-gray-600 mb-5'} data-aos={'fade-right'}>
                            {translation('lowCostHighReward1stParagraph')}
                        </p>
                        <p className={'text-justify text-xl text-gray-600'} data-aos={'fade-left'}>
                            {translation('lowCostHighReward2ndParagraph1stPart')} <br />
                            {translation('lowCostHighReward2ndParagraph2ndPart')}{' '}
                            <span className={'text-black font-black'}>
                                {translation('lowCostHighReward2ndParagraph3rdPart')}
                            </span>{' '}
                            {translation('lowCostHighReward2ndParagraph4thPart')}
                        </p>
                    </div>
                    <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                        <h3
                            className={'text-2xl md:text-3xl font-black text-primary tracking-tight'}
                            data-aos={'zoom-y-out'}
                        >
                            {translation('lowCostHighReward1stTitle')}
                        </h3>
                    </div>
                    <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                        <p className={'text-justify text-xl text-gray-600 mb-5'} data-aos={'fade-right'}>
                            <span className={'text-black font-black'}>
                                {translation('lowCostHighReward3rdParagraph1stPart')}
                            </span>{' '}
                            {translation('lowCostHighReward3rdParagraph2ndPart')}{' '}
                            <span className={'text-black font-black'}>
                                {translation('lowCostHighReward3rdParagraph3rdPart')}
                            </span>
                        </p>
                        <p className={'text-justify text-xl text-gray-600'} data-aos={'fade-left'}>
                            {translation('lowCostHighReward4thParagraph1stPart')} <br />
                            {translation('lowCostHighReward4thParagraph2ndPart')}
                        </p>
                    </div>
                    <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                        <h3
                            className={'text-2xl md:text-3xl font-black text-primary tracking-tight'}
                            data-aos={'zoom-y-out'}
                        >
                            {translation('lowCostHighReward2ndTitle')}
                        </h3>
                    </div>
                    <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                        <p className={'text-justify text-xl text-gray-600 mb-5'} data-aos={'fade-right'}>
                            {translation('lowCostHighReward5thParagraph1stPart')}{' '}
                            <span className={'text-black font-black'}>
                                {translation('lowCostHighReward5thParagraph2ndPart')}
                            </span>{' '}
                            {translation('lowCostHighReward5thParagraph3rdPart')}{' '}
                            <span className={'text-black font-black'}>
                                {translation('lowCostHighReward5thParagraph4thPart')}
                            </span>{' '}
                            {translation('lowCostHighReward5thParagraph5thPart')}{' '}
                            <span className={'text-black font-black'}>
                                {translation('lowCostHighReward5thParagraph6thPart')}
                            </span>{' '}
                            {translation('lowCostHighReward5thParagraph7thPart')}
                        </p>
                        <p className={'text-justify text-xl text-gray-600'} data-aos={'fade-left'}>
                            {translation('lowCostHighReward6thParagraph1stPart')} <br />
                            {translation('lowCostHighReward6thParagraph2ndPart')}
                        </p>
                    </div>
                    <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                        <h3
                            className={'text-2xl md:text-3xl font-black text-primary tracking-tight'}
                            data-aos={'zoom-y-out'}
                        >
                            {translation('lowCostHighReward3rdTitle')}
                        </h3>
                    </div>
                    <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                        <p className={'text-justify text-xl text-gray-600 mb-5'} data-aos={'fade-left'}>
                            {translation('lowCostHighReward7thParagraph1stPart')} <br />
                            {translation('lowCostHighReward7thParagraph2ndPart')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingIntro
