import styled from 'styled-components'
import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'
import Page from 'components/Page'

const TestContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;
`

const AccessDenied = ({ translation, i18n, languages }: BaseAppRoute) => {
    return (
        <Page translation={translation} i18n={i18n} languages={languages}>
            <TestContainer>{translation('accessDenied')}</TestContainer>
        </Page>
    )
}

export default AccessDenied
