import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'
import BackgroundLogo from 'resources/images/background/login.jpg'
import LogoImage from 'resources/images/logo/logo.png'

const ComingSoon = ({ translation }: BaseAppRoute) => {
    return (
        <div className={'w-full'}>
            <div className={'flex bg-white h-screen'}>
                <div className={'w-full flex items-center justify-center text-center lg:text-left px-2 lg:w-1/2'}>
                    <div className={'w-full max-w-xl bg-clip-padding bg-transparent p-0 m-0 '}>
                        <div className={'py-8 px-4 mx-auto max-w-screen-md text-center'}>
                            <div
                                className={
                                    'flex items-center justify-center text-base text-gray-500 hover:text-primary transition-colors'
                                }
                            >
                                <img src={LogoImage} className={'w-auto h-20 mr-4'} alt={'EthicMind'} />
                                <span className={'text-5xl text-primary font-black'}>EthicMind</span>
                            </div>
                            <h1
                                className={
                                    'mt-10 font-bold tracking-tight leading-none lg:mb-6 text-6xl dark:text-white'
                                }
                            >
                                {translation('underConstruction')}
                            </h1>
                        </div>
                    </div>
                </div>
                <div className={'hidden lg:block lg:w-1/2'}>
                    <div className={'h-full bg-cover'} style={{ backgroundImage: `url(${BackgroundLogo})` }}>
                        <div className={'h-full opacity-50'}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon
