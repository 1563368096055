import { BaseComponent } from 'interfaces/BaseComponent.interfaces'

const LandingAboutUs = ({ translation }: BaseComponent) => {
    return (
        <section id={'about-us'} className={'relative'}>
            <div
                className={'absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -mb-32'}
                aria-hidden={'true'}
            >
                <svg width={'1760'} height={'518'} viewBox={'0 0 1760 518'} xmlns={'http://www.w3.org/2000/svg'}>
                    <defs>
                        <linearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'illustration-02'}>
                            <stop stopColor={'#FFF'} offset={'0%'} />
                            <stop stopColor={'#EAEAEA'} offset={'77.402%'} />
                            <stop stopColor={'#DFDFDF'} offset={'100%'} />
                        </linearGradient>
                    </defs>
                    <g transform={'translate(0 -3)'} fill={'url(#illustration-02)'} fillRule={'evenodd'}>
                        <circle cx={'1630'} cy={'128'} r={'128'} />
                        <circle cx={'178'} cy={'481'} r={'40'} />
                    </g>
                </svg>
            </div>
            <div className={'relative max-w-7xl mx-auto px-4 sm:px-6'}>
                {/* <div className={'pt-12 md:pt-20'}> */}
                <div className={'pt-0'}>
                    <div className={'max-w-3xl mx-auto text-center pb-8 md:pb-10'}>
                        <h2 className={'text-4xl md:text-5xl font-black leading-5 tracking-tight mb-6 md:mb-4'}>
                            {translation('aboutUsTitle')}
                        </h2>
                        <p className={'text-xl text-gray-600 text-left text-justify mb-5'} data-aos={'fade-right'}>
                            {translation('aboutUs1stParagraph')}
                        </p>
                        <p className={'text-xl text-gray-600 text-left text-justify mb-5'} data-aos={'fade-left'}>
                            {translation('aboutUs2ndParagraph1stPart')}
                            <br />
                            {translation('aboutUs2ndParagraph2ndPart')}
                        </p>
                        <p className={'text-xl text-gray-600 text-left text-justify mb-5'} data-aos={'fade-left'}>
                            {translation('aboutUs2ndParagraph3rdPart')}
                        </p>
                        <p className={'text-xl text-gray-600 text-left text-justify mb-5'} data-aos={'fade-right'}>
                            {translation('aboutUs3rdParagraph')}
                        </p>
                        <p className={'text-xl text-gray-600 text-left text-justify mb-5'} data-aos={'fade-left'}>
                            {translation('aboutUs4thParagraph1stPart')} <br />{' '}
                            {translation('aboutUs4thParagraph2ndPart')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingAboutUs
