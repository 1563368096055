import { BaseComponent } from 'interfaces/BaseComponent.interfaces'
import HeroImage from 'resources/images/misc/about.png'

const LandingHero = ({ translation }: BaseComponent) => {
    return (
        <section id={'welcome-ethicmind'} className={'relative'}>
            <div
                className={'absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none'}
                aria-hidden={'true'}
            >
                <svg width={'1360'} height={'578'} viewBox={'0 0 1360 578'} xmlns={'http://www.w3.org/2000/svg'}>
                    <defs>
                        <linearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'illustration-01'}>
                            <stop stopColor={'#FFF'} offset={'0%'} />
                            <stop stopColor={'#EAEAEA'} offset={'77.402%'} />
                            <stop stopColor={'#DFDFDF'} offset={'100%'} />
                        </linearGradient>
                    </defs>
                    <g fill={'url(#illustration-01)'} fillRule={'evenodd'}>
                        <circle cx={'1232'} cy={'128'} r={'128'} />
                        <circle cx={'155'} cy={'443'} r={'64'} />
                    </g>
                </svg>
            </div>
            <div className={'max-w-7xl mx-auto px-4 sm:px-6'}>
                <div className={'pt-20 pb-12 md:pt-40 md:pb-20'}>
                    <div className={'text-center pb-12 md:pb-16'}>
                        <p
                            className={'text-sm md:text-lg text-gray-600 mb-4'}
                            data-aos={'zoom-y-out'}
                            data-aos-delay={'150'}
                        >
                            {translation('landingHeroQuestion1stPart')}
                            <br />
                            {translation('landingHeroQuestion2ndPart')}
                        </p>
                        <h1
                            className={'text-6xl md:text-8xl font-black leading-tighter tracking-tighter mb-4'}
                            data-aos={'zoom-y-out'}
                        >
                            {translation('welcomeTo')}{' '}
                            <span className={'bg-clip-text text-transparent text-primary'}>EthicMind</span>
                        </h1>
                        <div className={'max-w-3xl mx-auto'}>
                            <p
                                className={'text-xl md:text-2xl text-gray-800 mb-8'}
                                data-aos={'zoom-y-out'}
                                data-aos-delay={'150'}
                            >
                                {translation('landingHeroSubTitle1stPart')}{' '}
                                <span className={'text-black font-black'}>
                                    {translation('landingHeroSubTitle2ndPart')}
                                </span>{' '}
                                {translation('landingHeroSubTitle3rdPart')}{' '}
                                <span className={'text-black font-black'}>
                                    {translation('landingHeroSubTitle4thPart')}
                                </span>{' '}
                                {translation('landingHeroSubTitle5thPart')}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div
                            className={'relative flex justify-center mb-8'}
                            data-aos={'zoom-y-out'}
                            data-aos-delay={'450'}
                        >
                            <div className={'flex flex-col justify-center'}>
                                <img className={'mx-auto'} src={HeroImage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingHero
