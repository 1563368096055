import { BrandIconsObjectType } from 'types/BrandIconsObjectType'
import { BusinessIconsObjectType } from 'types/BusinessIconsObjectType'
import { CommunicationIconsObjectType } from 'types/CommunicationIconsObjectType'
import { ConstructionIconsObjectType } from 'types/ConstructionIconsObjectType'
import { CurrencyIconsObjectType } from 'types/CurrencyIconsObjectType'
import { DesignIconsObjectType } from 'types/DesignIconsObjectType'
import { DirectionIconsObjectType } from 'types/DirectionIconsObjectType'
import { EducationIconsObjectType } from 'types/EducationIconsObjectType'
import { EmojiIconsObjectType } from 'types/EmojiIconsObjectType'
import { FlagsIconsObjectType } from 'types/FlagsIconsObjectType'
import { FoodAndRestaurantIconsObjectType } from 'types/FoodAndRestaurantIconsObjectType'
import { HealthIconsObjectType } from 'types/HealthIconsObjectType'
import { HeroIconsObjectType } from 'types/HeroIconsObjectType'
import { IconType } from 'types/IconType'
import { InterfaceAndSignIconsObjectType } from 'types/InterfaceAndSignIconsObjectType'
import { MarketingIconsObjectType } from 'types/MarketingIconsObjectType'
import { MediaIconsObjectType } from 'types/MediaIconsObjectType'
import { NatureIconsObjectType } from 'types/NatureIconsObjectType'
import { PartyIconsObjectType } from 'types/PartyIconsObjectType'
import { PersonalIconsObjectType } from 'types/PersonalIconsObjectType'
import { SpinnerIconsObjectType } from 'types/SpinnerIconsObjectType'
import { TextEditorIconsObjectType } from 'types/TextEditorIconsObjectType'
import { TravelIconsObjectType } from 'types/TravelIconsObjectType'
import { WeatherIconsObjectType } from 'types/WeatherIconsObjectType'
import { WebAndTechnologyObjectType } from 'types/WebAndTechnologyObjectType'
import { SvgIconProps } from 'interfaces/SvgIconProps.interfaces'
import BrandIcons from 'resources/icons/brand'
import BusinessIcons from 'resources/icons/business'
import CommunicationIcons from 'resources/icons/communication'
import ConstructionIcons from 'resources/icons/construction'
import CurrencyIcons from 'resources/icons/currency'
import DesignIcons from 'resources/icons/design'
import DirectionIcons from 'resources/icons/direction'
import EducationIcons from 'resources/icons/education'
import EmojiIcons from 'resources/icons/emoji'
import FlagsIcons from 'resources/icons/flags'
import FoodAndRestaurantIcons from 'resources/icons/foodAndRestaurant'
import HealthIcons from 'resources/icons/health'
import HeroIcons from 'resources/icons/heroicons'
import InterfaceAndSignIcons from 'resources/icons/interfaceAndSign'
import MarketingIcons from 'resources/icons/marketing'
import MediaIcons from 'resources/icons/media'
import NatureIcons from 'resources/icons/nature'
import PartyIcons from 'resources/icons/party'
import PersonalIcons from 'resources/icons/personal'
import SpinnerIcons from 'resources/icons/spinner'
import TextEditorIcons from 'resources/icons/textEditor'
import TravelIcons from 'resources/icons/travel'
import WeatherIcons from 'resources/icons/weather'
import WebAndTechnologyIcons from 'resources/icons/webAndTechnology'

type IconsGroupType =
    | BrandIconsObjectType
    | BusinessIconsObjectType
    | CommunicationIconsObjectType
    | ConstructionIconsObjectType
    | CurrencyIconsObjectType
    | DesignIconsObjectType
    | DirectionIconsObjectType
    | EducationIconsObjectType
    | EmojiIconsObjectType
    | FlagsIconsObjectType
    | FoodAndRestaurantIconsObjectType
    | HealthIconsObjectType
    | HeroIconsObjectType
    | InterfaceAndSignIconsObjectType
    | MarketingIconsObjectType
    | MediaIconsObjectType
    | NatureIconsObjectType
    | PartyIconsObjectType
    | PersonalIconsObjectType
    | SpinnerIconsObjectType
    | TextEditorIconsObjectType
    | TravelIconsObjectType
    | WeatherIconsObjectType
    | WebAndTechnologyObjectType

const SvgIcon = ({ folder, name, className }: SvgIconProps) => {
    const getIconsGroup = (): IconsGroupType => {
        switch (folder) {
            case 'brand':
                return BrandIcons
            case 'business':
                return BusinessIcons
            case 'communication':
                return CommunicationIcons
            case 'construction':
                return ConstructionIcons
            case 'currency':
                return CurrencyIcons
            case 'design':
                return DesignIcons
            case 'direction':
                return DirectionIcons
            case 'education':
                return EducationIcons
            case 'emoji':
                return EmojiIcons
            case 'flags':
                return FlagsIcons
            case 'foodAndRestaurant':
                return FoodAndRestaurantIcons
            case 'health':
                return HealthIcons
            case 'heroicons':
                return HeroIcons
            case 'interfaceAndSign':
                return InterfaceAndSignIcons
            case 'marketing':
                return MarketingIcons
            case 'media':
                return MediaIcons
            case 'nature':
                return NatureIcons
            case 'party':
                return PartyIcons
            case 'personal':
                return PersonalIcons
            case 'spinner':
                return SpinnerIcons
            case 'textEditor':
                return TextEditorIcons
            case 'travel':
                return TravelIcons
            case 'weather':
                return WeatherIcons
            case 'webAndTechnology':
                return WebAndTechnologyIcons
        }
    }

    const OtherIconsGroup: any = getIconsGroup()

    const SvgIconItem: IconType = OtherIconsGroup[name]

    return <SvgIconItem className={`${className ?? ''}`} />
}

export default SvgIcon
