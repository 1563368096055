import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'
import { ApiResponse } from 'interfaces/ApiResponse.interfaces'
import { ResetPasswordRequestInfo } from 'interfaces/ResetPasswordRequestInfo.interfaces'
import ResetPasswordServices from 'services/ResetPasswordServices'
import AuthPage from 'components/AuthPage'
import ConfirmResetPwsForm from 'components/ConfirmResetPwsForm'
import React from 'react'
import LoadingSpinner from 'components/LoadingSpinner'

interface ConfirmResetPswProps extends BaseAppRoute {
    guid: string
}
const ConfirmResetPsw = ({ translation, guid }: ConfirmResetPswProps) => {
    const history = useHistory()
    const [invalid, setInvalid] = useState<boolean | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        ResetPasswordServices.verifyGuid(guid)
            .then((response: AxiosResponse<ResetPasswordRequestInfo>) => {
                const hasBeenSuccessful = response.data !== null ? true : false
                if (hasBeenSuccessful === false) {
                    setInvalid(true)
                }
            })
            .catch((err: AxiosError<ApiResponse>) => {
                const errText = err?.response?.data.message ?? ''
                setInvalid(errText === 'Link not valid' ? true : false)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [guid, history])

    return (
        <AuthPage translation={translation} showLoginBtn={false}>
            <p className={'text-center text-3xl mb-2'}>{translation('titleConfirmResetPsw')}</p>
            {isLoading === true && (
                <span className={'flex items-center justify-center pt-4'}>
                    <LoadingSpinner width={'3rem'} height={'3rem'} />
                </span>
            )}
            {isLoading === false && (
                <ConfirmResetPwsForm
                    guid={guid}
                    invalid={invalid}
                    translation={translation}
                    showOtherBtn={true}
                    labelOtherBtn={translation('backHome')}
                    onClickOtherBtnHandler={() => history.push('/')}
                />
            )}
        </AuthPage>
    )
}

export default ConfirmResetPsw
