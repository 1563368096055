import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'
import LandingPage from 'components/LandingPage'
import LandingHero from 'components/LandingHero'
import LandingIntro from 'components/LandingIntro'
import LandingFeaturesBlocks from 'components/LandingFeaturesBlocks'
import LandingFeatures from 'components/LandingFeatures'
import LandingAboutUs from 'components/LandingAboutUs'
import LandingContactUs from 'components/LandingContactUs'
import LandingEducational from 'components/LandingEducational'
import LandingFaq from 'components/LandingFaq'

const Home = ({ translation, i18n, languages }: BaseAppRoute) => {
    return (
        <LandingPage translation={translation} i18n={i18n} languages={languages}>
            <LandingHero translation={translation} />
            <LandingIntro translation={translation} />
            <LandingFeaturesBlocks translation={translation} />
            <LandingFeatures translation={translation} />
            <LandingAboutUs translation={translation} />
            <LandingEducational translation={translation} />
            <LandingFaq translation={translation} />
            <LandingContactUs translation={translation} />
        </LandingPage>
    )
}

export default Home
