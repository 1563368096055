import { ReactNode } from 'react'

interface SecondaryButtonProps {
    text: string | JSX.Element | React.ReactChild | ReactNode | React.FC
    type?: 'submit' | 'reset' | 'button' | undefined
    disabled?: boolean
    circular?: boolean
    className?: string
    onClickHandler?: () => void
}

const SecondaryButton = ({
    text,
    type = 'button',
    disabled = false,
    circular = false,
    className,
    onClickHandler,
}: SecondaryButtonProps) => {
    const onClickDefaultHandler = () => {
        return
    }

    return (
        <button
            type={type}
            className={`rounded-full cursor-pointer inline-block overflow-hidden ${
                circular === true ? 'px-3 py-3' : 'py-3 px-8'
            } relative text-center border border-primary text-primary bg-white transition-all duration-300 focus:outline-none hover:bg-gray-200 ${
                className ?? 'font-medium text-base'
            } disabled:opacity-45 disabled:cursor-default`}
            disabled={disabled ?? false}
            onClick={onClickHandler ?? onClickDefaultHandler}
        >
            {text}
        </button>
    )
}

export default SecondaryButton
