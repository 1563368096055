import { BrandIconsObjectType } from 'types/BrandIconsObjectType'
import { ReactComponent as FiveHundredpxIcon } from './500px.svg'
import { ReactComponent as AdobeIcon } from './adobe.svg'
import { ReactComponent as AirbnbIcon } from './airbnb.svg'
import { ReactComponent as AmazonOriginalIcon } from './amazon-original.svg'
import { ReactComponent as AmazonPayIcon } from './amazon-pay.svg'
import { ReactComponent as AmazonIcon } from './amazon.svg'
import { ReactComponent as AndroidOriginalIcon } from './android-original.svg'
import { ReactComponent as AndroidIcon } from './android.svg'
import { ReactComponent as AppStoreIcon } from './app-store.svg'
import { ReactComponent as AppleMusicIcon } from './apple-music.svg'
import { ReactComponent as ApplePayIcon } from './apple-pay.svg'
import { ReactComponent as AppleIcon } from './apple.svg'
import { ReactComponent as AtlassianIcon } from './atlassian.svg'
import { ReactComponent as AwsIcon } from './aws.svg'
import { ReactComponent as AzureIcon } from './azure.svg'
import { ReactComponent as BehanceOriginalIcon } from './behance-original.svg'
import { ReactComponent as BehanceIcon } from './behance.svg'
import { ReactComponent as BitbucketIcon } from './bitbucket.svg'
import { ReactComponent as BitcoinIcon } from './bitcoin.svg'
import { ReactComponent as BluetoothOriginalIcon } from './bluetooth-original.svg'
import { ReactComponent as BtcIcon } from './btc.svg'
import { ReactComponent as ChromeIcon } from './chrome.svg'
import { ReactComponent as ChromecastIcon } from './chromecast.svg'
import { ReactComponent as DigitaloceanIcon } from './digitalocean.svg'
import { ReactComponent as DinersClubIcon } from './diners-club.svg'
import { ReactComponent as DiscordIcon } from './discord.svg'
import { ReactComponent as DockerIcon } from './docker.svg'
import { ReactComponent as DropboxOriginalIcon } from './dropbox-original.svg'
import { ReactComponent as DropboxIcon } from './dropbox.svg'
import { ReactComponent as EdgeIcon } from './edge.svg'
import { ReactComponent as FacebookFilledIcon } from './facebook-filled.svg'
import { ReactComponent as FacebookMessengerIcon } from './facebook-messenger.svg'
import { ReactComponent as FacebookOriginalIcon } from './facebook-original.svg'
import { ReactComponent as FacebookOvalIcon } from './facebook-oval.svg'
import { ReactComponent as FacebookIcon } from './facebook.svg'
import { ReactComponent as FirefoxOriginalIcon } from './firefox-original.svg'
import { ReactComponent as FirefoxIcon } from './firefox.svg'
import { ReactComponent as FlickrIcon } from './flickr.svg'
import { ReactComponent as GithubOriginalIcon } from './github-original.svg'
import { ReactComponent as GithubIcon } from './github.svg'
import { ReactComponent as GoodreadsIcon } from './goodreads.svg'
import { ReactComponent as GoogleDriveIcon } from './google-drive.svg'
import { ReactComponent as GooglePayIcon } from './google-pay.svg'
import { ReactComponent as GoogleWalletIcon } from './google-wallet.svg'
import { ReactComponent as GoogleIcon } from './google.svg'
import { ReactComponent as Html5Icon } from './html5.svg'
import { ReactComponent as InstagramFilledIcon } from './instagram-filled.svg'
import { ReactComponent as InstagramOriginalIcon } from './instagram-original.svg'
import { ReactComponent as InstagramIcon } from './instagram.svg'
import { ReactComponent as LinkedinOriginalIcon } from './linkedin-original.svg'
import { ReactComponent as LinkedinIcon } from './linkedin.svg'
import { ReactComponent as MailchimpIcon } from './mailchimp.svg'
import { ReactComponent as MastercardIcon } from './mastercard.svg'
import { ReactComponent as MicrosoftEdgeIcon } from './microsoft-edge.svg'
import { ReactComponent as MicrosoftIcon } from './microsoft.svg'
import { ReactComponent as OperaIcon } from './opera.svg'
import { ReactComponent as PatreonIcon } from './patreon.svg'
import { ReactComponent as PaypalOriginalIcon } from './paypal-original.svg'
import { ReactComponent as PaypalIcon } from './paypal.svg'
import { ReactComponent as PinterestIcon } from './pinterest.svg'
import { ReactComponent as PlayStoreIcon } from './play-store.svg'
import { ReactComponent as PlaystationIcon } from './playstation.svg'
import { ReactComponent as ReactIcon } from './react.svg'
import { ReactComponent as RedditIcon } from './reddit.svg'
import { ReactComponent as SkypeIcon } from './skype.svg'
import { ReactComponent as SlackLineIcon } from './slack-line.svg'
import { ReactComponent as SlackIcon } from './slack.svg'
import { ReactComponent as SlideshareIcon } from './slideshare.svg'
import { ReactComponent as SnapchatIcon } from './snapchat.svg'
import { ReactComponent as SoundcloudOriginalIcon } from './soundcloud-original.svg'
import { ReactComponent as SoundcloudIcon } from './soundcloud.svg'
import { ReactComponent as SpotifyOriginalIcon } from './spotify-original.svg'
import { ReactComponent as SpotifyIcon } from './spotify.svg'
import { ReactComponent as SquarespaceIcon } from './squarespace.svg'
import { ReactComponent as SteamIcon } from './steam.svg'
import { ReactComponent as StripeIcon } from './stripe.svg'
import { ReactComponent as StumbleuponIcon } from './stumbleupon.svg'
import { ReactComponent as SwiftIcon } from './swift.svg'
import { ReactComponent as TelegramOriginalIcon } from './telegram-original.svg'
import { ReactComponent as TelegramIcon } from './telegram.svg'
import { ReactComponent as TiktokIcon } from './tiktok.svg'
import { ReactComponent as TwitchIcon } from './twitch.svg'
import { ReactComponent as TwitterFilledIcon } from './twitter-filled.svg'
import { ReactComponent as TwitterOriginalIcon } from './twitter-original.svg'
import { ReactComponent as TwitterIcon } from './twitter.svg'
import { ReactComponent as UbuntuIcon } from './ubuntu.svg'
import { ReactComponent as VimeoIcon } from './vimeo.svg'
import { ReactComponent as VisaIcon } from './visa.svg'
import { ReactComponent as WhatsappIcon } from './whatsapp.svg'
import { ReactComponent as WindowsIcon } from './windows.svg'
import { ReactComponent as XboxIcon } from './xbox.svg'
import { ReactComponent as YahooIcon } from './yahoo.svg'
import { ReactComponent as YoutubeIcon } from './youtube.svg'

const BrandIcons: BrandIconsObjectType = {
    '500px': FiveHundredpxIcon,
    adobe: AdobeIcon,
    airbnb: AirbnbIcon,
    amazon: AmazonIcon,
    'amazon-original': AmazonOriginalIcon,
    'amazon-pay': AmazonPayIcon,
    android: AndroidIcon,
    'android-original': AndroidOriginalIcon,
    'app-store': AppStoreIcon,
    apple: AppleIcon,
    'apple-music': AppleMusicIcon,
    'apple-pay': ApplePayIcon,
    atlassian: AtlassianIcon,
    aws: AwsIcon,
    azure: AzureIcon,
    behance: BehanceIcon,
    'behance-original': BehanceOriginalIcon,
    bitbucket: BitbucketIcon,
    bitcoin: BitcoinIcon,
    'bluetooth-original': BluetoothOriginalIcon,
    btc: BtcIcon,
    chrome: ChromeIcon,
    chromecast: ChromecastIcon,
    digitalocean: DigitaloceanIcon,
    'diners-club': DinersClubIcon,
    discord: DiscordIcon,
    docker: DockerIcon,
    dropbox: DropboxIcon,
    'dropbox-original': DropboxOriginalIcon,
    edge: EdgeIcon,
    facebook: FacebookIcon,
    'facebook-filled': FacebookFilledIcon,
    'facebook-messenger': FacebookMessengerIcon,
    'facebook-original': FacebookOriginalIcon,
    'facebook-oval': FacebookOvalIcon,
    firefox: FirefoxIcon,
    'firefox-original': FirefoxOriginalIcon,
    flickr: FlickrIcon,
    github: GithubIcon,
    'github-original': GithubOriginalIcon,
    goodreads: GoodreadsIcon,
    google: GoogleIcon,
    'google-drive': GoogleDriveIcon,
    'google-pay': GooglePayIcon,
    'google-wallet': GoogleWalletIcon,
    html5: Html5Icon,
    instagram: InstagramIcon,
    'instagram-filled': InstagramFilledIcon,
    'instagram-original': InstagramOriginalIcon,
    linkedin: LinkedinIcon,
    'linkedin-original': LinkedinOriginalIcon,
    mailchimp: MailchimpIcon,
    mastercard: MastercardIcon,
    microsoft: MicrosoftIcon,
    'microsoft-edge': MicrosoftEdgeIcon,
    opera: OperaIcon,
    patreon: PatreonIcon,
    paypal: PaypalIcon,
    'paypal-original': PaypalOriginalIcon,
    pinterest: PinterestIcon,
    'play-store': PlayStoreIcon,
    playstation: PlaystationIcon,
    react: ReactIcon,
    reddit: RedditIcon,
    skype: SkypeIcon,
    slack: SlackIcon,
    'slack-line': SlackLineIcon,
    slideshare: SlideshareIcon,
    snapchat: SnapchatIcon,
    soundcloud: SoundcloudIcon,
    'soundcloud-original': SoundcloudOriginalIcon,
    spotify: SpotifyIcon,
    'spotify-original': SpotifyOriginalIcon,
    squarespace: SquarespaceIcon,
    steam: SteamIcon,
    stripe: StripeIcon,
    stumbleupon: StumbleuponIcon,
    swift: SwiftIcon,
    telegram: TelegramIcon,
    'telegram-original': TelegramOriginalIcon,
    tiktok: TiktokIcon,
    twitch: TwitchIcon,
    twitter: TwitterIcon,
    'twitter-filled': TwitterFilledIcon,
    'twitter-original': TwitterOriginalIcon,
    ubuntu: UbuntuIcon,
    vimeo: VimeoIcon,
    visa: VisaIcon,
    whatsapp: WhatsappIcon,
    windows: WindowsIcon,
    xbox: XboxIcon,
    yahoo: YahooIcon,
    youtube: YoutubeIcon,
}

export default BrandIcons
