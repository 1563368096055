import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROLE } from 'enum/roles'
import { StateReducerType } from 'types/StateReducerType'
import { UserReducerType } from 'types/UserReducerType'

interface MyRouteProps extends RouteProps {
    component: any
    user: UserReducerType
    rest?: any
}

// The AuthRoute is for non-authenticated user on login. If user if already authenticated then it will redirect user to Index page
const AuthRoute: React.FC<MyRouteProps> = ({ component: Component, user, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                user.authenticated === true && !!user.user ? (
                    <Redirect
                        to={`${
                            user.user.role === ROLE.ADMINISTRATOR && user.user.isAdministrator === true
                                ? '/admin'
                                : '/dashboard'
                        }`}
                    />
                ) : (
                    <Component {...props} />
                )
            }
        />
    )
}

const mapStateToProps = (state: StateReducerType) => ({
    user: state.user,
})

export default connect(mapStateToProps)(AuthRoute)
