import {
    SET_AUTHENTICATED,
    SET_USER,
    SET_TMP_LOGIN_DATA,
    CLEAR_TMP_LOGIN_DATA,
    SET_ERROR,
    LOADING_UI,
    CLEAR_ERRORS,
    SET_UNAUTHENTICATED,
} from '../types'
import AuthServices from 'services/AuthServices'
import { AxiosError } from 'axios'
import { ApiResponse } from 'interfaces/ApiResponse.interfaces'
import { LoginResponse } from 'interfaces/LoginResponse.interfaces'
import { LoginRequestData } from 'types/LoginRequestData'

export const loginUser = (userData: LoginRequestData, auth2FAEnabled: boolean) => (dispatch: any) => {
    dispatch({ type: LOADING_UI })
    if (auth2FAEnabled === true) {
        AuthServices.login2FA(userData.username, userData.password)
            .then((res: any) => {
                if (!!res.data) {
                    dispatch({ type: CLEAR_ERRORS })
                    dispatch({ type: SET_TMP_LOGIN_DATA, payload: userData })
                } else {
                    dispatch({
                        type: SET_ERROR,
                        payload: 'Something went wrong. Authentication failed.',
                    })
                }
            })
            .catch((err: AxiosError<ApiResponse>) => {
                dispatch({
                    type: SET_ERROR,
                    payload:
                        err?.response?.data.message !== 'Bad request'
                            ? err?.response?.data.message
                            : 'Something went wrong. Authentication failed.',
                })
            })
    } else {
        AuthServices.login(userData.username, userData.password)
            .then((res: LoginResponse) => {
                if (!!res.data.user.guid && !!res.data.user.id && !!res.data.token) {
                    dispatch({
                        type: SET_USER,
                        payload: res.data.user,
                    })
                    dispatch({ type: SET_AUTHENTICATED })
                    dispatch({ type: CLEAR_ERRORS })
                    dispatch({ type: CLEAR_TMP_LOGIN_DATA })
                } else {
                    dispatch({
                        type: SET_ERROR,
                        payload: 'Something went wrong. Authentication failed.',
                    })
                }
            })
            .catch((err: AxiosError<ApiResponse>) => {
                dispatch({
                    type: SET_ERROR,
                    payload:
                        err?.response?.data.message !== 'Bad request'
                            ? err?.response?.data.message
                            : 'Something went wrong. Authentication failed.',
                })
            })
    }
}

export const loginOtpVerify = (username: string, password: string, code: string) => (dispatch: any) => {
    dispatch({ type: LOADING_UI })
    AuthServices.login2FAVerify(username, password, code)
        .then((res: LoginResponse) => {
            if (!!res.data.user.guid && !!res.data.user.id && !!res.data.token) {
                dispatch({
                    type: SET_USER,
                    payload: res.data.user,
                })
                dispatch({ type: SET_AUTHENTICATED })
                dispatch({ type: CLEAR_ERRORS })
                dispatch({ type: CLEAR_TMP_LOGIN_DATA })
            } else {
                dispatch({
                    type: SET_ERROR,
                    payload: 'Something went wrong. Authentication failed.',
                })
            }
        })
        .catch((err: AxiosError<ApiResponse>) => {
            dispatch({
                type: SET_ERROR,
                payload:
                    err?.response?.data.message !== 'Bad request'
                        ? err?.response?.data.message
                        : 'Something went wrong. Authentication failed.',
            })
        })
}

export const cancelLoginOtpVerify = () => (dispatch: any) => {
    dispatch({ type: CLEAR_TMP_LOGIN_DATA })
}

export const logoutUser = () => (dispatch: any) => {
    AuthServices.logout()
    dispatch({ type: SET_UNAUTHENTICATED })
}
