import { WeatherIconsObjectType } from 'types/WeatherIconsObjectType'
import { ReactComponent as CloudIcon } from './cloud.svg'
import { ReactComponent as CloudySunIcon } from './cloudy-sun.svg'
import { ReactComponent as DropIcon } from './drop.svg'
import { ReactComponent as NightIcon } from './night.svg'
import { ReactComponent as RainIcon } from './rain.svg'
import { ReactComponent as SunIcon } from './sun.svg'
import { ReactComponent as ThunderAltIcon } from './thunder-alt.svg'
import { ReactComponent as ThunderIcon } from './thunder.svg'

const WeatherIcons: WeatherIconsObjectType = {
    cloud: CloudIcon,
    'cloudy-sun': CloudySunIcon,
    drop: DropIcon,
    night: NightIcon,
    rain: RainIcon,
    sun: SunIcon,
    'thunder-alt': ThunderAltIcon,
    thunder: ThunderIcon,
}

export default WeatherIcons
