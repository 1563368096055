import { BaseComponent } from 'interfaces/BaseComponent.interfaces'

const LandingFeaturesBlocks = ({ translation }: BaseComponent) => {
    return (
        <section id={'target'} className={'relative'}>
            {/* Section background (needs .relative class on parent and next sibling elements) */}
            <div
                className={'absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-white pointer-events-none'}
                aria-hidden={'true'}
            ></div>
            <div
                className={
                    'absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-primary transform translate-y-1/2'
                }
            ></div>
            <div className={'relative max-w-7xl mx-auto px-4 sm:px-6'}>
                <div className={'py-12 md:py-16'}>
                    {/* Section header */}
                    <div className={'max-w-3xl mx-auto text-center pb-12'}>
                        <h2 className={'text-4xl md:text-5xl font-black tracking-tight mb-6 md:mb-4'}>
                            {translation('whoIsEthicMindFor1stPartQuestion')}{' '}
                            <span className={'bg-clip-text text-transparent text-primary'}>
                                {translation('whoIsEthicMindFor2ndPartQuestion')}
                            </span>{' '}
                            {translation('whoIsEthicMindFor3rdPartQuestion')}
                        </h2>
                        <h3 className={'text-2xl md:text-3xl font-extrabold tracking-tight mb-4'}>
                            {translation('whoIsEthicMindForTitle')}
                        </h3>
                        <p className={'text-xl text-gray-600'}>{translation('whoIsEthicMindForParagraph')}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingFeaturesBlocks
