import { QueryClient } from 'react-query'

export const getQueryClient = (): QueryClient => {
    const queryClient = new QueryClient()

    return queryClient
}

export const removeProtocolFromUrl = (url: string): string => {
    return url.replace(/(^\w+:|^)\/\//, '')
}

export const isUrl = (data: string): boolean => {
    let url

    try {
        url = new URL(data)
    } catch (error) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isValidURL = (url: string) => {
    const Regex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    return Regex.test(url)
}
