import { ReactComponent as ToastErrorIcon } from './error.svg'
import { ReactComponent as ToastSuccessIcon } from './success.svg'
import { ReactComponent as ToastInfoIcon } from './info.svg'
import { ReactComponent as ToastWarnIcon } from './warn.svg'
import { SeverityType } from 'types/SeverityType'
import { IconType } from 'types/IconType'

type ToastIconsObjectType = {
    [key in SeverityType]: IconType
}

const ToastIcons: ToastIconsObjectType = {
    error: ToastErrorIcon,
    success: ToastSuccessIcon,
    warn: ToastWarnIcon,
    info: ToastInfoIcon,
}

export default ToastIcons
