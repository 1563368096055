import { MediaIconsObjectType } from 'types/MediaIconsObjectType'
import { ReactComponent as BackwardIcon } from './backward.svg'
import { ReactComponent as ForwardIcon } from './forward.svg'
import { ReactComponent as FrameExpandIcon } from './frame-expand.svg'
import { ReactComponent as FullScreenIcon } from './full-screen.svg'
import { ReactComponent as GalleryIcon } from './gallery.svg'
import { ReactComponent as PauseIcon } from './pause.svg'
import { ReactComponent as PlayIcon } from './play.svg'
import { ReactComponent as ShuffleIcon } from './shuffle.svg'
import { ReactComponent as StopIcon } from './stop.svg'
import { ReactComponent as VideoIcon } from './video.svg'
import { ReactComponent as VolumeHighIcon } from './volume-high.svg'
import { ReactComponent as VolumeLowIcon } from './volume-low.svg'
import { ReactComponent as VolumeMediumIcon } from './volume-medium.svg'
import { ReactComponent as VolumeMuteIcon } from './volume-mute.svg'
import { ReactComponent as VolumeIcon } from './volume.svg'

const MediaIcons: MediaIconsObjectType = {
    backward: BackwardIcon,
    forward: ForwardIcon,
    'frame-expand': FrameExpandIcon,
    'full-screen': FullScreenIcon,
    gallery: GalleryIcon,
    pause: PauseIcon,
    play: PlayIcon,
    shuffle: ShuffleIcon,
    stop: StopIcon,
    video: VideoIcon,
    'volume-high': VolumeHighIcon,
    'volume-low': VolumeLowIcon,
    'volume-medium': VolumeMediumIcon,
    'volume-mute': VolumeMuteIcon,
    volume: VolumeIcon,
}

export default MediaIcons
