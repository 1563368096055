import React from 'react'
import styled from 'styled-components'

const Spinner = styled.div.attrs((props: { width: string; height: string; borderWidth: string }) => ({
    width: props.width,
    height: props.height,
    borderWidth: props.borderWidth,
}))`
    width: ${(props) => `${props.width}`};
    height: ${(props) => `${props.height}`};
    border-width: ${(props) => `${props.borderWidth}`};
    border-top-color: transparent;
`

interface LoadingSpinnerProps {
    width?: string
    height?: string
    borderWidth?: string
    style?: React.CSSProperties
}

const LoadingSpinner = ({ width = '4rem', height = '4rem', borderWidth = '4px', style = {} }: LoadingSpinnerProps) => {
    return (
        <Spinner
            width={width}
            height={height}
            borderWidth={borderWidth}
            className={'border-primary border-solid rounded-full animate-spin'}
            style={style}
        ></Spinner>
    )
}

export default LoadingSpinner
