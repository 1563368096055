import { useEffect, useState } from 'react'

const TABLET_BREAKPOINT = 640
const LAPTOP_BREAKPOINT = 1024
const DESKTOP_BREAKPOINT = 1280
const LARGE_DESKTOP_BREAKPOINT = 1536
const EXTRA_LARGE_DESKTOP_BREAKPOINT = 9999

const useDeviceDetect = (deviceBreakpoint: number): boolean => {
    const [width, setWidth] = useState(window.innerWidth)
    const handleWindowResize = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    return width < deviceBreakpoint
}

export const useIsMobile = (): boolean => {
    return useDeviceDetect(TABLET_BREAKPOINT)
}

export const useIsTablet = (): boolean => {
    return useDeviceDetect(LAPTOP_BREAKPOINT)
}

export const useIsLaptop = (): boolean => {
    return useDeviceDetect(DESKTOP_BREAKPOINT)
}

export const useIsDesktop = (): boolean => {
    return useDeviceDetect(LARGE_DESKTOP_BREAKPOINT)
}

export const useIsLargeDesktop = (): boolean => {
    return useDeviceDetect(EXTRA_LARGE_DESKTOP_BREAKPOINT)
}
