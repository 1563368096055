import React, { ReactNode } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { BaseComponent } from 'interfaces/BaseComponent.interfaces'
import LoginFormContainer from 'styles/LoginFormContainer'
import LogoImage from 'resources/images/logo/logo.png'
import BackgroundLogo from 'resources/images/background/login.jpg'
import Card from 'components/Card'

interface AuthPageProps extends BaseComponent {
    children: JSX.Element | React.ReactChild | ReactNode | React.FC
    className?: string
    showRegisterBtn?: boolean
    showLoginBtn?: boolean
    maxWidth?: string
    backgroundImage?: string
    positionBackgroundImage?:
        | 'leftTop'
        | 'middleLeftTop'
        | 'centerTop'
        | 'middleRightTop'
        | 'rightTop'
        | 'leftBottom'
        | 'middleLeftBottom'
        | 'centerBottom'
        | 'middleRightBottom'
        | 'rightBottom'
}

const AuthPage = ({
    children,
    className,
    translation,
    showRegisterBtn = false,
    showLoginBtn = false,
    maxWidth = '32rem',
    backgroundImage = BackgroundLogo,
    positionBackgroundImage = 'leftBottom',
}: AuthPageProps) => {
    const history = useHistory()
    return (
        <React.Fragment>
            <div
                className={`hidden xl:block h-screen w-screen bg-cover bg-${positionBackgroundImage} bg-no-repeat ${
                    className ?? ''
                }`}
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <div className={'flex flex-col items-center flex-1 h-full justify-center px-4 sm:px-0'}>
                    <Card
                        className={'flex flex-col w-full sm:w-3/4 sm:w-lg justify-center items-center p-6 mx-0! my-2!'}
                    >
                        <div className={'flex justify-start items-center h-16 md:h-20 mt-0 mb-4'}>
                            <Link
                                to={'/'}
                                className={'flex items-center border-b-0 tracking-wide transition duration-300'}
                            >
                                <img src={LogoImage} className={'w-auto h-14 sm:h-16 mr-4'} alt={'EthicMind'} />
                                <span className={'text-3xl text-primary font-black'}>EthicMind</span>
                            </Link>
                        </div>
                        <LoginFormContainer className={'w-full px-4 sm:px-10'} maxWidth={maxWidth}>
                            {children}
                            {showRegisterBtn === true && (
                                <div className={'text-center mt-4'}>
                                    <p>
                                        {translation('dontHaveAccount')}
                                        <button
                                            className={'underline font-semibold ml-1'}
                                            onClick={() => history.push('/sign-up')}
                                        >
                                            {translation('registerHere')}
                                        </button>
                                    </p>
                                </div>
                            )}
                            {showLoginBtn === true && (
                                <div className={'text-center mt-4'}>
                                    <p>
                                        {translation('alreadyHaveAccount')}
                                        <button
                                            className={'underline font-semibold ml-1'}
                                            onClick={() => history.push('/sign-in')}
                                        >
                                            {translation('signInHere')}
                                        </button>
                                    </p>
                                </div>
                            )}
                        </LoginFormContainer>
                    </Card>
                </div>
            </div>
            <div className={`flex xl:hidden w-full flex-wrap ${className ?? ''}`}>
                <div className={'w-full lg:w-1/2 flex flex-col min-h-screen justify-center items-center'}>
                    <div className={'flex justify-start items-center h-16 md:h-20 mt-0 mb-4 sm:my-6'}>
                        <Link to={'/'} className={'flex items-center border-b-0 tracking-wide transition duration-300'}>
                            <img src={LogoImage} className={'w-auto h-14 sm:h-16 mr-4'} alt={'EthicMind'} />
                            <span className={'text-3xl text-primary font-black'}>EthicMind</span>
                        </Link>
                    </div>
                    <LoginFormContainer className={'w-full px-4 sm:px-10'} maxWidth={maxWidth}>
                        {children}
                        {showRegisterBtn === true && (
                            <div className={'text-center mt-8'}>
                                <p>
                                    {translation('dontHaveAccount')}
                                    <button
                                        className={'underline font-semibold ml-1'}
                                        onClick={() => history.push('/sign-up')}
                                    >
                                        {translation('registerHere')}
                                    </button>
                                </p>
                            </div>
                        )}
                        {showLoginBtn === true && (
                            <div className={'text-center mt-4 sm:mt-8'}>
                                <p>
                                    {translation('alreadyHaveAccount')}
                                    <button
                                        className={'underline font-semibold ml-1'}
                                        onClick={() => history.push('/sign-in')}
                                    >
                                        {translation('signInHere')}
                                    </button>
                                </p>
                            </div>
                        )}
                    </LoginFormContainer>
                </div>
                {/* Image Section */}
                <div className={'w-1/2'}>
                    <div
                        className={`w-full h-full min-h-screen hidden lg:block bg-cover bg-${positionBackgroundImage} bg-no-repeat`}
                        style={{ backgroundImage: `url(${backgroundImage})` }}
                    ></div>
                </div>
            </div>
        </React.Fragment>
        // <div className={`w-full flex flex-wrap ${className ?? ''}`}>
        //     <div className={'w-full lg:w-1/2 flex flex-col min-h-screen justify-center items-center'}>
        //         <div className={'flex justify-start items-center h-16 md:h-20 mt-0 mb-4 sm:my-6'}>
        //             <Link to={'/'} className={'flex items-center border-b-0 tracking-wide transition duration-300'}>
        //                 <img src={LogoImage} className={'w-auto h-14 sm:h-16 mr-4'} alt={'EthicMind'} />
        //                 <span className={'text-3xl text-primary font-black'}>EthicMind</span>
        //             </Link>
        //         </div>
        //         <LoginFormContainer className={'w-full px-4 sm:px-10'} maxWidth={maxWidth}>
        //             {children}
        //             {showRegisterBtn === true && (
        //                 <div className={'text-center mt-8'}>
        //                     <p>
        //                         {`Don't have an account?`}
        //                         <button
        //                             className={'underline font-semibold ml-1'}
        //                             onClick={() => history.push('/sign-up')}
        //                         >
        //                             Register here.
        //                         </button>
        //                     </p>
        //                 </div>
        //             )}
        //             {showLoginBtn === true && (
        //                 <div className={'text-center mt-4 sm:mt-8'}>
        //                     <p>
        //                         {`Already have an account?`}
        //                         <button
        //                             className={'underline font-semibold ml-1'}
        //                             onClick={() => history.push('/sign-in')}
        //                         >
        //                             Sign in.
        //                         </button>
        //                     </p>
        //                 </div>
        //             )}
        //         </LoginFormContainer>
        //     </div>
        //     {/* Image Section */}
        //     <div className={'w-1/2'}>
        //         <div
        //             className={`w-full h-full min-h-screen hidden lg:block bg-cover bg-${positionBackgroundImage} bg-no-repeat`}
        //             style={{ backgroundImage: `url(${backgroundImage})` }}
        //         ></div>
        //     </div>
        // </div>
    )
}

export default AuthPage
