import { PartyIconsObjectType } from 'types/PartyIconsObjectType'
import { ReactComponent as BaloonIcon } from './baloon.svg'
import { ReactComponent as CakeIcon } from './cake.svg'
import { ReactComponent as CandyCaneIcon } from './candy-cane.svg'
import { ReactComponent as CandyIcon } from './candy.svg'
import { ReactComponent as FireworksIcon } from './fireworks.svg'
import { ReactComponent as FlagsIcon } from './flags.svg'
import { ReactComponent as SprayIcon } from './spray.svg'

const PartyIcons: PartyIconsObjectType = {
    baloon: BaloonIcon,
    cake: CakeIcon,
    'candy-cane': CandyCaneIcon,
    candy: CandyIcon,
    fireworks: FireworksIcon,
    flags: FlagsIcon,
    spray: SprayIcon,
}

export default PartyIcons
