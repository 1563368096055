import { WebAndTechnologyObjectType } from 'types/WebAndTechnologyObjectType'
import { ReactComponent as AddFilesIcon } from './add-files.svg'
import { ReactComponent as AlarmClockIcon } from './alarm-clock.svg'
import { ReactComponent as AlarmIcon } from './alarm.svg'
import { ReactComponent as ArchiveIcon } from './archive.svg'
import { ReactComponent as BanIcon } from './ban.svg'
import { ReactComponent as BluetoothIcon } from './bluetooth.svg'
import { ReactComponent as BookmarkAltIcon } from './bookmark-alt.svg'
import { ReactComponent as BookmarkIcon } from './bookmark.svg'
import { ReactComponent as BulbIcon } from './bulb.svg'
import { ReactComponent as CalendarIcon } from './calendar.svg'
import { ReactComponent as CameraIcon } from './camera.svg'
import { ReactComponent as CloudCheckIcon } from './cloud-check.svg'
import { ReactComponent as CloudDownloadIcon } from './cloud-download.svg'
import { ReactComponent as CloudNetworkIcon } from './cloud-network.svg'
import { ReactComponent as CloudSyncIcon } from './cloud-sync.svg'
import { ReactComponent as CloudUploadIcon } from './cloud-upload.svg'
import { ReactComponent as CodeAltIcon } from './code-alt.svg'
import { ReactComponent as CodeIcon } from './code.svg'
import { ReactComponent as CogIcon } from './cog.svg'
import { ReactComponent as CogsIcon } from './cogs.svg'
import { ReactComponent as ControlPanelIcon } from './control-panel.svg'
import { ReactComponent as CutIcon } from './cut.svg'
import { ReactComponent as DashboardIcon } from './dashboard.svg'
import { ReactComponent as DatabaseIcon } from './database.svg'
import { ReactComponent as DisplayAltIcon } from './display-alt.svg'
import { ReactComponent as DisplayIcon } from './display.svg'
import { ReactComponent as DomainIcon } from './domain.svg'
import { ReactComponent as DownloadIcon } from './download.svg'
import { ReactComponent as EmptyFileIcon } from './empty-file.svg'
import { ReactComponent as FilesIcon } from './files.svg'
import { ReactComponent as FolderIcon } from './folder.svg'
import { ReactComponent as GameIcon } from './game.svg'
import { ReactComponent as HarddriveIcon } from './harddrive.svg'
import { ReactComponent as HeadphoneAltIcon } from './headphone-alt.svg'
import { ReactComponent as HeadphoneIcon } from './headphone.svg'
import { ReactComponent as ImageIcon } from './image.svg'
import { ReactComponent as KeyboardIcon } from './keyboard.svg'
import { ReactComponent as LaptopPhoneIcon } from './laptop-phone.svg'
import { ReactComponent as LaptopIcon } from './laptop.svg'
import { ReactComponent as LinkIcon } from './link.svg'
import { ReactComponent as MagnetIcon } from './magnet.svg'
import { ReactComponent as MagnifierIcon } from './magnifier.svg'
import { ReactComponent as MapMarkerIcon } from './map-marker.svg'
import { ReactComponent as MapIcon } from './map.svg'
import { ReactComponent as MenuIcon } from './menu.svg'
import { ReactComponent as MicIcon } from './mic.svg'
import { ReactComponent as MicrophoneIcon } from './microphone.svg'
import { ReactComponent as MobileIcon } from './mobile.svg'
import { ReactComponent as MouseIcon } from './mouse.svg'
import { ReactComponent as PackageIcon } from './package.svg'
import { ReactComponent as PlugIcon } from './plug.svg'
import { ReactComponent as PrinterIcon } from './printer.svg'
import { ReactComponent as ReloadIcon } from './reload.svg'
import { ReactComponent as RemoveFileIcon } from './remove-file.svg'
import { ReactComponent as RocketIcon } from './rocket.svg'
import { ReactComponent as RssFeedIcon } from './rss-feed.svg'
import { ReactComponent as SaveIcon } from './save.svg'
import { ReactComponent as ScrolldownIcon } from './scroll-down.svg'
import { ReactComponent as SearchAltIcon } from './search-alt.svg'
import { ReactComponent as SearchIcon } from './search.svg'
import { ReactComponent as ShareAlt1Icon } from './share-alt-1.svg'
import { ReactComponent as ShareAltIcon } from './share-alt.svg'
import { ReactComponent as ShareIcon } from './share.svg'
import { ReactComponent as ShoppingBasketIcon } from './shopping-basket.svg'
import { ReactComponent as ShortcodeIcon } from './shortcode.svg'
import { ReactComponent as SignalIcon } from './signal.svg'
import { ReactComponent as StarEmptyIcon } from './star-empty.svg'
import { ReactComponent as StarFilledIcon } from './star-filled.svg'
import { ReactComponent as StarHalfIcon } from './star-half.svg'
import { ReactComponent as StarIcon } from './star.svg'
import { ReactComponent as TabIcon } from './tab.svg'
import { ReactComponent as TimerIcon } from './timer.svg'
import { ReactComponent as TrashCanIcon } from './trash-can.svg'
import { ReactComponent as UnlinkIcon } from './unlink.svg'
import { ReactComponent as UploadIcon } from './upload.svg'
import { ReactComponent as UserIcon } from './user.svg'
import { ReactComponent as WebsiteAltIcon } from './website-alt.svg'
import { ReactComponent as WebsiteIcon } from './website.svg'
import { ReactComponent as ZipIcon } from './zip.svg'
import { ReactComponent as ZoomInIcon } from './zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from './zoom-out.svg'

const WebAndTechnology: WebAndTechnologyObjectType = {
    'add-files': AddFilesIcon,
    'alarm-clock': AlarmClockIcon,
    alarm: AlarmIcon,
    archive: ArchiveIcon,
    ban: BanIcon,
    bluetooth: BluetoothIcon,
    'bookmark-alt': BookmarkAltIcon,
    bookmark: BookmarkIcon,
    bulb: BulbIcon,
    calendar: CalendarIcon,
    camera: CameraIcon,
    'cloud-check': CloudCheckIcon,
    'cloud-download': CloudDownloadIcon,
    'cloud-network': CloudNetworkIcon,
    'cloud-sync': CloudSyncIcon,
    'cloud-upload': CloudUploadIcon,
    'code-alt': CodeAltIcon,
    code: CodeIcon,
    cog: CogIcon,
    cogs: CogsIcon,
    'control-panel': ControlPanelIcon,
    cut: CutIcon,
    dashboard: DashboardIcon,
    database: DatabaseIcon,
    'display-alt': DisplayAltIcon,
    display: DisplayIcon,
    domain: DomainIcon,
    download: DownloadIcon,
    'empty-file': EmptyFileIcon,
    files: FilesIcon,
    folder: FolderIcon,
    game: GameIcon,
    harddrive: HarddriveIcon,
    'headphone-alt': HeadphoneAltIcon,
    headphone: HeadphoneIcon,
    image: ImageIcon,
    keyboard: KeyboardIcon,
    'laptop-phone': LaptopPhoneIcon,
    laptop: LaptopIcon,
    link: LinkIcon,
    magnet: MagnetIcon,
    magnifier: MagnifierIcon,
    'map-marker': MapMarkerIcon,
    map: MapIcon,
    menu: MenuIcon,
    mic: MicIcon,
    microphone: MicrophoneIcon,
    mobile: MobileIcon,
    mouse: MouseIcon,
    package: PackageIcon,
    plug: PlugIcon,
    printer: PrinterIcon,
    reload: ReloadIcon,
    'remove-file': RemoveFileIcon,
    rocket: RocketIcon,
    'rss-feed': RssFeedIcon,
    save: SaveIcon,
    'scroll-down': ScrolldownIcon,
    'search-alt': SearchAltIcon,
    search: SearchIcon,
    'share-alt-1': ShareAlt1Icon,
    'share-alt': ShareAltIcon,
    share: ShareIcon,
    'shopping-basket': ShoppingBasketIcon,
    shortcode: ShortcodeIcon,
    signal: SignalIcon,
    'star-empty': StarEmptyIcon,
    'star-filled': StarFilledIcon,
    'star-half': StarHalfIcon,
    star: StarIcon,
    tab: TabIcon,
    timer: TimerIcon,
    'trash-can': TrashCanIcon,
    unlink: UnlinkIcon,
    upload: UploadIcon,
    user: UserIcon,
    'website-alt': WebsiteAltIcon,
    website: WebsiteIcon,
    zip: ZipIcon,
    'zoom-in': ZoomInIcon,
    'zoom-out': ZoomOutIcon,
}

export default WebAndTechnology
