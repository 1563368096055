import { HeroIconsObjectType } from 'types/HeroIconsObjectType'
import { ReactComponent as AcademicCapIcon } from './academic-cap.svg'
import { ReactComponent as AdjustmentsIcon } from './adjustments.svg'
import { ReactComponent as AnnotationIcon } from './annotation.svg'
import { ReactComponent as ArchiveIcon } from './archive.svg'
import { ReactComponent as ArrowCircleDownIcon } from './arrow-circle-down.svg'
import { ReactComponent as ArrowCircleLeftIcon } from './arrow-circle-left.svg'
import { ReactComponent as ArrowCircleRightIcon } from './arrow-circle-right.svg'
import { ReactComponent as ArrowCircleUpIcon } from './arrow-circle-up.svg'
import { ReactComponent as ArrowDownIcon } from './arrow-down.svg'
import { ReactComponent as ArrowLeftIcon } from './arrow-left.svg'
import { ReactComponent as ArrowNarrowDownIcon } from './arrow-narrow-down.svg'
import { ReactComponent as ArrowNarrowLeftIcon } from './arrow-narrow-left.svg'
import { ReactComponent as ArrowNarrowRightIcon } from './arrow-narrow-right.svg'
import { ReactComponent as ArrowNarrowUpIcon } from './arrow-narrow-up.svg'
import { ReactComponent as ArrowRightIcon } from './arrow-right.svg'
import { ReactComponent as ArrowSmDownIcon } from './arrow-sm-down.svg'
import { ReactComponent as ArrowSmLeftIcon } from './arrow-sm-left.svg'
import { ReactComponent as ArrowSmRightIcon } from './arrow-sm-right.svg'
import { ReactComponent as ArrowSmUpIcon } from './arrow-sm-up.svg'
import { ReactComponent as ArrowUpIcon } from './arrow-up.svg'
import { ReactComponent as ArrowsExpandIcon } from './arrows-expand.svg'
import { ReactComponent as AtSymbolIcon } from './at-symbol.svg'
import { ReactComponent as BackspaceIcon } from './backspace.svg'
import { ReactComponent as BadgeCheckIcon } from './badge-check.svg'
import { ReactComponent as BanIcon } from './ban.svg'
import { ReactComponent as BeakerIcon } from './beaker.svg'
import { ReactComponent as BellIcon } from './bell.svg'
import { ReactComponent as BookOpenIcon } from './book-open.svg'
import { ReactComponent as BookmarkAltIcon } from './bookmark-alt.svg'
import { ReactComponent as BookmarkIcon } from './bookmark.svg'
import { ReactComponent as BriefcaseIcon } from './briefcase.svg'
import { ReactComponent as CakeIcon } from './cake.svg'
import { ReactComponent as CalculatorIcon } from './calculator.svg'
import { ReactComponent as CalendarIcon } from './calendar.svg'
import { ReactComponent as CameraIcon } from './camera.svg'
import { ReactComponent as CashIcon } from './cash.svg'
import { ReactComponent as ChartBarIcon } from './chart-bar.svg'
import { ReactComponent as ChartPieIcon } from './chart-pie.svg'
import { ReactComponent as ChartSquareBarIcon } from './chart-square-bar.svg'
import { ReactComponent as ChatAlt2Icon } from './chat-alt-2.svg'
import { ReactComponent as ChatAltIcon } from './chat-alt.svg'
import { ReactComponent as ChatIcon } from './chat.svg'
import { ReactComponent as CheckCircleIcon } from './check-circle.svg'
import { ReactComponent as CheckIcon } from './check.svg'
import { ReactComponent as ChevronDoubleDownIcon } from './chevron-double-down.svg'
import { ReactComponent as ChevronDoubleLeftIcon } from './chevron-double-left.svg'
import { ReactComponent as ChevronDoubleRightIcon } from './chevron-double-right.svg'
import { ReactComponent as ChevronDoubleUpIcon } from './chevron-double-up.svg'
import { ReactComponent as ChevronDownIcon } from './chevron-down.svg'
import { ReactComponent as ChevronLeftIcon } from './chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from './chevron-right.svg'
import { ReactComponent as ChevronUpIcon } from './chevron-up.svg'
import { ReactComponent as ChipIcon } from './chip.svg'
import { ReactComponent as ClipboardCheckIcon } from './clipboard-check.svg'
import { ReactComponent as ClipboardCopyIcon } from './clipboard-copy.svg'
import { ReactComponent as ClipboardListIcon } from './clipboard-list.svg'
import { ReactComponent as ClipboardIcon } from './clipboard.svg'
import { ReactComponent as ClockIcon } from './clock.svg'
import { ReactComponent as CloudDownloadIcon } from './cloud-download.svg'
import { ReactComponent as CloudUploadIcon } from './cloud-upload.svg'
import { ReactComponent as CloudIcon } from './cloud.svg'
import { ReactComponent as CodeIcon } from './code.svg'
import { ReactComponent as CogIcon } from './cog.svg'
import { ReactComponent as CollectionIcon } from './collection.svg'
import { ReactComponent as ColorSwatchIcon } from './color-swatch.svg'
import { ReactComponent as CreditCardIcon } from './credit-card.svg'
import { ReactComponent as CubeTransparentIcon } from './cube-transparent.svg'
import { ReactComponent as CubeIcon } from './cube.svg'
import { ReactComponent as CurrencyBangladeshiIcon } from './currency-bangladeshi.svg'
import { ReactComponent as CurrencyDollarIcon } from './currency-dollar.svg'
import { ReactComponent as CurrencyEuroIcon } from './currency-euro.svg'
import { ReactComponent as CurrencyPoundIcon } from './currency-pound.svg'
import { ReactComponent as CurrencyYenIcon } from './currency-yen.svg'
import { ReactComponent as CursorClickIcon } from './cursor-click.svg'
import { ReactComponent as DatabaseIcon } from './database.svg'
import { ReactComponent as DesktopComputerIcon } from './desktop-computer.svg'
import { ReactComponent as DeviceMobileIcon } from './device-mobile.svg'
import { ReactComponent as DeviceTabletIcon } from './device-tablet.svg'
import { ReactComponent as DocumentAddIcon } from './document-add.svg'
import { ReactComponent as DocumentDownloadIcon } from './document-download.svg'
import { ReactComponent as DocumentDuplicateIcon } from './document-duplicate.svg'
import { ReactComponent as DocumentRemoveIcon } from './document-remove.svg'
import { ReactComponent as DocumentReportIcon } from './document-report.svg'
import { ReactComponent as DocumentSearchIcon } from './document-search.svg'
import { ReactComponent as DocumentTextIcon } from './document-text.svg'
import { ReactComponent as DocumentIcon } from './document.svg'
import { ReactComponent as DotsCircleHorizontalIcon } from './dots-circle-horizontal.svg'
import { ReactComponent as DotsHorizontalIcon } from './dots-horizontal.svg'
import { ReactComponent as DotsVerticalIcon } from './dots-vertical.svg'
import { ReactComponent as DownloadIcon } from './download.svg'
import { ReactComponent as DuplicateIcon } from './duplicate.svg'
import { ReactComponent as EmojiHappyIcon } from './emoji-happy.svg'
import { ReactComponent as EmojiSadIcon } from './emoji-sad.svg'
import { ReactComponent as ExclamationCircleIcon } from './exclamation-circle.svg'
import { ReactComponent as ExclamationIcon } from './exclamation.svg'
import { ReactComponent as ExternalLinkIcon } from './external-link.svg'
import { ReactComponent as EyeOffIcon } from './eye-off.svg'
import { ReactComponent as EyeIcon } from './eye.svg'
import { ReactComponent as FastForwardIcon } from './fast-forward.svg'
import { ReactComponent as FilmIcon } from './film.svg'
import { ReactComponent as FilterIcon } from './filter.svg'
import { ReactComponent as FingerPrintIcon } from './finger-print.svg'
import { ReactComponent as FireIcon } from './fire.svg'
import { ReactComponent as FlagIcon } from './flag.svg'
import { ReactComponent as FolderAddIcon } from './folder-add.svg'
import { ReactComponent as FolderDownloadIcon } from './folder-download.svg'
import { ReactComponent as FolderOpenIcon } from './folder-open.svg'
import { ReactComponent as FolderRemoveIcon } from './folder-remove.svg'
import { ReactComponent as FolderIcon } from './folder.svg'
import { ReactComponent as GiftIcon } from './gift.svg'
import { ReactComponent as GlobeAltIcon } from './globe-alt.svg'
import { ReactComponent as GlobeIcon } from './globe.svg'
import { ReactComponent as HandIcon } from './hand.svg'
import { ReactComponent as HashtagIcon } from './hashtag.svg'
import { ReactComponent as HeartIcon } from './heart.svg'
import { ReactComponent as HomeIcon } from './home.svg'
import { ReactComponent as IdentificationIcon } from './identification.svg'
import { ReactComponent as InboxInIcon } from './inbox-in.svg'
import { ReactComponent as InboxIcon } from './inbox.svg'
import { ReactComponent as InformationCircleIcon } from './information-circle.svg'
import { ReactComponent as KeyIcon } from './key.svg'
import { ReactComponent as LibraryIcon } from './library.svg'
import { ReactComponent as LightBulbIcon } from './light-bulb.svg'
import { ReactComponent as LightningBoltIcon } from './lightning-bolt.svg'
import { ReactComponent as LinkIcon } from './link.svg'
import { ReactComponent as LocationMarkerIcon } from './location-marker.svg'
import { ReactComponent as LockClosedIcon } from './lock-closed.svg'
import { ReactComponent as LockOpenIcon } from './lock-open.svg'
import { ReactComponent as LoginIcon } from './login.svg'
import { ReactComponent as LogoutIcon } from './logout.svg'
import { ReactComponent as MailOpenIcon } from './mail-open.svg'
import { ReactComponent as MailIcon } from './mail.svg'
import { ReactComponent as MapIcon } from './map.svg'
import { ReactComponent as MenuAlt1Icon } from './menu-alt-1.svg'
import { ReactComponent as MenuAlt2Icon } from './menu-alt-2.svg'
import { ReactComponent as MenuAlt3Icon } from './menu-alt-3.svg'
import { ReactComponent as MenuAlt4Icon } from './menu-alt-4.svg'
import { ReactComponent as MenuIcon } from './menu.svg'
import { ReactComponent as MicrophoneIcon } from './microphone.svg'
import { ReactComponent as MinusCircleIcon } from './minus-circle.svg'
import { ReactComponent as MinusSmIcon } from './minus-sm.svg'
import { ReactComponent as MinusIcon } from './minus.svg'
import { ReactComponent as MoonIcon } from './moon.svg'
import { ReactComponent as MusicNoteIcon } from './music-note.svg'
import { ReactComponent as NewspaperIcon } from './newspaper.svg'
import { ReactComponent as OfficeBuildingIcon } from './office-building.svg'
import { ReactComponent as PaperAirplaneIcon } from './paper-airplane.svg'
import { ReactComponent as PaperClipIcon } from './paper-clip.svg'
import { ReactComponent as PauseIcon } from './pause.svg'
import { ReactComponent as PencilAltIcon } from './pencil-alt.svg'
import { ReactComponent as PencilIcon } from './pencil.svg'
import { ReactComponent as PhoneIncomingIcon } from './phone-incoming.svg'
import { ReactComponent as PhoneMissedCallIcon } from './phone-missed-call.svg'
import { ReactComponent as PhoneOutgoingIcon } from './phone-outgoing.svg'
import { ReactComponent as PhoneIcon } from './phone.svg'
import { ReactComponent as PhotographIcon } from './photograph.svg'
import { ReactComponent as PlayIcon } from './play.svg'
import { ReactComponent as PlusCircleIcon } from './plus-circle.svg'
import { ReactComponent as PlusSmIcon } from './plus-sm.svg'
import { ReactComponent as PlusIcon } from './plus.svg'
import { ReactComponent as PresentationChartBarIcon } from './presentation-chart-bar.svg'
import { ReactComponent as PresentationChartLineIcon } from './presentation-chart-line.svg'
import { ReactComponent as PrinterIcon } from './printer.svg'
import { ReactComponent as PuzzleIcon } from './puzzle.svg'
import { ReactComponent as QrcodeIcon } from './qrcode.svg'
import { ReactComponent as QuestionMarkCircleIcon } from './question-mark-circle.svg'
import { ReactComponent as ReceiptRefundIcon } from './receipt-refund.svg'
import { ReactComponent as ReceiptTaxIcon } from './receipt-tax.svg'
import { ReactComponent as RefreshIcon } from './refresh.svg'
import { ReactComponent as ReplyIcon } from './reply.svg'
import { ReactComponent as RewindIcon } from './rewind.svg'
import { ReactComponent as RssIcon } from './rss.svg'
import { ReactComponent as SaveAsIcon } from './save-as.svg'
import { ReactComponent as SaveIcon } from './save.svg'
import { ReactComponent as ScaleIcon } from './scale.svg'
import { ReactComponent as ScissorsIcon } from './scissors.svg'
import { ReactComponent as SearchCircleIcon } from './search-circle.svg'
import { ReactComponent as SearchIcon } from './search.svg'
import { ReactComponent as SelectorIcon } from './selector.svg'
import { ReactComponent as ServerIcon } from './server.svg'
import { ReactComponent as ShareIcon } from './share.svg'
import { ReactComponent as ShieldCheckIcon } from './shield-check.svg'
import { ReactComponent as ShieldExclamationIcon } from './shield-exclamation.svg'
import { ReactComponent as ShoppingBagIcon } from './shopping-bag.svg'
import { ReactComponent as ShoppingCartIcon } from './shopping-cart.svg'
import { ReactComponent as SortAscendingIcon } from './sort-ascending.svg'
import { ReactComponent as SortDescendingIcon } from './sort-descending.svg'
import { ReactComponent as SparklesIcon } from './sparkles.svg'
import { ReactComponent as SpeakerphoneIcon } from './speakerphone.svg'
import { ReactComponent as StarIcon } from './star.svg'
import { ReactComponent as StatusOfflineIcon } from './status-offline.svg'
import { ReactComponent as StatusOnlineIcon } from './status-online.svg'
import { ReactComponent as StopIcon } from './stop.svg'
import { ReactComponent as SunIcon } from './sun.svg'
import { ReactComponent as SupportIcon } from './support.svg'
import { ReactComponent as SwitchHorizontalIcon } from './switch-horizontal.svg'
import { ReactComponent as SwitchVerticalIcon } from './switch-vertical.svg'
import { ReactComponent as TableIcon } from './table.svg'
import { ReactComponent as TagIcon } from './tag.svg'
import { ReactComponent as TemplateIcon } from './template.svg'
import { ReactComponent as TerminalIcon } from './terminal.svg'
import { ReactComponent as ThumbDownIcon } from './thumb-down.svg'
import { ReactComponent as ThumbUpIcon } from './thumb-up.svg'
import { ReactComponent as TicketIcon } from './ticket.svg'
import { ReactComponent as TranslateIcon } from './translate.svg'
import { ReactComponent as TrashIcon } from './trash.svg'
import { ReactComponent as TrendingDownIcon } from './trending-down.svg'
import { ReactComponent as TrendingUpIcon } from './trending-up.svg'
import { ReactComponent as TruckIcon } from './truck.svg'
import { ReactComponent as UploadIcon } from './upload.svg'
import { ReactComponent as UserAddIcon } from './user-add.svg'
import { ReactComponent as UserCircleIcon } from './user-circle.svg'
import { ReactComponent as UserGroupIcon } from './user-group.svg'
import { ReactComponent as UserRemoveIcon } from './user-remove.svg'
import { ReactComponent as UserIcon } from './user.svg'
import { ReactComponent as UsersIcon } from './users.svg'
import { ReactComponent as VariableIcon } from './variable.svg'
import { ReactComponent as VideoCameraIcon } from './video-camera.svg'
import { ReactComponent as ViewBoardsIcon } from './view-boards.svg'
import { ReactComponent as ViewGridAddIcon } from './view-grid-add.svg'
import { ReactComponent as ViewGridIcon } from './view-grid.svg'
import { ReactComponent as ViewListIcon } from './view-list.svg'
import { ReactComponent as VolumeOffIcon } from './volume-off.svg'
import { ReactComponent as VolumeUpIcon } from './volume-up.svg'
import { ReactComponent as WifiIcon } from './wifi.svg'
import { ReactComponent as XCircleIcon } from './x-circle.svg'
import { ReactComponent as XIcon } from './x.svg'
import { ReactComponent as ZoomInIcon } from './zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from './zoom-out.svg'

const HeroIcons: HeroIconsObjectType = {
    'academic-cap': AcademicCapIcon,
    adjustments: AdjustmentsIcon,
    annotation: AnnotationIcon,
    archive: ArchiveIcon,
    'arrow-circle-down': ArrowCircleDownIcon,
    'arrow-circle-left': ArrowCircleLeftIcon,
    'arrow-circle-right': ArrowCircleRightIcon,
    'arrow-circle-up': ArrowCircleUpIcon,
    'arrow-down': ArrowDownIcon,
    'arrow-left': ArrowLeftIcon,
    'arrow-narrow-down': ArrowNarrowDownIcon,
    'arrow-narrow-left': ArrowNarrowLeftIcon,
    'arrow-narrow-right': ArrowNarrowRightIcon,
    'arrow-narrow-up': ArrowNarrowUpIcon,
    'arrow-right': ArrowRightIcon,
    'arrow-sm-down': ArrowSmDownIcon,
    'arrow-sm-left': ArrowSmLeftIcon,
    'arrow-sm-right': ArrowSmRightIcon,
    'arrow-sm-up': ArrowSmUpIcon,
    'arrow-up': ArrowUpIcon,
    'arrows-expand': ArrowsExpandIcon,
    'at-symbol': AtSymbolIcon,
    backspace: BackspaceIcon,
    'badge-check': BadgeCheckIcon,
    ban: BanIcon,
    beaker: BeakerIcon,
    bell: BellIcon,
    'book-open': BookOpenIcon,
    'bookmark-alt': BookmarkAltIcon,
    bookmark: BookmarkIcon,
    briefcase: BriefcaseIcon,
    cake: CakeIcon,
    calculator: CalculatorIcon,
    calendar: CalendarIcon,
    camera: CameraIcon,
    cash: CashIcon,
    'chart-bar': ChartBarIcon,
    'chart-pie': ChartPieIcon,
    'chart-square-bar': ChartSquareBarIcon,
    'chat-alt-2': ChatAlt2Icon,
    'chat-alt': ChatAltIcon,
    chat: ChatIcon,
    'check-circle': CheckCircleIcon,
    check: CheckIcon,
    'chevron-double-down': ChevronDoubleDownIcon,
    'chevron-double-left': ChevronDoubleLeftIcon,
    'chevron-double-right': ChevronDoubleRightIcon,
    'chevron-double-up': ChevronDoubleUpIcon,
    'chevron-down': ChevronDownIcon,
    'chevron-left': ChevronLeftIcon,
    'chevron-right': ChevronRightIcon,
    'chevron-up': ChevronUpIcon,
    chip: ChipIcon,
    'clipboard-check': ClipboardCheckIcon,
    'clipboard-copy': ClipboardCopyIcon,
    'clipboard-list': ClipboardListIcon,
    clipboard: ClipboardIcon,
    clock: ClockIcon,
    'cloud-download': CloudDownloadIcon,
    'cloud-upload': CloudUploadIcon,
    cloud: CloudIcon,
    code: CodeIcon,
    cog: CogIcon,
    collection: CollectionIcon,
    'color-swatch': ColorSwatchIcon,
    'credit-card': CreditCardIcon,
    'cube-transparent': CubeTransparentIcon,
    cube: CubeIcon,
    'currency-bangladeshi': CurrencyBangladeshiIcon,
    'currency-dollar': CurrencyDollarIcon,
    'currency-euro': CurrencyEuroIcon,
    'currency-pound': CurrencyPoundIcon,
    'currency-yen': CurrencyYenIcon,
    'cursor-click': CursorClickIcon,
    database: DatabaseIcon,
    'desktop-computer': DesktopComputerIcon,
    'device-mobile': DeviceMobileIcon,
    'device-tablet': DeviceTabletIcon,
    'document-add': DocumentAddIcon,
    'document-download': DocumentDownloadIcon,
    'document-duplicate': DocumentDuplicateIcon,
    'document-remove': DocumentRemoveIcon,
    'document-report': DocumentReportIcon,
    'document-search': DocumentSearchIcon,
    'document-text': DocumentTextIcon,
    document: DocumentIcon,
    'dots-circle-horizontal': DotsCircleHorizontalIcon,
    'dots-horizontal': DotsHorizontalIcon,
    'dots-vertical': DotsVerticalIcon,
    download: DownloadIcon,
    duplicate: DuplicateIcon,
    'emoji-happy': EmojiHappyIcon,
    'emoji-sad': EmojiSadIcon,
    'exclamation-circle': ExclamationCircleIcon,
    exclamation: ExclamationIcon,
    'external-link': ExternalLinkIcon,
    'eye-off': EyeOffIcon,
    eye: EyeIcon,
    'fast-forward': FastForwardIcon,
    film: FilmIcon,
    filter: FilterIcon,
    'finger-print': FingerPrintIcon,
    fire: FireIcon,
    flag: FlagIcon,
    'folder-add': FolderAddIcon,
    'folder-download': FolderDownloadIcon,
    'folder-open': FolderOpenIcon,
    'folder-remove': FolderRemoveIcon,
    folder: FolderIcon,
    gift: GiftIcon,
    'globe-alt': GlobeAltIcon,
    globe: GlobeIcon,
    hand: HandIcon,
    hashtag: HashtagIcon,
    heart: HeartIcon,
    home: HomeIcon,
    identification: IdentificationIcon,
    'inbox-in': InboxInIcon,
    inbox: InboxIcon,
    'information-circle': InformationCircleIcon,
    key: KeyIcon,
    library: LibraryIcon,
    'light-bulb': LightBulbIcon,
    'lightning-bolt': LightningBoltIcon,
    link: LinkIcon,
    'location-marker': LocationMarkerIcon,
    'lock-closed': LockClosedIcon,
    'lock-open': LockOpenIcon,
    login: LoginIcon,
    logout: LogoutIcon,
    'mail-open': MailOpenIcon,
    mail: MailIcon,
    map: MapIcon,
    'menu-alt-1': MenuAlt1Icon,
    'menu-alt-2': MenuAlt2Icon,
    'menu-alt-3': MenuAlt3Icon,
    'menu-alt-4': MenuAlt4Icon,
    menu: MenuIcon,
    microphone: MicrophoneIcon,
    'minus-circle': MinusCircleIcon,
    'minus-sm': MinusSmIcon,
    minus: MinusIcon,
    moon: MoonIcon,
    'music-note': MusicNoteIcon,
    newspaper: NewspaperIcon,
    'office-building': OfficeBuildingIcon,
    'paper-airplane': PaperAirplaneIcon,
    'paper-clip': PaperClipIcon,
    pause: PauseIcon,
    'pencil-alt': PencilAltIcon,
    pencil: PencilIcon,
    'phone-incoming': PhoneIncomingIcon,
    'phone-missed-call': PhoneMissedCallIcon,
    'phone-outgoing': PhoneOutgoingIcon,
    phone: PhoneIcon,
    photograph: PhotographIcon,
    play: PlayIcon,
    'plus-circle': PlusCircleIcon,
    'plus-sm': PlusSmIcon,
    plus: PlusIcon,
    'presentation-chart-bar': PresentationChartBarIcon,
    'presentation-chart-line': PresentationChartLineIcon,
    printer: PrinterIcon,
    puzzle: PuzzleIcon,
    qrcode: QrcodeIcon,
    'question-mark-circle': QuestionMarkCircleIcon,
    'receipt-refund': ReceiptRefundIcon,
    'receipt-tax': ReceiptTaxIcon,
    refresh: RefreshIcon,
    reply: ReplyIcon,
    rewind: RewindIcon,
    rss: RssIcon,
    'save-as': SaveAsIcon,
    save: SaveIcon,
    scale: ScaleIcon,
    scissors: ScissorsIcon,
    'search-circle': SearchCircleIcon,
    search: SearchIcon,
    selector: SelectorIcon,
    server: ServerIcon,
    share: ShareIcon,
    'shield-check': ShieldCheckIcon,
    'shield-exclamation': ShieldExclamationIcon,
    'shopping-bag': ShoppingBagIcon,
    'shopping-cart': ShoppingCartIcon,
    'sort-ascending': SortAscendingIcon,
    'sort-descending': SortDescendingIcon,
    sparkles: SparklesIcon,
    speakerphone: SpeakerphoneIcon,
    star: StarIcon,
    'status-offline': StatusOfflineIcon,
    'status-online': StatusOnlineIcon,
    stop: StopIcon,
    sun: SunIcon,
    support: SupportIcon,
    'switch-horizontal': SwitchHorizontalIcon,
    'switch-vertical': SwitchVerticalIcon,
    table: TableIcon,
    tag: TagIcon,
    template: TemplateIcon,
    terminal: TerminalIcon,
    'thumb-down': ThumbDownIcon,
    'thumb-up': ThumbUpIcon,
    ticket: TicketIcon,
    translate: TranslateIcon,
    trash: TrashIcon,
    'trending-down': TrendingDownIcon,
    'trending-up': TrendingUpIcon,
    truck: TruckIcon,
    upload: UploadIcon,
    'user-add': UserAddIcon,
    'user-circle': UserCircleIcon,
    'user-group': UserGroupIcon,
    'user-remove': UserRemoveIcon,
    user: UserIcon,
    users: UsersIcon,
    variable: VariableIcon,
    'video-camera': VideoCameraIcon,
    'view-boards': ViewBoardsIcon,
    'view-grid-add': ViewGridAddIcon,
    'view-grid': ViewGridIcon,
    'view-list': ViewListIcon,
    'volume-off': VolumeOffIcon,
    'volume-up': VolumeUpIcon,
    wifi: WifiIcon,
    'x-circle': XCircleIcon,
    x: XIcon,
    'zoom-in': ZoomInIcon,
    'zoom-out': ZoomOutIcon,
}

export default HeroIcons
