import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoutUser } from 'redux/actions/userActions'
import { ROLE } from 'enum/roles'
import { SidebarProps } from 'interfaces/SidebarProps.interfaces'
import { StateReducerType } from 'types/StateReducerType'
import { MenuElement } from 'types/MenuElement'
import Menu from 'components/Menu'
import * as Config from 'utils/configUtils'
import { UserHasRequiredRole } from 'utils/authUtils'
import SvgIcon from 'components/SvgIcon'

const Sidebar = ({ user, logoutUser, sidebarActive, searchIsEnabled, translation }: SidebarProps) => {
    const userSession = user.user
    const location = useLocation()
    const splitLocation = location.pathname.split('/')
    const activeMenuItem = splitLocation[1]
    const logoutItem: MenuElement = {
        code: 'logout',
        label: 'Logout',
        href: '',
        subItems: [],
        hasIcon: true,
        icon: { folder: 'heroicons', name: 'logout' },
        onClickHandler: logoutUser,
    }
    const [sideBarMenuItems, setSideBarMenuItems] = useState<MenuElement[]>([])
    const [sideBarSecondaryMenuItems, setSideBarSecondaryMenuItems] = useState<MenuElement[]>([])

    useEffect(() => {
        if (!!userSession.role) {
            const refreshSideBarMenuItems: Array<MenuElement> = Config.menuItems.filter(
                (menuItem: MenuElement) =>
                    UserHasRequiredRole(
                        true,
                        typeof userSession.role !== 'undefined' ? userSession.role : ROLE.USER,
                        menuItem.roles
                    ) === true
            )
            const refreshSideBarSecondaryMenuItems: Array<MenuElement> = Config.secondaryMenuItems.filter(
                (menuItem: MenuElement) =>
                    UserHasRequiredRole(
                        true,
                        typeof userSession.role !== 'undefined' ? userSession.role : ROLE.USER,
                        menuItem.roles
                    ) === true
            )
            refreshSideBarSecondaryMenuItems.push(logoutItem)
            setSideBarMenuItems(refreshSideBarMenuItems)
            setSideBarSecondaryMenuItems(refreshSideBarSecondaryMenuItems)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSession.role])

    return (
        <React.Fragment>
            <aside
                className={`fixed ${
                    sidebarActive === false ? 'hidden' : ''
                } z-30 h-full top-0 left-0 pt-16 flex lg:flex flex-shrink-0 flex-col w-64 transition-all duration-300`}
            >
                <div className={'relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0'}>
                    <div className={'flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'}>
                        <div className={'flex-1 px-3 bg-white space-y-1'}>
                            {searchIsEnabled === true && (
                                <span className={'lg:hidden'}>
                                    <label htmlFor={'mobile-search'} className={'sr-only'}>
                                        {translation('search')}
                                    </label>
                                    <div className={'relative'}>
                                        <div
                                            className={
                                                'absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'
                                            }
                                        >
                                            <SvgIcon
                                                className={'w-5 h-5 text-gray-500'}
                                                folder={'heroicons'}
                                                name={'search'}
                                            />
                                        </div>
                                        <input
                                            type={'text'}
                                            className={
                                                'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-600 focus:ring-cyan-600 block w-full pl-10 p-2.5'
                                            }
                                            placeholder={translation('search')}
                                        ></input>
                                    </div>
                                </span>
                            )}
                            <Menu menuItems={sideBarMenuItems} activeItem={activeMenuItem} translation={translation} />
                            <Menu
                                className={'border-t! pt-2 pb-0!'}
                                menuItems={sideBarSecondaryMenuItems}
                                activeItem={activeMenuItem}
                                translation={translation}
                            />
                        </div>
                    </div>
                </div>
            </aside>
            <div
                className={`fixed top-0 w-full h-full min-h-screen ${
                    sidebarActive === false ? 'hidden' : ''
                } bg-gray-900 opacity-50 inset-0 z-20`}
            ></div>
        </React.Fragment>
    )
}

// this map the states to our props in this funtional component
const mapStateToProps = (state: StateReducerType) => ({
    user: state.user,
    UI: state.UI,
})

// this map actions to our props in this functional component
const mapActionsToProps = {
    logoutUser,
}

export default connect(mapStateToProps, mapActionsToProps)(Sidebar)
