import { MarketingIconsObjectType } from 'types/MarketingIconsObjectType'
import { ReactComponent as BarChartIcon } from './bar-chart.svg'
import { ReactComponent as GraphIcon } from './graph.svg'
import { ReactComponent as KeywordResearchIcon } from './keyword-research.svg'
import { ReactComponent as NetworkIcon } from './network.svg'
import { ReactComponent as SeoIcon } from './seo.svg'
import { ReactComponent as TargetCustomerIcon } from './target-customer.svg'

const MarketingIcons: MarketingIconsObjectType = {
    'bar-chart': BarChartIcon,
    graph: GraphIcon,
    'keyword-research': KeywordResearchIcon,
    network: NetworkIcon,
    seo: SeoIcon,
    'target-customer': TargetCustomerIcon,
}

export default MarketingIcons
