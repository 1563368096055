import { InterfaceAndSignIconsObjectType } from 'types/InterfaceAndSignIconsObjectType'
import { ReactComponent as BoltAltIcon } from './bolt-alt.svg'
import { ReactComponent as BoltIcon } from './bolt.svg'
import { ReactComponent as CheckBoxIcon } from './check-box.svg'
import { ReactComponent as CheckmarkCircleIcon } from './checkmark-circle.svg'
import { ReactComponent as CheckmarkIcon } from './checkmark.svg'
import { ReactComponent as CircleMinusIcon } from './circle-minus.svg'
import { ReactComponent as CirclePlusIcon } from './circle-plus.svg'
import { ReactComponent as CloseIcon } from './close.svg'
import { ReactComponent as CrossCircleIcon } from './cross-circle.svg'
import { ReactComponent as EyeIcon } from './eye.svg'
import { ReactComponent as FlagAltIcon } from './flag-alt.svg'
import { ReactComponent as FlagIcon } from './flag.svg'
import { ReactComponent as FunnelIcon } from './funnel.svg'
import { ReactComponent as HandIcon } from './hand.svg'
import { ReactComponent as HeartFilledIcon } from './heart-filled.svg'
import { ReactComponent as HeartIcon } from './heart.svg'
import { ReactComponent as HelpIcon } from './help.svg'
import { ReactComponent as InfiniteIcon } from './infinite.svg'
import { ReactComponent as InformationIcon } from './information.svg'
import { ReactComponent as InventionIcon } from './invention.svg'
import { ReactComponent as KeyIcon } from './key.svg'
import { ReactComponent as LiferingIcon } from './lifering.svg'
import { ReactComponent as ListIcon } from './list.svg'
import { ReactComponent as LockAltIcon } from './lock-alt.svg'
import { ReactComponent as LockIcon } from './lock.svg'
import { ReactComponent as MinusIcon } from './minus.svg'
import { ReactComponent as MoreAltIcon } from './more-alt.svg'
import { ReactComponent as MoreIcon } from './more.svg'
import { ReactComponent as MusicIcon } from './music.svg'
import { ReactComponent as PlusIcon } from './plus.svg'
import { ReactComponent as PointerIcon } from './pointer.svg'
import { ReactComponent as PowerSwitchIcon } from './power-switch.svg'
import { ReactComponent as ProtectionIcon } from './protection.svg'
import { ReactComponent as QuestionCircleIcon } from './question-circle.svg'
import { ReactComponent as RadioButtonIcon } from './radio-button.svg'
import { ReactComponent as ShieldIcon } from './shield.svg'
import { ReactComponent as TeabagIcon } from './teabag.svg'
import { ReactComponent as ThumbsDownIcon } from './thumbs-down.svg'
import { ReactComponent as ThumbsUpIcon } from './thumbs-up.svg'
import { ReactComponent as TicketAltIcon } from './ticket-alt.svg'
import { ReactComponent as TicketIcon } from './ticket.svg'
import { ReactComponent as UnlockIcon } from './unlock.svg'
import { ReactComponent as WarningIcon } from './warning.svg'

const InterfaceAndSignIcons: InterfaceAndSignIconsObjectType = {
    'bolt-alt': BoltAltIcon,
    bolt: BoltIcon,
    'check-box': CheckBoxIcon,
    'checkmark-circle': CheckmarkCircleIcon,
    checkmark: CheckmarkIcon,
    'circle-minus': CircleMinusIcon,
    'circle-plus': CirclePlusIcon,
    close: CloseIcon,
    'cross-circle': CrossCircleIcon,
    eye: EyeIcon,
    'flag-alt': FlagAltIcon,
    flag: FlagIcon,
    funnel: FunnelIcon,
    hand: HandIcon,
    'heart-filled': HeartFilledIcon,
    heart: HeartIcon,
    help: HelpIcon,
    infinite: InfiniteIcon,
    information: InformationIcon,
    invention: InventionIcon,
    key: KeyIcon,
    lifering: LiferingIcon,
    list: ListIcon,
    'lock-alt': LockAltIcon,
    lock: LockIcon,
    minus: MinusIcon,
    'more-alt': MoreAltIcon,
    more: MoreIcon,
    music: MusicIcon,
    plus: PlusIcon,
    pointer: PointerIcon,
    'power-switch': PowerSwitchIcon,
    protection: ProtectionIcon,
    'question-circle': QuestionCircleIcon,
    'radio-button': RadioButtonIcon,
    shield: ShieldIcon,
    teabag: TeabagIcon,
    'thumbs-down': ThumbsDownIcon,
    'thumbs-up': ThumbsUpIcon,
    'ticket-alt': TicketAltIcon,
    ticket: TicketIcon,
    unlock: UnlockIcon,
    warning: WarningIcon,
}

export default InterfaceAndSignIcons
