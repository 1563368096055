import React, { useEffect, useState } from 'react'
import { FormikHelpers, useFormik } from 'formik'
import toast, { Toaster } from 'react-hot-toast'
import { AxiosError, AxiosResponse } from 'axios'
import ResetPasswordServices from 'services/ResetPasswordServices'
import { ResetPasswordFormInputNameType } from 'types/ResetPasswordFormInputNameType'
import { ResetPasswordFormProps } from 'interfaces/ResetPasswordFormProps.interfaces'
import { DataResetPassword } from 'interfaces/DataResetPassword.interfaces'
import { ApiResponse } from 'interfaces/ApiResponse.interfaces'
import { ResetPasswordRequestResponse } from 'interfaces/ResetPasswordRequestResponse.interfaces'
import InputText from 'components/InputText'
import InputTextError from 'components/InputTextError'
import ToastNotification from 'components/ToastNotification'
import SvgIcon from 'components/SvgIcon'
import PrimaryButton from 'components/PrimaryButton'
import SecondaryButton from 'components/SecondaryButton'
import LoadingSpinner from 'components/LoadingSpinner'
import { clearInterval, setInterval } from 'timers'

const ResetPasswordForm = ({
    translation,
    showOtherBtn = false,
    labelOtherBtn,
    onClickOtherBtnHandler,
}: ResetPasswordFormProps) => {
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [resetOk, setResetOk] = useState(false)
    const timeoutResentEmail = 120
    const [disableResentEmail, setDisableResentEmail] = useState(false)
    const [countDownResend, setCountDownResend] = useState(timeoutResentEmail)
    const [minutesCountDownResend, setMinutesCountDownResend] = useState(2)
    const [secondsCountDownResend, setSecondsCountDownResend] = useState(0)

    const resetResendOtp = () => {
        // reinizializza il timer e riabilita il pulsante di invio
        setDisableResentEmail(false)
        setCountDownResend(timeoutResentEmail)
        setMinutesCountDownResend(2)
        setSecondsCountDownResend(0)
    }

    const timeFormatter = (value: number): string => {
        return String(value).padStart(2, '0')
    }

    useEffect(() => {
        let countdownId: NodeJS.Timeout | undefined

        if (disableResentEmail) {
            if (countDownResend >= 0) {
                countdownId = setInterval(() => {
                    setCountDownResend(countDownResend - 1)
                    setMinutesCountDownResend(Math.trunc(countDownResend / 60))
                    setSecondsCountDownResend(countDownResend % 60)
                }, 1000)
            } else {
                setDisableResentEmail(false)
                setCountDownResend(timeoutResentEmail)
                if (typeof countdownId !== 'undefined') {
                    clearInterval(countdownId)
                }
            }
        } else {
            resetResendOtp()
        }

        return () => {
            if (typeof countdownId !== 'undefined') {
                clearInterval(countdownId)
            }
        }
    }, [countDownResend, disableResentEmail])

    const resetPasswordForm = useFormik({
        initialValues: {
            username: '',
        },
        validate: (data: DataResetPassword) => {
            const errors: Partial<DataResetPassword> = {}

            if (!data.username || data.username.trim() === '') {
                errors.username = translation('errorEmailRequired')
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.username)) {
                errors.username = translation('errorEmailInvalid')
            }

            return errors
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit: (formValue: DataResetPassword, helpers: FormikHelpers<any>) => {
            setLoading(true)
            ResetPasswordServices.resetPasswordRequest(formValue.username)
                .then((response: AxiosResponse<ResetPasswordRequestResponse>) => {
                    const hasBeenSuccessful =
                        response.data.user !== null &&
                        response.data.resetPasswordGuid !== null &&
                        response.data.resetPasswordGuid !== ''
                            ? true
                            : false
                    setLoading(false)
                    setDisabled(hasBeenSuccessful)
                    toast.custom(
                        (t) => (
                            <ToastNotification
                                t={t}
                                severity={hasBeenSuccessful === true ? 'success' : 'error'}
                                message={translation(
                                    hasBeenSuccessful === true ? 'messageResetRequestSuccess' : 'messageGenericError'
                                )}
                            />
                        ),
                        {
                            duration: 5000,
                        }
                    )
                    if (hasBeenSuccessful === true) {
                        setResetOk(true)
                        try {
                            localStorage.setItem('resetPwdEmail', formValue.username)
                        } catch (error) {}
                    }
                    setDisableResentEmail(true)
                })
                .catch((err: AxiosError<ApiResponse>) => {
                    setLoading(false)
                    setDisabled(false)
                    setResetOk(false)
                    localStorage.removeItem('resetPwdEmail')
                    toast.custom((t) => (
                        <ToastNotification
                            t={t}
                            severity={'error'}
                            message={translation(getErrorLabelHandler(err?.response?.data.message ?? ''))}
                        />
                    ))
                })
        },
    })

    const resendEmail = () => {
        const email = localStorage.getItem('resetPwdEmail') !== null ? localStorage.getItem('resetPwdEmail') : ''

        if (email !== '' && email !== null) {
            setLoading(true)
            ResetPasswordServices.resetPasswordNewEmail(email)
                .then((response: AxiosResponse<ResetPasswordRequestResponse>) => {
                    const hasBeenSuccessful =
                        response.data.user !== null &&
                        response.data.resetPasswordGuid !== null &&
                        response.data.resetPasswordGuid !== ''
                            ? true
                            : false
                    setLoading(false)
                    setDisabled(hasBeenSuccessful)
                    toast.custom(
                        (t) => (
                            <ToastNotification
                                t={t}
                                severity={hasBeenSuccessful === true ? 'success' : 'error'}
                                message={translation(
                                    hasBeenSuccessful === true ? 'messageResetNewEmailSuccess' : 'messageGenericError'
                                )}
                            />
                        ),
                        {
                            duration: 5000,
                        }
                    )
                    if (hasBeenSuccessful === true) {
                        setResetOk(true)
                        try {
                            localStorage.setItem('resetPwdEmail', email)
                        } catch (error) {}
                    }
                    setDisableResentEmail(true)
                })
                .catch((err: AxiosError<ApiResponse>) => {
                    setLoading(false)
                    setDisabled(false)
                    setResetOk(false)
                    localStorage.removeItem('resetPwdEmail')
                    toast.custom((t) => (
                        <ToastNotification
                            t={t}
                            severity={'error'}
                            message={translation(getErrorLabelHandler(err?.response?.data.message ?? ''))}
                        />
                    ))
                })
        } else {
        }

        resetResendOtp()
    }

    const getErrorLabelHandler = (error: string): string => {
        switch (error) {
            default:
                return 'messageGenericError'
        }
    }

    const isFormFieldValid = (name: ResetPasswordFormInputNameType) =>
        !!(resetPasswordForm.touched[name] && resetPasswordForm.errors[name])

    const checkFormInputError = (name: ResetPasswordFormInputNameType): boolean => {
        return isFormFieldValid(name)
    }

    const getFormErrorMessage = (name: ResetPasswordFormInputNameType) => {
        return (
            isFormFieldValid(name) && (
                <InputTextError className={'mt-0.5! leading-4'} message={resetPasswordForm.errors[name]} />
            )
        )
    }

    return (
        <React.Fragment>
            <Toaster position={'top-right'} reverseOrder={false} />
            <form className={'flex flex-col'} onSubmit={resetPasswordForm.handleSubmit}>
                <div className={'flex flex-col mb-4'}>
                    <label
                        htmlFor={'username'}
                        className={`text-xs px-1 ${
                            checkFormInputError('username') === true ? 'text-red-700' : 'text-neutral-700'
                        }`}
                    >
                        {translation('emailUsername')}
                    </label>
                    <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                            <SvgIcon folder={'heroicons'} name={'mail'} className={'w-5 text-gray-400'} />
                        </div>
                        <InputText
                            type={'text'}
                            className={'w-full h-10 -ml-10 pl-10 pr-3'}
                            name={'username'}
                            placeholder={'your@email.com'}
                            onChange={resetPasswordForm.handleChange}
                            error={checkFormInputError('username')}
                        />
                    </div>
                    {getFormErrorMessage('username')}
                </div>

                {loading ? (
                    <div className={'flex items-center justify-center'}>
                        <LoadingSpinner width={'42px'} height={'42px'} />
                    </div>
                ) : (
                    <div>
                        {!!resetOk === true && disableResentEmail === false && (
                            <div className={'text-center mt-4'}>
                                <p>
                                    {translation('resetNewEmail')}
                                    <button
                                        type={'button'}
                                        className={'underline font-semibold ml-1'}
                                        onClick={() => resendEmail()}
                                    >
                                        {translation('resend')}
                                    </button>
                                </p>
                            </div>
                        )}
                        {!!resetOk === true && disableResentEmail === true && (
                            <p className={'text-center mb-6'}>
                                {translation('txtCountDownEmail')}
                                <span className={'inline-block ml-1 font-semibold'}>{`${timeFormatter(
                                    minutesCountDownResend
                                )}:${timeFormatter(secondsCountDownResend)}`}</span>
                            </p>
                        )}
                        <div className={'flex items-center justify-center mt-2 sm:mt-4'}>
                            {!!resetOk === false && (
                                <PrimaryButton
                                    type={'submit'}
                                    className={'w-32 mr-3 py-2!'}
                                    text={translation('resetPasswordBtn')}
                                    disabled={disabled}
                                />
                            )}

                            {!!showOtherBtn === true && (
                                <SecondaryButton
                                    type={'button'}
                                    className={'py-2!'}
                                    text={labelOtherBtn}
                                    onClickHandler={onClickOtherBtnHandler}
                                />
                            )}
                        </div>
                    </div>
                )}
            </form>
        </React.Fragment>
    )
}

export default ResetPasswordForm
