import { EducationIconsObjectType } from 'types/EducationIconsObjectType'
import { ReactComponent as BlackboardIcon } from './blackboard.svg'
import { ReactComponent as BookIcon } from './book.svg'
import { ReactComponent as CertificateIcon } from './certificate.svg'
import { ReactComponent as ClipboardIcon } from './clipboard.svg'
import { ReactComponent as CompassIcon } from './compass.svg'
import { ReactComponent as EraserIcon } from './eraser.svg'
import { ReactComponent as GraduationIcon } from './graduation.svg'
import { ReactComponent as LibraryIcon } from './library.svg'
import { ReactComponent as PencilAltIcon } from './pencil-alt.svg'
import { ReactComponent as PencilIcon } from './pencil.svg'
import { ReactComponent as SchoolBenchAltIcon } from './school-bench-alt.svg'
import { ReactComponent as SchoolBenchIcon } from './school-bench.svg'
import { ReactComponent as WorldAltIcon } from './world-alt.svg'
import { ReactComponent as WorldIcon } from './world.svg'
import { ReactComponent as WriteIcon } from './write.svg'

const EducationIcons: EducationIconsObjectType = {
    blackboard: BlackboardIcon,
    book: BookIcon,
    certificate: CertificateIcon,
    clipboard: ClipboardIcon,
    compass: CompassIcon,
    eraser: EraserIcon,
    graduation: GraduationIcon,
    library: LibraryIcon,
    'pencil-alt': PencilAltIcon,
    pencil: PencilIcon,
    'school-bench-alt': SchoolBenchAltIcon,
    'school-bench': SchoolBenchIcon,
    'world-alt': WorldAltIcon,
    world: WorldIcon,
    write: WriteIcon,
}

export default EducationIcons
