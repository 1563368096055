import { connect } from 'react-redux'
import { StateReducerType } from 'types/StateReducerType'
import { BaseSessionApp } from 'interfaces/BaseSessionApp.interfaces'
import Page from 'components/Page'

const UserDashboard = ({ translation, i18n, languages }: BaseSessionApp) => {
    return (
        <Page fullWidth={true} searchIsEnabled={false} translation={translation} i18n={i18n} languages={languages}>
            <div className={'pt-6 px-4'}>User dashboard</div>
        </Page>
    )
}

const mapStateToProps = (state: StateReducerType) => ({
    user: state.user,
    UI: state.UI,
})

export default connect(mapStateToProps)(UserDashboard)
