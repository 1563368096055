import { ReactNode } from 'react'

interface ButtonProps {
    text: string | JSX.Element | React.ReactChild | ReactNode | React.FC
    type?: 'submit' | 'reset' | 'button' | undefined
    disabled?: boolean
    circular?: boolean
    className?: string
    onClickHandler?: () => void
}

const Button = ({
    text,
    type = 'button',
    disabled = false,
    circular = false,
    className,
    onClickHandler,
}: ButtonProps) => {
    const onClickDefaultHandler = () => {
        return
    }

    return (
        <button
            type={type}
            className={`items-center ${
                circular === true ? 'px-2 py-2' : 'px-6 py-2'
            } border border-transparent rounded-full disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none hover:shadow ${
                className ?? ''
            }`}
            disabled={disabled ?? false}
            onClick={onClickHandler ?? onClickDefaultHandler}
        >
            {text}
        </button>
    )
}

export default Button
