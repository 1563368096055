import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikHelpers, useFormik } from 'formik'
import toast, { Toaster } from 'react-hot-toast'
import { AxiosError, AxiosResponse } from 'axios'
import AuthServices from 'services/AuthServices'
import { SignupFormInputNameType } from 'types/SignupFormInputNameType'
import { SignupFormProps } from 'interfaces/SignupFormProps.interfaces'
import { DataRegistrationUser } from 'interfaces/DataRegistrationUser.interfaces'
import { ApiResponse } from 'interfaces/ApiResponse.interfaces'
import { RegisterResponse } from 'interfaces/RegisterResponse.interfaces'
import InputText from 'components/InputText'
import InputTextError from 'components/InputTextError'
import ToastNotification from 'components/ToastNotification'
import SvgIcon from 'components/SvgIcon'
import PrimaryButton from 'components/PrimaryButton'
import SecondaryButton from 'components/SecondaryButton'
import LoadingSpinner from 'components/LoadingSpinner'

const SignupForm = ({ translation, showOtherBtn = false, labelOtherBtn, onClickOtherBtnHandler }: SignupFormProps) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const signUpForm = useFormik({
        initialValues: {
            name: '',
            surname: '',
            username: '',
            password: '',
            confirmPassword: '',
        },
        validate: (data: DataRegistrationUser) => {
            const errors: Partial<DataRegistrationUser> = {}

            if (!data.name || data.name.trim() === '') {
                errors.name = translation('errorNameRequired')
            }

            if (!data.surname || data.surname.trim() === '') {
                errors.surname = translation('errorSurnameRequired')
            }

            if (!data.username || data.username.trim() === '') {
                errors.username = translation('errorEmailRequired')
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.username)) {
                errors.username = translation('errorEmailInvalid')
            }

            if (!data.password || data.password.trim().length === 0) {
                errors.password = translation('errorPasswordRequired')
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}$/.test(data.password)) {
                errors.password = translation('errorPasswordInvalid')
            }

            if (!data.confirmPassword || data.confirmPassword.trim().length === 0) {
                errors.confirmPassword = translation('errorConfirmPasswordRequired')
            } else if (data.confirmPassword !== data.password) {
                errors.confirmPassword = translation('errorConfirmPasswordSamePassword')
            }

            return errors
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit: (formValue: DataRegistrationUser, helpers: FormikHelpers<any>) => {
            setLoading(true)
            AuthServices.register(formValue)
                .then((response: AxiosResponse<RegisterResponse>) => {
                    const hasBeenSuccessful =
                        !!response.data.data.id &&
                        response.data.data.id > 0 &&
                        !!response.data.data.guid &&
                        response.data.data.guid !== ''
                            ? true
                            : false
                    setLoading(false)
                    setDisabled(hasBeenSuccessful)
                    toast.custom(
                        (t) => (
                            <ToastNotification
                                t={t}
                                severity={hasBeenSuccessful === true ? 'success' : 'error'}
                                message={translation(
                                    hasBeenSuccessful === true ? 'messageSignUpSuccess' : 'messageGenericError'
                                )}
                            />
                        ),
                        {
                            duration: 5000,
                        }
                    )
                    if (hasBeenSuccessful === true) {
                        setTimeout(() => history.push('/sign-in'), 5010)
                    }
                })
                .catch((err: AxiosError<ApiResponse>) => {
                    setLoading(false)
                    setDisabled(false)
                    toast.custom((t) => (
                        <ToastNotification
                            t={t}
                            severity={'error'}
                            message={translation(getErrorLabelHandler(err?.response?.data.message ?? ''))}
                        />
                    ))
                })
        },
    })

    const getErrorLabelHandler = (error: string): string => {
        switch (error) {
            case 'Username not available':
                return 'messageErrorUsernameNotAvailable'
            case 'Password not valid':
                return 'messageErrorPasswordNotValid'
            default:
                return 'messageGenericError'
        }
    }

    const isFormFieldValid = (name: SignupFormInputNameType) => !!(signUpForm.touched[name] && signUpForm.errors[name])

    const checkFormInputError = (name: SignupFormInputNameType): boolean => {
        return isFormFieldValid(name)
    }

    const getFormErrorMessage = (name: SignupFormInputNameType) => {
        return (
            isFormFieldValid(name) && (
                <InputTextError className={'mt-0.5! leading-4'} message={signUpForm.errors[name]} />
            )
        )
    }

    return (
        <React.Fragment>
            <Toaster position={'top-right'} reverseOrder={false} />
            <form className={'flex flex-col'} onSubmit={signUpForm.handleSubmit}>
                <div className={'flex flex-col mb-4'}>
                    <label
                        htmlFor={'username'}
                        className={`text-xs px-1 ${
                            checkFormInputError('username') === true ? 'text-red-700' : 'text-neutral-700'
                        }`}
                    >
                        {translation('emailUsername')}
                    </label>
                    <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                            <SvgIcon folder={'heroicons'} name={'mail'} className={'w-5 text-gray-400'} />
                        </div>
                        <InputText
                            type={'text'}
                            className={'w-full h-10 -ml-10 pl-10 pr-3'}
                            name={'username'}
                            placeholder={'your@email.com'}
                            onChange={signUpForm.handleChange}
                            error={checkFormInputError('username')}
                        />
                    </div>
                    {getFormErrorMessage('username')}
                </div>
                <div className={'flex flex-col mb-4'}>
                    <label
                        htmlFor={'password'}
                        className={`text-xs px-1 ${
                            checkFormInputError('password') === true ? 'text-red-700' : 'text-neutral-700'
                        }`}
                    >
                        {translation('password')}
                    </label>
                    <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                            <SvgIcon folder={'heroicons'} name={'key'} className={'w-5 text-gray-400'} />
                        </div>
                        <InputText
                            type={'password'}
                            className={'w-full h-10 -ml-10 pl-10 pr-3'}
                            name={'password'}
                            placeholder={'************'}
                            onChange={signUpForm.handleChange}
                            error={checkFormInputError('password')}
                        />
                    </div>
                    {getFormErrorMessage('password')}
                </div>
                <div className={'flex flex-col mb-4'}>
                    <label
                        htmlFor={'confirmPassword'}
                        className={`text-xs px-1 ${
                            checkFormInputError('confirmPassword') === true ? 'text-red-700' : 'text-neutral-700'
                        }`}
                    >
                        {translation('confirmPassword')}
                    </label>
                    <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                            <SvgIcon folder={'heroicons'} name={'key'} className={'w-5 text-gray-400'} />
                        </div>
                        <InputText
                            type={'password'}
                            className={'w-full h-10 -ml-10 pl-10 pr-3'}
                            name={'confirmPassword'}
                            placeholder={'************'}
                            onChange={signUpForm.handleChange}
                            error={checkFormInputError('confirmPassword')}
                        />
                    </div>
                    {getFormErrorMessage('confirmPassword')}
                </div>
                <div className={'flex flex-col mb-4'}>
                    <label
                        htmlFor={'name'}
                        className={`text-xs px-1 ${
                            checkFormInputError('name') === true ? 'text-red-700' : 'text-neutral-700'
                        }`}
                    >
                        {translation('firstname')}
                    </label>
                    <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                            <SvgIcon folder={'heroicons'} name={'user'} className={'w-5 text-gray-400'} />
                        </div>
                        <InputText
                            type={'text'}
                            className={'w-full h-10 -ml-10 pl-10 pr-3'}
                            name={'name'}
                            placeholder={translation('firstname')}
                            onChange={signUpForm.handleChange}
                            error={checkFormInputError('name')}
                        />
                    </div>
                    {getFormErrorMessage('name')}
                </div>
                <div className={'flex flex-col mb-4'}>
                    <label
                        htmlFor={'surname'}
                        className={`text-xs px-1 ${
                            checkFormInputError('surname') === true ? 'text-red-700' : 'text-neutral-700'
                        }`}
                    >
                        {translation('lastname')}
                    </label>
                    <div className="flex">
                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                            <SvgIcon folder={'heroicons'} name={'user'} className={'w-5 text-gray-400'} />
                        </div>
                        <InputText
                            type={'text'}
                            className={'w-full h-10 -ml-10 pl-10 pr-3'}
                            name={'surname'}
                            placeholder={translation('lastname')}
                            onChange={signUpForm.handleChange}
                            error={checkFormInputError('surname')}
                        />
                    </div>
                    {getFormErrorMessage('surname')}
                </div>
                {loading ? (
                    <div className={'flex items-center justify-center'}>
                        <LoadingSpinner width={'42px'} height={'42px'} />
                    </div>
                ) : (
                    <div className={'flex items-center justify-center mt-2 sm:mt-4'}>
                        <PrimaryButton
                            type={'submit'}
                            className={'w-32 mr-3 py-2!'}
                            text={translation('signUp')}
                            disabled={disabled}
                        />
                        {!!showOtherBtn === true && (
                            <SecondaryButton
                                type={'button'}
                                className={'py-2!'}
                                text={labelOtherBtn}
                                onClickHandler={onClickOtherBtnHandler}
                                disabled={disabled}
                            />
                        )}
                    </div>
                )}
            </form>
        </React.Fragment>
    )
}

export default SignupForm
