import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { loginUser } from 'redux/actions/userActions'
import { ROLE } from 'enum/roles'
import { StateReducerType } from 'types/StateReducerType'
import { LoginFormValues } from 'types/LoginFormValues'
import { LoginFormProps } from 'interfaces/LoginFormProps.interfaces'
import { FormikHelpers, useFormik } from 'formik'
import InputText from 'components/InputText'
import InputTextError from 'components/InputTextError'
import PrimaryButton from 'components/PrimaryButton'
import AlertErrorMessage from 'components/AlertErrorMessage'
import LoadingSpinner from 'components/LoadingSpinner'
import SecondaryButton from 'components/SecondaryButton'

const LoginForm = ({
    UI,
    loginUser,
    user,
    translation,
    auth2FAEnabled = false,
    showOtherBtn = false,
    labelOtherBtn,
    onClickOtherBtnHandler,
}: LoginFormProps) => {
    const history = useHistory()
    const [loginError, setLoginError] = useState(null)
    const [loading, setLoading] = useState(false)
    const loginForm = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validate: (data: LoginFormValues) => {
            const errors: Partial<LoginFormValues> = {}

            if (!data.username || data.username.trim() === '') {
                errors.username = translation('errorEmailRequired')
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.username)) {
                errors.username = translation('errorEmailInvalid')
            }

            if (!data.password || data.password.trim() === '') {
                errors.password = translation('errorPasswordRequired')
            }

            return errors
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit: (formValue: LoginFormValues, helpers: FormikHelpers<LoginFormValues>) => {
            setLoading(true)
            loginUser(formValue, auth2FAEnabled)
        },
    })

    useEffect(() => {
        if (UI.error) {
            setLoginError(UI.error)
        }
        setLoading(UI.loading)
    }, [UI])

    useEffect(() => {
        if (
            auth2FAEnabled === true &&
            user.authenticated === false &&
            !!user.credentials?.username &&
            !!user.credentials?.password
        ) {
            history.push('/verify-login')
        }

        if (auth2FAEnabled === false && user.authenticated === true) {
            history.push(
                user.user.role === ROLE.ADMINISTRATOR && user.user.isAdministrator === true ? '/admin' : '/dashboard'
            )
        }
    }, [user, history, auth2FAEnabled])

    const isFormFieldValid = (name: 'username' | 'password') => !!(loginForm.touched[name] && loginForm.errors[name])

    const checkFormInputError = (name: 'username' | 'password'): boolean => {
        return isFormFieldValid(name)
    }

    const getFormErrorMessage = (name: 'username' | 'password') => {
        return isFormFieldValid(name) && <InputTextError message={loginForm.errors[name]} />
    }

    return (
        <form className={'flex flex-col pt-2'} onSubmit={loginForm.handleSubmit}>
            {!!loginError && loginError !== '' && <AlertErrorMessage message={loginError} />}
            <div className={'flex flex-col pt-4 mb-6'}>
                <label
                    htmlFor="username"
                    className={`text-sm  ${
                        checkFormInputError('username') === true ? 'text-red-700' : 'text-neutral-700'
                    }`}
                >
                    {translation('email')}
                </label>
                <InputText
                    type={'text'}
                    className={'pl-1.5 mt-2'}
                    name={'username'}
                    placeholder={'your@email.com'}
                    onChange={loginForm.handleChange}
                    error={checkFormInputError('username')}
                />
                {getFormErrorMessage('username')}
            </div>
            <div className={'flex flex-col mb-1'}>
                <div>
                    <label
                        htmlFor="password"
                        className={`text-sm  ${
                            checkFormInputError('password') === true ? 'text-red-700' : 'text-neutral-700'
                        }`}
                    >
                        {translation('password')}
                    </label>
                    {/* <a href="#" className={'float-right text-sm text-primary no-underline hover:underline'}>
                        Forget Password?
                    </a> */}
                </div>
                <InputText
                    type={'password'}
                    className={'pl-1.5 mt-2'}
                    name={'password'}
                    placeholder={translation('password')}
                    onChange={loginForm.handleChange}
                    error={checkFormInputError('password')}
                />
                {getFormErrorMessage('password')}
            </div>
            {/* Inizio modifica Reset Password - Simona 25/05/2022 */}
            <div className={'text-right mb-8'}>
                <p>
                    <button
                        type={'button'}
                        className={'underline font-semibold ml-1 text-sm'}
                        onClick={() => history.push('/reset-password')}
                    >
                        {translation('resetPassword')}
                    </button>
                </p>
            </div>
            {/* Fine - Simona 25/05/2022 */}
            {loading ? (
                <div className={'flex items-center justify-center'}>
                    <LoadingSpinner width={'42px'} height={'42px'} />
                </div>
            ) : (
                <div className={'flex items-center justify-center'}>
                    <PrimaryButton type={'submit'} className={'w-32 mr-3 py-2!'} text={translation('signIn')} />
                    {!!showOtherBtn === true && (
                        <SecondaryButton
                            type={'button'}
                            className={'py-2!'}
                            text={labelOtherBtn}
                            onClickHandler={onClickOtherBtnHandler}
                        />
                    )}
                </div>
            )}
        </form>
    )
}

// this map the states to our props in this funtional component
const mapStateToProps = (state: StateReducerType) => ({
    user: state.user,
    UI: state.UI,
})

// this map actions to our props in this functional component
const mapActionsToProps = {
    loginUser,
}

export default connect(mapStateToProps, mapActionsToProps)(LoginForm)
