import { DesignIconsObjectType } from 'types/DesignIconsObjectType'
import { ReactComponent as BrushAltIcon } from './brush-alt.svg'
import { ReactComponent as BrushIcon } from './brush.svg'
import { ReactComponent as CropIcon } from './crop.svg'
import { ReactComponent as GridAltIcon } from './grid-alt.svg'
import { ReactComponent as GridIcon } from './grid.svg'
import { ReactComponent as HighlightAltIcon } from './highlight-alt.svg'
import { ReactComponent as HighlightIcon } from './highlight.svg'
import { ReactComponent as LayersIcon } from './layers.svg'
import { ReactComponent as LayoutIcon } from './layout.svg'
import { ReactComponent as MoveIcon } from './move.svg'
import { ReactComponent as PalletIcon } from './pallet.svg'
import { ReactComponent as SelectIcon } from './select.svg'
import { ReactComponent as SliceIcon } from './slice.svg'
import { ReactComponent as UxIcon } from './ux.svg'
import { ReactComponent as VectorIcon } from './vector.svg'

const DesignIcons: DesignIconsObjectType = {
    'brush-alt': BrushAltIcon,
    brush: BrushIcon,
    crop: CropIcon,
    'grid-alt': GridAltIcon,
    grid: GridIcon,
    'highlight-alt': HighlightAltIcon,
    highlight: HighlightIcon,
    layers: LayersIcon,
    layout: LayoutIcon,
    move: MoveIcon,
    pallet: PalletIcon,
    select: SelectIcon,
    slice: SliceIcon,
    ux: UxIcon,
    vector: VectorIcon,
}

export default DesignIcons
