import { FoodAndRestaurantIconsObjectType } from 'types/FoodAndRestaurantIconsObjectType'
import { ReactComponent as BurgerIcon } from './burger.svg'
import { ReactComponent as ChefHatIcon } from './chef-hat.svg'
import { ReactComponent as CoffeeCupIcon } from './coffee-cup.svg'
import { ReactComponent as DinnerIcon } from './dinner.svg'
import { ReactComponent as FreshJuiceIcon } from './fresh-juice.svg'
import { ReactComponent as JuiceIcon } from './juice.svg'
import { ReactComponent as PizzaIcon } from './pizza.svg'
import { ReactComponent as RestaurantIcon } from './restaurant.svg'
import { ReactComponent as ServiceIcon } from './service.svg'

const FoodAndRestaurantIcons: FoodAndRestaurantIconsObjectType = {
    burger: BurgerIcon,
    'chef-hat': ChefHatIcon,
    'coffee-cup': CoffeeCupIcon,
    dinner: DinnerIcon,
    'fresh-juice': FreshJuiceIcon,
    juice: JuiceIcon,
    pizza: PizzaIcon,
    restaurant: RestaurantIcon,
    service: ServiceIcon,
}

export default FoodAndRestaurantIcons
