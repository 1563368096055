import { TextEditorIconsObjectType } from 'types/TextEditorIconsObjectType'
import { ReactComponent as BoldIcon } from './bold.svg'
import { ReactComponent as IndentDecreaseIcon } from './indent-decrease.svg'
import { ReactComponent as IndentIncreaseIcon } from './indent-increase.svg'
import { ReactComponent as ItalicIcon } from './italic.svg'
import { ReactComponent as LineDashedIcon } from './line-dashed.svg'
import { ReactComponent as LineDottedIcon } from './line-dotted.svg'
import { ReactComponent as LineDoubleIcon } from './line-double.svg'
import { ReactComponent as LineSpacingIcon } from './line-spacing.svg'
import { ReactComponent as PageBreakIcon } from './page-break.svg'
import { ReactComponent as PaginationIcon } from './pagination.svg'
import { ReactComponent as PilcrowIcon } from './pilcrow.svg'
import { ReactComponent as SortAlphaAscIcon } from './sort-alpha-asc.svg'
import { ReactComponent as SortAmountAscIcon } from './sort-amount-asc.svg'
import { ReactComponent as SortAmountDscIcon } from './sort-amount-dsc.svg'
import { ReactComponent as SpellcheckIcon } from './spellcheck.svg'
import { ReactComponent as StrikethroughIcon } from './strikethrough.svg'
import { ReactComponent as TextAlignCenterIcon } from './text-align-center.svg'
import { ReactComponent as TextAlignJustifyIcon } from './text-align-justify.svg'
import { ReactComponent as TextAlignReftIcon } from './text-align-left.svg'
import { ReactComponent as TextAlignRightIcon } from './text-align-right.svg'
import { ReactComponent as TextFormatRemoveIcon } from './text-format-remove.svg'
import { ReactComponent as TextFormatIcon } from './text-format.svg'
import { ReactComponent as UnderlineIcon } from './underline.svg'

const TextEditorIcons: TextEditorIconsObjectType = {
    bold: BoldIcon,
    'indent-decrease': IndentDecreaseIcon,
    'indent-increase': IndentIncreaseIcon,
    italic: ItalicIcon,
    'line-dashed': LineDashedIcon,
    'line-dotted': LineDottedIcon,
    'line-double': LineDoubleIcon,
    'line-spacing': LineSpacingIcon,
    'page-break': PageBreakIcon,
    pagination: PaginationIcon,
    pilcrow: PilcrowIcon,
    'sort-alpha-asc': SortAlphaAscIcon,
    'sort-amount-asc': SortAmountAscIcon,
    'sort-amount-dsc': SortAmountDscIcon,
    spellcheck: SpellcheckIcon,
    strikethrough: StrikethroughIcon,
    'text-align-center': TextAlignCenterIcon,
    'text-align-justify': TextAlignJustifyIcon,
    'text-align-left': TextAlignReftIcon,
    'text-align-right': TextAlignRightIcon,
    'text-format-remove': TextFormatRemoveIcon,
    'text-format': TextFormatIcon,
    underline: UnderlineIcon,
}

export default TextEditorIcons
