import { Switch, Route } from 'react-router-dom'
import { TFunction, Namespace } from 'react-i18next'
import { i18n } from 'i18next'
import { ROLE } from 'enum/roles'
import { FlagsIconsKeysType } from 'types/FlagsIconsKeysType'
import { ConfirmResetPswRoute } from 'interfaces/ConfirmResetPswRoute.interfaces'
import AuthRoute from 'components/AuthRoute'
import PrivateRoute from 'components/PrivateRoute'
import ComingSoon from 'pages/ComingSoon'
import Login from 'pages/Login'
import Login2FA from 'pages/Login2FA'
import Signup from 'pages/Signup'
import Home from 'pages/Home'
import AdminDashboard from 'pages/AdminDashboard'
import UserDashboard from 'pages/UserDashboard'
import MyAccount from 'pages/MyAccount'
import AccessDenied from 'pages/AccessDenied'
import NotFound from 'pages/NotFound'
import ResetPassword from 'pages/ResetPassword'
import ConfirmResetPsw from 'pages/ConfirmResetPsw'
import * as Config from 'utils/configUtils'

interface AppRouterPros {
    t: TFunction<Namespace, undefined>
    i18n: i18n
    languages: Array<FlagsIconsKeysType>
}

const AppRouter = ({ t, i18n, languages }: AppRouterPros) => {
    return Config.COMING_SOON_ENABLED === true ? (
        <Switch>
            <Route
                exact
                path={'/'}
                component={() => <ComingSoon translation={t} i18n={i18n} languages={languages} />}
            />
            <Route path="*" component={() => <NotFound translation={t} i18n={i18n} languages={languages} />} />
        </Switch>
    ) : (
        <Switch>
            <Route exact path={'/'} component={() => <Home translation={t} i18n={i18n} languages={languages} />} />
            <AuthRoute
                path={'/sign-in'}
                component={() => <Login translation={t} i18n={i18n} languages={languages} />}
            />
            {Config.AUTH_2FA === true && (
                <AuthRoute
                    path={'/verify-login'}
                    component={() => <Login2FA translation={t} i18n={i18n} languages={languages} />}
                />
            )}
            {Config.AUTH_REGISTRATION === true && (
                <AuthRoute
                    path={'/sign-up'}
                    component={() => <Signup translation={t} i18n={i18n} languages={languages} />}
                />
            )}
            <AuthRoute
                path={'/reset-password'}
                component={() => <ResetPassword translation={t} i18n={i18n} languages={languages} />}
            />
            <AuthRoute
                path={'/confirm-reset-pws/:guid'}
                component={(props: ConfirmResetPswRoute) => (
                    <ConfirmResetPsw translation={t} i18n={i18n} languages={languages} guid={props.match.params.guid} />
                )}
            />
            <PrivateRoute
                exact
                path={'/admin'}
                component={() => <AdminDashboard translation={t} i18n={i18n} languages={languages} />}
                roles={[ROLE.ADMINISTRATOR]}
            />
            <PrivateRoute
                exact
                path={'/dashboard'}
                component={() => <UserDashboard translation={t} i18n={i18n} languages={languages} />}
                roles={[ROLE.USER]}
            />
            <PrivateRoute
                exact
                path={'/my-account'}
                component={() => <MyAccount translation={t} i18n={i18n} languages={languages} />}
                roles={[ROLE.USER]}
            />
            <PrivateRoute
                path={'/access-denied'}
                component={() => <AccessDenied translation={t} i18n={i18n} languages={languages} />}
            />
            <Route path="*" component={() => <NotFound translation={t} i18n={i18n} languages={languages} />} />
        </Switch>
    )
}

export default AppRouter
