import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'
import Login2FAForm from 'components/Login2FAForm'
import AuthPage from 'components/AuthPage'

const Login2FA = ({ translation }: BaseAppRoute) => {
    return (
        <AuthPage translation={translation}>
            <p className={'text-center text-3xl mb-2'}>{translation('enterOTPCode')}</p>
            <Login2FAForm translation={translation} />
        </AuthPage>
    )
}

export default Login2FA
