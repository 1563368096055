import axios from 'axios'
import { ROLE } from 'enum/roles'
import { DataRegistrationUser } from 'interfaces/DataRegistrationUser.interfaces'
import { RegistrationData } from 'interfaces/RegistrationData.interfaces'
import BaseServices from './BaseServices'

class AuthServices extends BaseServices {
    login(username: string, password: string) {
        return axios
            .post(`${this.getBaseApiUrl()}auth/authenticate`, { data: { username, password } })
            .then((response) => {
                if (
                    response.data !== null &&
                    response.data.data !== null &&
                    response.data.status === 'Success' &&
                    response.data.data.user != null &&
                    response.data.data.token != null
                ) {
                    localStorage.setItem('user', JSON.stringify(response.data.data))
                }

                return response.data
            })
    }

    login2FA(username: string, password: string) {
        return axios
            .post(`${this.getBaseApiUrl()}auth/authenticate/mfa`, { data: { username, password } })
            .then((response) => {
                return response
            })
    }

    login2FAVerify(username: string, password: string, code: string) {
        return axios
            .post(`${this.getBaseApiUrl()}auth/authenticate/mfa/verify`, { data: { username, password, code } })
            .then((response) => {
                if (
                    response.data != null &&
                    response.data.data != null &&
                    response.data.status === 'Success' &&
                    response.data.data.user != null &&
                    response.data.data.token != null
                ) {
                    localStorage.setItem('user', JSON.stringify(response.data.data))
                }

                return response.data
            })
    }

    logout() {
        localStorage.removeItem('user')
    }

    register(data: DataRegistrationUser) {
        return axios
            .post(`${this.getBaseApiUrl()}users/register`, { data: this.setRegistrationData(data) })
            .then((response) => {
                return response
            })
    }

    setRegistrationData(data: DataRegistrationUser): RegistrationData {
        return {
            user: {
                name: data.name,
                surname: data.surname,
                username: data.username,
                password: data.password,
                role: ROLE.USER,
            },
        }
    }
}

export default new AuthServices()
