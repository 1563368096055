import { ReactNode } from 'react'
import { BaseComponentChangeableLanguage } from 'interfaces/BaseComponentChangeableLanguage.interfaces'
import PublicNavbar from 'components/PublicNavbar'
import PublicFooter from 'components/PublicFooter'
import ScrollToTop from 'components/ScrollToTop'

interface LandingPageProps extends BaseComponentChangeableLanguage {
    children: JSX.Element | React.ReactChild | ReactNode | React.FC
    className?: string
}

const LandingPage = ({ children, className, translation, i18n, languages }: LandingPageProps) => {
    return (
        <div className={`flex flex-col min-h-screen overflow-hidden ${className ?? ''}`}>
            <PublicNavbar translation={translation} i18n={i18n} languages={languages} />
            <main className={'flex-grow'}>{children}</main>
            <PublicFooter translation={translation} />
            <ScrollToTop />
        </div>
    )
}

export default LandingPage
