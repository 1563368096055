import { useHistory } from 'react-router-dom'
import * as Config from 'utils/configUtils'
import LoginForm from 'components/LoginForm'
import AuthPage from 'components/AuthPage'
import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'

const Login = ({ translation }: BaseAppRoute) => {
    const history = useHistory()
    return (
        <AuthPage translation={translation} showRegisterBtn={Config.AUTH_REGISTRATION}>
            <p className={'text-center text-lg mb-0'}>{translation('subtitleLogin')}</p>
            <p className={'text-center text-3xl mb-2'}>{translation('titleLogin')}</p>
            <LoginForm
                translation={translation}
                auth2FAEnabled={Config.AUTH_2FA}
                showOtherBtn={true}
                labelOtherBtn={translation('backHome')}
                onClickOtherBtnHandler={() => history.push('/')}
            />
        </AuthPage>
    )
}

export default Login
