import MenuItem from 'components/MenuItem'
import { BaseComponent } from 'interfaces/BaseComponent.interfaces'
// import DarkModeToggle from 'components/DarkModeToggle'
import { MenuElement } from 'types/MenuElement'

interface MenuProps extends BaseComponent {
    menuItems: MenuElement[]
    className?: string
    activeItem?: string
}

const Menu = ({ menuItems, className, activeItem, translation }: MenuProps) => {
    return (
        <ul className={`space-y-2 pb-2 ${className ?? ''}`}>
            {menuItems.map((menuItem: MenuElement) => {
                return (
                    <MenuItem
                        key={menuItem.code}
                        code={menuItem.code}
                        href={menuItem.href}
                        label={menuItem.label}
                        external={menuItem.external}
                        divider={menuItem.divider}
                        subItems={menuItem.subItems}
                        hasIcon={menuItem.hasIcon}
                        icon={menuItem?.icon}
                        activeItem={activeItem}
                        onClickHandler={menuItem.onClickHandler}
                        translation={translation}
                    />
                )
            })}
        </ul>
        // <div className={'max-w-full mx-auto px-2 sm:px-6 lg:px-8 hidden sm:block'}>
        //     <div className={'relative flex items-center justify-between h-16'}>
        //         <div className={'flex-1 flex justify-center sm:justify-start items-center'}>
        //             <MenuLogo />
        //             {menuItems.map((menuItem) => {
        //                 return (
        //                     <MenuItem
        //                         key={menuItem.code}
        //                         code={menuItem.code}
        //                         href={menuItem.href}
        //                         text={menuItem.label}
        //                         external={menuItem.external}
        //                         divider={menuItem.divider}
        //                     />
        //                 )
        //             })}
        //         </div>
        //         <div
        //             className={
        //                 'absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'
        //             }
        //         >
        //             <div className={'hidden sm:block sm:ml-3'}>
        //                 <DarkModeToggle />
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Menu
