import { useEffect, useState } from 'react'
import SvgIcon from 'components/SvgIcon'

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        setVisible(document.documentElement.scrollTop > 300 ? true : false)
    }

    const scrollToTopHandler = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    return (
        <button
            type={'button'}
            className={`${
                visible === true ? 'flex' : 'hidden'
            }  items-center justify-center fixed right-8 bottom-8 rounded-md text-lg text-white bg-gray-900 fill-current z-20 transition-all duration-300 w-11 h-11`}
            onClick={scrollToTopHandler}
        >
            <SvgIcon className={'w-5'} folder={'heroicons'} name={'arrow-narrow-up'} />
        </button>
    )
}

export default ScrollToTop
