import axios from 'axios'
import BaseServices from './BaseServices'
import { DataConfirmResetPsw } from 'interfaces/DataConfirmResetPsw.interfaces'

class ResetPasswordServices extends BaseServices {
    resetPasswordRequest(username: string) {
        return axios
            .post(`${this.getBaseApiUrl()}resetpassword/reset/request`, { data: { username } })
            .then((response) => {
                return response
            })
    }

    resetPasswordNewEmail(username: string) {
        return axios
            .post(`${this.getBaseApiUrl()}resetpassword/reset/newemail`, { data: { username } })
            .then((response) => {
                return response
            })
    }

    verifyGuid(resetGuid: string) {
        return axios.get(`${this.getBaseApiUrl()}resetpassword/reset/resetguid/verify/${resetGuid}`, {
            headers: this.getAuthHeader(),
        })
    }

    confirmResetPassword(values: DataConfirmResetPsw) {
        return axios
            .post(`${this.getBaseApiUrl()}resetpassword/reset/confirmreset`, {
                data: this.setConfirmResetPassword(values),
            })
            .then((response) => {
                return response
            })
    }

    setConfirmResetPassword(data: DataConfirmResetPsw): DataConfirmResetPsw {
        return {
            username: data.username,
            newPassword: data.newPassword,
            resetPwdGuid: data.resetPwdGuid,
        }
    }
}

export default new ResetPasswordServices()
