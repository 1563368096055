import React, { FC, InputHTMLAttributes } from 'react'

interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    className?: string
    error?: boolean
}

const InputText: FC<InputTextProps> = ({ name, className, error = false, ...rest }) => {
    return (
        <input
            name={name}
            className={`w-full box-border rounded-lg border-2 text-base ${
                error === true
                    ? 'bg-red-50 border-red-500 text-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100'
                    : 'border-genericInput outline-none focus:border-primary'
            } ${className ?? 'px-2 py-2'} ${
                rest.disabled === true ? 'bg-gray-100 border-genericInput text-gray-900' : ''
            } `}
            {...rest}
        />
    )
}

export default InputText
