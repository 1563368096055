import { connect } from 'react-redux'
import { StateReducerType } from 'types/StateReducerType'
import { BaseSessionApp } from 'interfaces/BaseSessionApp.interfaces'
import Page from 'components/Page'

const AdminDashboard = ({ translation, i18n, languages }: BaseSessionApp) => {
    return (
        <Page
            fullWidth={true}
            searchIsEnabled={false}
            className={'overflow-x-hidden'}
            translation={translation}
            i18n={i18n}
            languages={languages}
        >
            <div className={'pt-6 px-4'}>Admin dashboard</div>
        </Page>
    )
}

const mapStateToProps = (state: StateReducerType) => ({
    user: state.user,
    UI: state.UI,
})

export default connect(mapStateToProps)(AdminDashboard)
