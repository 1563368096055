import { Toast } from 'react-hot-toast'
import { Transition } from '@headlessui/react'
import { SeverityType } from 'types/SeverityType'
import { IconType } from 'types/IconType'
import ToastIcons from 'resources/images/toast'

interface ToastProps {
    t: Toast
    severity?: SeverityType
    summary?: React.ReactNode // title toast
    message?: React.ReactNode // message toast
    content?: React.ReactNode // custom content toast
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToastNotification = ({ t, severity = 'info', summary, message, content }: ToastProps) => {
    const setBgToastBySeverity = (): string => {
        switch (severity) {
            case 'info':
                return 'bg-blue-400'
            case 'error':
                return 'bg-red-400'
            case 'success':
                return 'bg-green-400'
            case 'warn':
                return 'bg-yellow-400'
            default:
                return 'bg-blue-400'
        }
    }

    const getIconBySeverity = (): IconType => {
        return ToastIcons[severity]
    }

    const IconToast: IconType = getIconBySeverity()

    return (
        <Transition
            appear
            show={t.visible}
            enter={'transition ease duration-300 transform'}
            enterFrom={'opacity-0 -translate-y-full'}
            enterTo={'opacity-100 translate-y-0'}
            leave={'transition ease duration-300 transform'}
            leaveFrom={'opacity-100 translate-y-0'}
            leaveTo={'opacity-0 -translate-y-full'}
        >
            <div
                className={`flex items-center text-white max-w-sm w-full ${setBgToastBySeverity()} shadow-md rounded-lg overflow-hidden mx-auto`}
            >
                {!!content ? (
                    { content }
                ) : (
                    <>
                        <div className={'w-10 border-r px-2'}>
                            <IconToast className={'w-6 h-6'} />
                        </div>
                        <div className={'flex items-center px-2 py-3'}>
                            <div className={'mx-3'}>
                                <p>{message}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Transition>
    )
}

export default ToastNotification
