import { AxiosRequestHeaders } from 'axios'
import { BasePaginationList } from 'interfaces/BasePaginationList.interfaces'

class BaseServices {
    getBaseApiUrl() {
        return process.env.REACT_APP_API_URL
    }

    getAuthHeader(): AxiosRequestHeaders {
        const user = JSON.parse(localStorage.getItem('user') ?? '{}')
        if (!!user && user !== '' && user.token) {
            return { Authorization: user.token }
        } else {
            return {}
        }
    }

    getDataPaginationList(items = 8, page = 0, search = ''): BasePaginationList {
        return {
            items,
            page,
            search,
        }
    }
}

export default BaseServices
