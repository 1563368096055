import { DirectionIconsObjectType } from 'types/DirectionIconsObjectType'
import { ReactComponent as AngleDoubleDownIcon } from './angle-double-down.svg'
import { ReactComponent as AngleDoubleLeftIcon } from './angle-double-left.svg'
import { ReactComponent as AngleDoubleRightIcon } from './angle-double-right.svg'
import { ReactComponent as AngleDoubleUpIcon } from './angle-double-up.svg'
import { ReactComponent as ArrowDownCircleIcon } from './arrow-down-circle.svg'
import { ReactComponent as ArrowDownIcon } from './arrow-down.svg'
import { ReactComponent as ArrowLeftCircleIcon } from './arrow-left-circle.svg'
import { ReactComponent as ArrowLeftIcon } from './arrow-left.svg'
import { ReactComponent as ArrowRightCircleIcon } from './arrow-right-circle.svg'
import { ReactComponent as ArrowRightIcon } from './arrow-right.svg'
import { ReactComponent as ArrowTopLeftIcon } from './arrow-top-left.svg'
import { ReactComponent as ArrowTopRighIcon } from './arrow-top-right.svg'
import { ReactComponent as ArrowUpCircleIcon } from './arrow-up-circle.svg'
import { ReactComponent as ArrowUpIcon } from './arrow-up.svg'
import { ReactComponent as ArrowsHorizontalIcon } from './arrows-horizontal.svg'
import { ReactComponent as ArrowsVerticalIcon } from './arrows-vertical.svg'
import { ReactComponent as ChevronDownCircleIcon } from './chevron-down-circle.svg'
import { ReactComponent as ChevronDownIcon } from './chevron-down.svg'
import { ReactComponent as ChevronLeftCircleIcon } from './chevron-left-circle.svg'
import { ReactComponent as ChevronLeftIcon } from './chevron-left.svg'
import { ReactComponent as ChevronRightCircleIcon } from './chevron-right-circle.svg'
import { ReactComponent as ChevronRightIcon } from './chevron-right.svg'
import { ReactComponent as ChevronUpCircleIcon } from './chevron-up-circle.svg'
import { ReactComponent as ChevronUpIcon } from './chevron-up.svg'
import { ReactComponent as DirectionAltIcon } from './direction-alt.svg'
import { ReactComponent as DirectionLtrIcon } from './direction-ltr.svg'
import { ReactComponent as DirectionRtlIcon } from './direction-rtl.svg'
import { ReactComponent as DirectionIcon } from './direction.svg'
import { ReactComponent as EnterIcon } from './enter.svg'
import { ReactComponent as ExitDownIcon } from './exit-down.svg'
import { ReactComponent as ExitUpIcon } from './exit-up.svg'
import { ReactComponent as ExitIcon } from './exit.svg'
import { ReactComponent as PointerDownIcon } from './pointer-down.svg'
import { ReactComponent as PointerLeftIcon } from './pointer-left.svg'
import { ReactComponent as PointerRightIcon } from './pointer-right.svg'
import { ReactComponent as PointerTopIcon } from './pointer-top.svg'
import { ReactComponent as ShiftLefIcon } from './shift-left.svg'
import { ReactComponent as ShiftRightIcon } from './shift-right.svg'

const DirectionIcons: DirectionIconsObjectType = {
    'angle-double-down': AngleDoubleDownIcon,
    'angle-double-left': AngleDoubleLeftIcon,
    'angle-double-right': AngleDoubleRightIcon,
    'angle-double-up': AngleDoubleUpIcon,
    'arrow-down-circle': ArrowDownCircleIcon,
    'arrow-down': ArrowDownIcon,
    'arrow-left-circle': ArrowLeftCircleIcon,
    'arrow-left': ArrowLeftIcon,
    'arrow-right-circle': ArrowRightCircleIcon,
    'arrow-right': ArrowRightIcon,
    'arrow-top-left': ArrowTopLeftIcon,
    'arrow-top-right': ArrowTopRighIcon,
    'arrow-up-circle': ArrowUpCircleIcon,
    'arrow-up': ArrowUpIcon,
    'arrows-horizontal': ArrowsHorizontalIcon,
    'arrows-vertical': ArrowsVerticalIcon,
    'chevron-down-circle': ChevronDownCircleIcon,
    'chevron-down': ChevronDownIcon,
    'chevron-left-circle': ChevronLeftCircleIcon,
    'chevron-left': ChevronLeftIcon,
    'chevron-right-circle': ChevronRightCircleIcon,
    'chevron-right': ChevronRightIcon,
    'chevron-up-circle': ChevronUpCircleIcon,
    'chevron-up': ChevronUpIcon,
    'direction-alt': DirectionAltIcon,
    'direction-ltr': DirectionLtrIcon,
    'direction-rtl': DirectionRtlIcon,
    direction: DirectionIcon,
    enter: EnterIcon,
    'exit-down': ExitDownIcon,
    'exit-up': ExitUpIcon,
    exit: ExitIcon,
    'pointer-down': PointerDownIcon,
    'pointer-left': PointerLeftIcon,
    'pointer-right': PointerRightIcon,
    'pointer-top': PointerTopIcon,
    'shift-left': ShiftLefIcon,
    'shift-right': ShiftRightIcon,
}

export default DirectionIcons
