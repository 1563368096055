import { NatureIconsObjectType } from 'types/NatureIconsObjectType'
import { ReactComponent as BugIcon } from './bug.svg'
import { ReactComponent as FlowerIcon } from './flower.svg'
import { ReactComponent as IslandIcon } from './island.svg'
import { ReactComponent as LeafIcon } from './leaf.svg'
import { ReactComponent as MashroomIcon } from './mashroom.svg'
import { ReactComponent as SproutIcon } from './sprout.svg'
import { ReactComponent as TreeIcon } from './tree.svg'
import { ReactComponent as TreesIcon } from './trees.svg'

const NatureIcons: NatureIconsObjectType = {
    bug: BugIcon,
    flower: FlowerIcon,
    island: IslandIcon,
    leaf: LeafIcon,
    mashroom: MashroomIcon,
    sprout: SproutIcon,
    tree: TreeIcon,
    trees: TreesIcon,
}

export default NatureIcons
