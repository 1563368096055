import { CurrencyIconsObjectType } from 'types/CurrencyIconsObjectType'
import { ReactComponent as CalculatorIcon } from './calculator.svg'
import { ReactComponent as CoinIcon } from './coin.svg'
import { ReactComponent as CreditCardsIcon } from './credit-cards.svg'
import { ReactComponent as DiamondAltIcon } from './diamond-alt.svg'
import { ReactComponent as DiamondIcon } from './diamond.svg'
import { ReactComponent as DollarIcon } from './dollar.svg'
import { ReactComponent as EuroIcon } from './euro.svg'
import { ReactComponent as MoneyLocationIcon } from './money-location.svg'
import { ReactComponent as MoneyProtectionIcon } from './money-protection.svg'
import { ReactComponent as PoundIcon } from './pound.svg'
import { ReactComponent as RupeeIcon } from './rupee.svg'
import { ReactComponent as WalletIcon } from './wallet.svg'
import { ReactComponent as YenIcon } from './yen.svg'

const CurrencyIcons: CurrencyIconsObjectType = {
    calculator: CalculatorIcon,
    coin: CoinIcon,
    'credit-cards': CreditCardsIcon,
    'diamond-alt': DiamondAltIcon,
    diamond: DiamondIcon,
    dollar: DollarIcon,
    euro: EuroIcon,
    'money-location': MoneyLocationIcon,
    'money-protection': MoneyProtectionIcon,
    pound: PoundIcon,
    rupee: RupeeIcon,
    wallet: WalletIcon,
    yen: YenIcon,
}

export default CurrencyIcons
