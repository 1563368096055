import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Transition } from '@headlessui/react'
import { useIsTablet } from 'hooks/useDeviceDetect'
import { BaseComponentChangeableLanguage } from 'interfaces/BaseComponentChangeableLanguage.interfaces'
import LogoImage from 'resources/images/logo/logo.png'
import PrimaryButton from 'components/PrimaryButton'
import Button from 'components/Button'
import LanguageSelector from 'components/LanguageSelector'
import SvgIcon from 'components/SvgIcon'

type AnchorNameType = 'intro' | 'target' | 'characteristics' | 'about-us' | 'educational' | 'faq' | 'contact-us'

const PublicNavbar = ({ translation, i18n, languages }: BaseComponentChangeableLanguage) => {
    const history = useHistory()
    const [top, setTop] = useState(true)
    const [showMobileNav, setShowMobileNav] = useState(false)
    const isTablet = useIsTablet()

    const scrollWithOffset = (el: HTMLElement, to: AnchorNameType) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
        let yOffset = 0

        switch (to) {
            case 'intro':
                yOffset = isTablet === true ? -40 : -80
                break
            case 'target':
                yOffset = isTablet === true ? -40 : -80
                break
            case 'characteristics':
                yOffset = isTablet === true ? -40 : -80
                break
            case 'about-us':
                yOffset = isTablet === true ? -100 : -120
                break
            case 'educational':
                yOffset = -100
                break
            case 'faq':
                yOffset = -100
                break
            case 'contact-us':
                yOffset = -80
                break
            default:
                yOffset = isTablet === true ? -40 : -80
        }

        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
    }

    useEffect(() => {
        const scrollHandler = () => {
            setTop(window.pageYOffset > 10 ? false : true)
        }
        window.addEventListener('scroll', scrollHandler)
        return () => window.removeEventListener('scroll', scrollHandler)
    }, [top])

    useEffect(() => {
        if (isTablet === false) {
            setShowMobileNav(false)
        }
    }, [isTablet])

    return (
        <nav
            className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
                !top && 'bg-white backdrop-blur-sm shadow-lg'
            }`}
        >
            <div className={'max-w-7xl mx-auto px-4 sm:px-6'}>
                <div className={'flex items-center justify-between h-16 md:h-20'}>
                    {/* Site branding */}
                    <div className={'flex flex-1 justify-start items-center'}>
                        <HashLink
                            className={'flex w-64 items-center border-b-0 tracking-wide transition duration-300'}
                            smooth
                            to={'/#welcome-ethicmind'}
                            onClick={() => setShowMobileNav(false)}
                        >
                            <img
                                src={LogoImage}
                                className={'w-14 h-auto sm:w-auto sm:h-12 md:h-16 mr-4'}
                                alt={'EthicMind'}
                            />
                            <span className={'text-2xl sm:text-3xl text-primary font-black'}>EthicMind</span>
                        </HashLink>
                    </div>
                    {/* Site navigation */}
                    <nav className={'hidden xl:flex flex-grow'}>
                        <ul className={'flex flex-grow justify-end flex-wrap items-center'}>
                            <li>
                                <HashLink
                                    className={
                                        'flex items-center px-3 py-2 lg:px-2 xl:px-3 2xl:px-3 lg:text-sm! xl:text-base! text-gray-500 hover:text-primary transition-colors'
                                    }
                                    smooth
                                    to={'/#intro'}
                                    scroll={(el: HTMLElement) => scrollWithOffset(el, 'intro')}
                                >
                                    {translation('nav1stLink')}
                                </HashLink>
                            </li>
                            <li>
                                <HashLink
                                    className={
                                        'flex items-center px-3 py-2 lg:px-2 xl:px-3 2xl:px-3 lg:text-sm! xl:text-base! text-gray-500 hover:text-primary transition-colors'
                                    }
                                    smooth
                                    to={'/#target'}
                                    scroll={(el: HTMLElement) => scrollWithOffset(el, 'target')}
                                >
                                    {translation('nav2ndLink')}
                                </HashLink>
                            </li>
                            <li>
                                <HashLink
                                    className={
                                        'flex items-center px-3 py-2 lg:px-2 xl:px-3 2xl:px-3 lg:text-sm! xl:text-base! text-gray-500 hover:text-primary transition-colors'
                                    }
                                    smooth
                                    to={'/#characteristics'}
                                    scroll={(el: HTMLElement) => scrollWithOffset(el, 'characteristics')}
                                >
                                    {translation('nav3rdLink')}
                                </HashLink>
                            </li>
                            <li>
                                <HashLink
                                    className={
                                        'flex items-center px-3 py-2 lg:px-2 xl:px-3 2xl:px-3 lg:text-sm! xl:text-base! text-gray-500 hover:text-primary transition-colors'
                                    }
                                    smooth
                                    to={'/#about-us'}
                                    scroll={(el: HTMLElement) => scrollWithOffset(el, 'about-us')}
                                >
                                    {translation('nav4thLink')}
                                </HashLink>
                            </li>
                            <li>
                                <HashLink
                                    className={
                                        'flex items-center px-3 py-2 lg:px-2 xl:px-3 2xl:px-3 lg:text-sm! xl:text-base! text-gray-500 hover:text-primary transition-colors'
                                    }
                                    smooth
                                    to={'/#educational'}
                                    scroll={(el: HTMLElement) => scrollWithOffset(el, 'educational')}
                                >
                                    {translation('nav5thLink')}
                                </HashLink>
                            </li>
                            <li>
                                <HashLink
                                    className={
                                        'flex items-center px-3 py-2 lg:px-2 xl:px-3 2xl:px-3 lg:text-sm! xl:text-base! text-gray-500 hover:text-primary transition-colors'
                                    }
                                    smooth
                                    to={'/#faq'}
                                    scroll={(el: HTMLElement) => scrollWithOffset(el, 'faq')}
                                >
                                    {translation('nav6thLink')}
                                </HashLink>
                            </li>
                            <li>
                                <HashLink
                                    className={
                                        'flex items-center px-3 py-2 lg:px-2 xl:px-3 2xl:px-3 lg:text-sm! xl:text-base! text-gray-500 hover:text-primary transition-colors'
                                    }
                                    smooth
                                    to={'/#contact-us'}
                                    scroll={(el: HTMLElement) => scrollWithOffset(el, 'contact-us')}
                                >
                                    {translation('nav7thLink')}
                                </HashLink>
                            </li>
                        </ul>
                        <ul className={'flex flex-grow justify-end flex-wrap items-center'}>
                            <li>
                                <Button
                                    className={
                                        'text-primary! text-base! lg:text-sm! xl:text-lg! font-black! hover:text-gray-900! py-2! px-4! flex! items-center! transition! duration-150! ease-in-out!'
                                    }
                                    text={translation('signIn')}
                                    onClickHandler={() => history.push('/sign-in')}
                                />
                            </li>
                            <li>
                                <PrimaryButton
                                    className={
                                        'inline-flex! items-center! justify-center! text-base! lg:text-sm! xl:text-lg! font-black! leading-snug! transition-all! py-2! px-4! ml-3!'
                                    }
                                    text={
                                        <React.Fragment>
                                            <span>{translation('signUp')}</span>
                                        </React.Fragment>
                                    }
                                    onClickHandler={() => history.push('/sign-up')}
                                />
                            </li>
                            <li>
                                <LanguageSelector
                                    translation={translation}
                                    i18n={i18n}
                                    languages={languages}
                                    styleList={'w-16!'}
                                    size={'w-6 h-4'}
                                    sizeSelected={'w-6 h-4'}
                                />
                            </li>
                        </ul>
                    </nav>
                    <div className={'flex xl:hidden'}>
                        <button onClick={() => setShowMobileNav(!showMobileNav)}>
                            <SvgIcon
                                className={'w-8 h-8 transition-all duration-300'}
                                folder={'heroicons'}
                                name={showMobileNav === true ? 'x' : 'menu'}
                            />
                        </button>
                        <Transition
                            show={showMobileNav}
                            enter={'transition-opacity duration-75'}
                            enterFrom={'opacity-0'}
                            enterTo={'opacity-100'}
                            leave={'transition-opacity duration-150'}
                            leaveFrom={'opacity-100'}
                            leaveTo={'opacity-0'}
                        >
                            <nav
                                className={`absolute left-0 top-full z-20 h-screen w-full overflow-scroll bg-white pb-16`}
                            >
                                <ul className={'px-5 py-2'}>
                                    <li>
                                        <HashLink
                                            className={
                                                'flex justify-center items-center py-2 text-xl text-gray-500 hover:text-primary'
                                            }
                                            smooth
                                            to={'/#intro'}
                                            scroll={(el: HTMLElement) => scrollWithOffset(el, 'intro')}
                                            onClick={() => setShowMobileNav(false)}
                                        >
                                            {translation('nav1stLink')}
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink
                                            className={
                                                'flex justify-center items-center py-2 text-xl text-gray-500 hover:text-primary'
                                            }
                                            smooth
                                            to={'/#target'}
                                            scroll={(el: HTMLElement) => scrollWithOffset(el, 'target')}
                                            onClick={() => setShowMobileNav(false)}
                                        >
                                            {translation('nav2ndLink')}
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink
                                            className={
                                                'flex justify-center items-center py-2 text-xl text-gray-500 hover:text-primary'
                                            }
                                            smooth
                                            to={'/#characteristics'}
                                            scroll={(el: HTMLElement) => scrollWithOffset(el, 'characteristics')}
                                            onClick={() => setShowMobileNav(false)}
                                        >
                                            {translation('nav3rdLink')}
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink
                                            className={
                                                'flex justify-center items-center py-2 text-xl text-gray-500 hover:text-primary'
                                            }
                                            smooth
                                            to={'/#about-us'}
                                            scroll={(el: HTMLElement) => scrollWithOffset(el, 'about-us')}
                                            onClick={() => setShowMobileNav(false)}
                                        >
                                            {translation('nav4thLink')}
                                        </HashLink>
                                    </li>
                                    <li className={'pb-2'}>
                                        <HashLink
                                            className={
                                                'flex justify-center items-center py-2 text-xl text-gray-500 hover:text-primary'
                                            }
                                            smooth
                                            to={'/#educational'}
                                            scroll={(el: HTMLElement) => scrollWithOffset(el, 'educational')}
                                            onClick={() => setShowMobileNav(false)}
                                        >
                                            {translation('nav5thLink')}
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink
                                            className={
                                                'flex justify-center items-center py-2 text-xl text-gray-500 hover:text-primary'
                                            }
                                            smooth
                                            to={'/#faq'}
                                            scroll={(el: HTMLElement) => scrollWithOffset(el, 'faq')}
                                            onClick={() => setShowMobileNav(false)}
                                        >
                                            {translation('nav6thLink')}
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink
                                            className={
                                                'flex justify-center items-center py-2 text-xl text-gray-500 hover:text-primary'
                                            }
                                            smooth
                                            to={'/#contact-us'}
                                            scroll={(el: HTMLElement) => scrollWithOffset(el, 'contact-us')}
                                            onClick={() => setShowMobileNav(false)}
                                        >
                                            {translation('nav7thLink')}
                                        </HashLink>
                                    </li>
                                    <li className={'border-t border-gray-200 pt-2'}>
                                        <Button
                                            className={
                                                'text-primary! flex! w-full! justify-center! py-2! text-xl! transition! duration-150! ease-in-out!'
                                            }
                                            // hover:text-gray-900! py-2! px-4! flex! items-center!
                                            text={translation('signIn')}
                                            onClickHandler={() => history.push('/sign-in')}
                                        />
                                    </li>
                                    <li>
                                        <PrimaryButton
                                            className={
                                                'w-full! my-2! inline-flex! items-center! justify-center! text-xl! font-black! leading-snug! transition-all! py-2! px-4!'
                                            }
                                            text={
                                                <React.Fragment>
                                                    <span>{translation('signUp')}</span>
                                                    <SvgIcon
                                                        className={'w-7 h-7 fill-current flex-shrink-0 ml-2 -mr-1'}
                                                        folder={'heroicons'}
                                                        name={'arrow-sm-right'}
                                                    />
                                                </React.Fragment>
                                            }
                                            onClickHandler={() => history.push('/sign-up')}
                                        />
                                    </li>
                                    <li>
                                        <LanguageSelector
                                            translation={translation}
                                            i18n={i18n}
                                            languages={languages}
                                            className={'w-full! my-2! inline-flex! items-center! justify-center!'}
                                            styleList={'w-24! mt-8!'}
                                            size={'w-10 h-8'}
                                            sizeSelected={'w-10 h-8'}
                                        />
                                    </li>
                                </ul>
                            </nav>
                        </Transition>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default PublicNavbar
