import { useState } from 'react'
import { BaseComponentChangeableLanguage } from 'interfaces/BaseComponentChangeableLanguage.interfaces'
import SvgIcon from 'components/SvgIcon'
import { FlagsIconsKeysType } from 'types/FlagsIconsKeysType'

interface LanguageSelectorProps extends BaseComponentChangeableLanguage {
    className?: string
    styleList?: string
    size?: string
    sizeSelected?: string
}

const LanguageSelector = ({ i18n, languages, className, styleList }: LanguageSelectorProps) => {
    const [showList, setShowList] = useState(false)
    const [languageSelected, setLanguageSelected] = useState(i18n.language)

    const getLanguageFlag = (i18nLang: string): FlagsIconsKeysType => {
        switch (i18nLang) {
            case 'en':
            case 'EN':
                return 'EN'
            case 'it':
            case 'IT':
                return 'IT'
            default:
                return 'EN'
        }
    }

    const toggleVisibilityHandler = () => {
        setShowList(!showList)
    }

    const changeLanguageHandler = (language: string) => {
        setLanguageSelected(language)
        i18n.changeLanguage(language)
        toggleVisibilityHandler()
    }

    return (
        <div className={`relative inline-block dropdown ${className ?? ''}`}>
            <span className={'rounded-md'}>
                <button
                    className={
                        'inline-flex justify-center items-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-transparent rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800'
                    }
                    type={'button'}
                    onClick={toggleVisibilityHandler}
                >
                    {getLanguageFlag(languageSelected)}
                    <SvgIcon className={'w-3 h-3 ml-2 -mr-1'} folder={'heroicons'} name={'chevron-down'} />
                </button>
            </span>
            <div
                className={` ${
                    showList === true ? 'opacity-100 visible' : 'opacity-0'
                } dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95 `}
            >
                <div
                    className={`absolute right-0 w-20 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none ${
                        styleList ?? ''
                    }`}
                >
                    <div className={'py-1 w-full'}>
                        {languages.map((language: FlagsIconsKeysType, index: number) => (
                            <span
                                key={index}
                                className={'flex w-full items-center justify-center px-4 py-2 cursor-pointer'}
                                onClick={() => changeLanguageHandler(language)}
                            >
                                {language}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LanguageSelector
