import jwtDecode from 'jwt-decode'
import { logoutUser } from '../redux/actions/userActions'
import store from '../redux/store'
import { SET_AUTHENTICATED, SET_UNAUTHENTICATED, SET_USER } from '../redux/types'
import { ROLE } from 'enum/roles'

export const CheckAuthentication = () => {
    const sessionUser = JSON.parse(localStorage.getItem('user') ?? '{}')
    const authToken = sessionUser.token ?? null
    const userLoggedIn = sessionUser.user ?? null

    if (authToken) {
        const decodeToken: any = jwtDecode(authToken)

        if (decodeToken.exp * 1000 < Date.now()) {
            store.dispatch(logoutUser())
        } else {
            store.dispatch({ type: SET_AUTHENTICATED })
            store.dispatch({ type: SET_USER, payload: userLoggedIn })
        }
    } else {
        store.dispatch({ type: SET_UNAUTHENTICATED })
    }
}

export const UserHasRequiredRole = (
    needUserAuthenticated: boolean,
    userRole: ROLE,
    roles: Array<ROLE> = []
): boolean => {
    if (needUserAuthenticated === true) {
        return (typeof userRole !== 'undefined' && userRole !== null && roles.includes(userRole)) || roles.length === 0
            ? true
            : false
    } else {
        return (typeof userRole !== 'undefined' && userRole !== null && roles.includes(userRole)) || roles.length === 0
            ? true
            : false
    }
}
