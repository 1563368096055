import { EmojiIconsObjectType } from 'types/EmojiIconsObjectType'
import { ReactComponent as CoolIcon } from './cool.svg'
import { ReactComponent as FriendlyIcon } from './friendly.svg'
import { ReactComponent as HappyIcon } from './happy.svg'
import { ReactComponent as SadIcon } from './sad.svg'
import { ReactComponent as SmileIcon } from './smile.svg'
import { ReactComponent as SpeechlessIcon } from './speechless.svg'
import { ReactComponent as SuspectIcon } from './suspect.svg'
import { ReactComponent as ToungeIcon } from './tounge.svg'

const EmojiIcons: EmojiIconsObjectType = {
    cool: CoolIcon,
    friendly: FriendlyIcon,
    happy: HappyIcon,
    sad: SadIcon,
    smile: SmileIcon,
    speechless: SpeechlessIcon,
    suspect: SuspectIcon,
    tounge: ToungeIcon,
}

export default EmojiIcons
