import { HealthIconsObjectType } from 'types/HealthIconsObjectType'
import { ReactComponent as AmbulanceIcon } from './ambulance.svg'
import { ReactComponent as BasketballIcon } from './basketball.svg'
import { ReactComponent as CapsuleIcon } from './capsule.svg'
import { ReactComponent as DumbbellIcon } from './dumbbell.svg'
import { ReactComponent as FirstAidIcon } from './first-aid.svg'
import { ReactComponent as HeartMonitorIcon } from './heart-monitor.svg'
import { ReactComponent as HospitalIcon } from './hospital.svg'
import { ReactComponent as MicroscopeIcon } from './microscope.svg'
import { ReactComponent as PulseIcon } from './pulse.svg'
import { ReactComponent as SkippingRopeIcon } from './skipping-rope.svg'
import { ReactComponent as SlimIcon } from './slim.svg'
import { ReactComponent as SthethoscopeIcon } from './sthethoscope.svg'
import { ReactComponent as SyringeIcon } from './syringe.svg'
import { ReactComponent as WeightIcon } from './weight.svg'
import { ReactComponent as WheelchairIcon } from './wheelchair.svg'

const HealthIcons: HealthIconsObjectType = {
    ambulance: AmbulanceIcon,
    basketball: BasketballIcon,
    capsule: CapsuleIcon,
    dumbbell: DumbbellIcon,
    'first-aid': FirstAidIcon,
    'heart-monitor': HeartMonitorIcon,
    hospital: HospitalIcon,
    microscope: MicroscopeIcon,
    pulse: PulseIcon,
    'skipping-rope': SkippingRopeIcon,
    slim: SlimIcon,
    sthethoscope: SthethoscopeIcon,
    syringe: SyringeIcon,
    weight: WeightIcon,
    wheelchair: WheelchairIcon,
}

export default HealthIcons
