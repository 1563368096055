import { TravelIconsObjectType } from 'types/TravelIconsObjectType'
import { ReactComponent as PyramidsIcon } from './pyramids.svg'
import { ReactComponent as SurfBoardIcon } from './surf-board.svg'
import { ReactComponent as TravelIcon } from './travel.svg'

const TravelIcons: TravelIconsObjectType = {
    pyramids: PyramidsIcon,
    'surf-board': SurfBoardIcon,
    travel: TravelIcon,
}

export default TravelIcons
