import { SET_ERROR, LOADING_UI, CLEAR_ERRORS } from '../types'

const initialState = {
    loading: false,
    error: null,
}

export default function (state = initialState, action: any) {
    const { type, payload } = action
    switch (type) {
        case SET_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case LOADING_UI:
            return {
                ...state,
                loading: true,
            }
        default:
            return state
    }
}
