import { ReactComponent as DashboardsIcon } from './dashboard.svg'
import { ReactComponent as ParametersIcon } from './parameters.svg'
import { ReactComponent as SettingsIcon } from './settings.svg'
import { ReactComponent as LogoutIcon } from './logout.svg'
import { ReactComponent as MenuCloseIcon } from './menu-close.svg'
import { ReactComponent as MenuOpenIcon } from './menu-open.svg'
import { ReactComponent as ItemCloseIcon } from './item-close.svg'
import { ReactComponent as ItemOpenIcon } from './item-open.svg'
import { ThemeIconsObjectType } from 'types/ThemeIconsObjectType'

const ThemeIcons: ThemeIconsObjectType = {
    dashboards: DashboardsIcon,
    parameters: ParametersIcon,
    settings: SettingsIcon,
    logout: LogoutIcon,
    menuClose: MenuCloseIcon,
    menuOpen: MenuOpenIcon,
    itemClose: ItemCloseIcon,
    itemOpen: ItemOpenIcon,
}

export default ThemeIcons
