import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import EN from 'locales/en.json'
import IT from 'locales/it.json'
import { FlagsIconsKeysType } from 'types/FlagsIconsKeysType'

const resources = {
    EN,
    IT,
}

export const defaultNS = 'translation'
export const availableLanguages: Array<FlagsIconsKeysType> = ['EN', 'IT']

i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    defaultNS,
    fallbackLng: 'EN',
})
