interface InputTextErrorProps {
    message?: string
    className?: string
}

const InputTextError = ({ message, className }: InputTextErrorProps) => {
    return <p className={`text-red-600 dark:text-red-500 mt-2 text-sm ${className ?? ''}`}>{message}</p>
}

export default InputTextError
