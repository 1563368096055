import React, { ReactNode } from 'react'

interface CardProps {
    imageSrc?: string
    children?: JSX.Element | React.ReactChild | ReactNode | React.FC
    className?: string
}

const Card = ({ imageSrc, children, className }: CardProps) => {
    return (
        <div
            className={`rounded-xl mx-3 my-8 bg-white transition-all duration-300 overflow-hidden shadow ${
                className ?? ''
            }`}
        >
            {!!imageSrc && (
                <div>
                    <img src={imageSrc} className={'w-full'} />
                </div>
            )}
            {children}
        </div>
    )
}

export default Card
