import { useHistory } from 'react-router-dom'
import ResetPasswordForm from 'components/ResetPasswordForm'
import AuthPage from 'components/AuthPage'
import { BaseAppRoute } from 'interfaces/BaseAppRoute.interfaces'

const ResetPassword = ({ translation }: BaseAppRoute) => {
    const history = useHistory()
    return (
        <AuthPage translation={translation} showRegisterBtn={false}>
            <p className={'text-center text-3xl mb-1'}>{translation('titleResetPassword')}</p>
            <p className={'text-center text-lg mb-1'}>{translation('subtitleResetPassword')}</p>
            <ResetPasswordForm
                translation={translation}
                showOtherBtn={true}
                labelOtherBtn={translation('backHome')}
                onClickOtherBtnHandler={() => history.push('/')}
            />
        </AuthPage>
    )
}

export default ResetPassword
