// user reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_UNSIGNED_ALL_DOCUMENTS = 'SET_UNSIGNED_ALL_DOCUMENTS'
export const SET_TMP_LOGIN_DATA = 'SET_TMP_LOGIN_DATA'
export const CLEAR_TMP_LOGIN_DATA = 'CLEAR_TMP_LOGIN_DATA'
export const SET_USER = 'SET_USER'
export const SET_TMP_USER = 'SET_TMP_USER'
export const LOADING_USER = 'LOADING_USER'

//UI reducer types
export const SET_ERROR = 'SET_ERROR'
export const LOADING_UI = 'LOADING_UI'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
